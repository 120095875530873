// import { ThemeProvider } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles"; import React, {
  useState,
  useEffect,
  createContext,
  useMemo
} from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import ForgotPassword2 from "./ForgotPassword2";
import Login from "./Login";
import MyPolicyHome from "./MyPolicy/MyPolicyHome";
import UserRegistration from "./MyPolicy/UserRegistration";
import UserRegistration2 from "./MyPolicy/UserRegistration2";
import UserRegistrationOTP from "./MyPolicy/UserRegistrationOTP";
import PrivacyPolicy from "./PrivacyPolicy"
import LoanAmortization from "./MyPolicy/LoanAmortization";
import { AuthProvider } from "./FirebaseAuth";

//import { auth } from "./Firebase/firebase";
// import { initializeApp } from "firebase/app";

// const firebaseConfig = {
//   apiKey: "AIzaSyCZ9_2oUnhH_ZG-bU_Op9ffX7XtJaQPQYY",
//   authDomain: "mypolicy-c57d9.firebaseapp.com",
//   projectId: "mypolicy-c57d9",
//   storageBucket: "mypolicy-c57d9.appspot.com",
//   messagingSenderId: "20756679398",
//   appId: "1:20756679398:web:30ecc4f737fc1f2ee7c927",
//   measurementId: "G-CS7H5TP721"
// };

// const firebaseapp = initializeApp(firebaseConfig);

const ColorModeContext = createContext({ toggleColorMode: () => { } });

export default function App() {
  // React.useEffect(() => {
  //   async function init() {
  //     if (process.env.REACT_APP_ENV == 'local') {
  //       console.log = function () { }
  //     }
  //   }
  //   init()
  // }, [])

  const themeSTATE = useSelector((state) => state.theme.value);
  // console.log("APP.JS | Called USE SELECTOR");
  const [mode, setMode] = useState(themeSTATE);
  const colorMode = useMemo(() => {
    // console.log("USE MEMO | START SETTING STATE");
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
    // console.log(" USE MEMO | DONE SETTING STATE");
  }, [themeSTATE]);

  // console.log("1. ThemeSTATE: " + themeSTATE + " | Mode: " + mode);

  const policyNum = useSelector((state) => state.policyNumber.value)
  const isVL = useSelector((state) => state.policyNumber.isVL)
  console.log("POLICY NUMBER: " + policyNum + "is TRAD" + isVL)

  // store.subscribe(() => {
  //   console.log("subscribe is happening");
  //   setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  //   console.log("setting mode lol");
  // });

  useEffect(() => {
    async function init() {

    }
    init()
  }, [])


  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light"
            ? {
              primary: {
                main: "#005db9",
                button: "#fff"
              },
              secondary: {
                main: "#005db9",
              },
              google: {
                main: "#fff"
              },
              appleLogo: {
                main: "#fff"
              },
              text: {
                primary: "rgba(13, 18, 38,1)", //headings
                hint: "rgba(0,0,0,0.38)", //hints
                secondary: "rgba(13, 18, 38, 0.75)", //headings
                disabled: "rgba(0, 0, 0, 0.7)", //disabled
                subtitle2: "rgba(76, 88, 125, .8)", //for small heading guides
                linkActive: "#005db9",
                error: "#f44336",
                success: "#66bb6a"
              },
              background: {
                // default: '#eff8fa',
                // default: "rgba(248,250,253,1)",
                default: "white",
                // paper: "rgba(228, 229, 235, 0.2)",
                paper: "#FAFAFB",
              },
              header: {
                color: "#fff",
              },
              divider: "rgba(0, 0, 0, 0.12)",
            }
            : {
              primary: {
                main: "#005db9",
                light: "#53a8fc",
              },
              secondary: {
                // main: "rgba(255, 255, 255, 0.7)",
                main: "#6eb7ff",
              },
              google: {
                main: "#fff"
              },
              appleLogo: {
                main: "#000"
              },
              text: {
                primary: "#fff",
                hint: "rgba(255, 255, 255, 0.5)",
                secondary: "rgba(255, 255, 255, 0.75)", //headings
                disabled: "rgba(255, 255, 255, 0.5)", //disabled
                subtitle2: "rgba(201, 211, 240, .5)", //for small heading guides
                linkActive: "#6eb7ff",
                error: "#f44336",
                success: "#66bb6a"
              },
              background: {
                // default: '#eff8fa',
                // default: "rgba(248,250,253,1)",
                default: "#171924",
                paper: "#222531",
              },
              header: {
                color: "#0f111c",
              },
              divider: "rgba(50, 54, 71,0.90)",
            }),
        },

        components: {
          mode,
          ...(mode === "light"
            ? {
              MuiAppBar: {
                styleOverrides: {
                  colorDefault: {
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #ddd",
                  },
                },
              },
              MuiDrawer: {
                styleOverrides: {
                  paper: {
                    backgroundImage: "none",
                  },
                },
              },
              // MuiCollapse: {
              //   styleOverrides: {
              //     wrapper:{
              //       backgroundColor: "#ebebf0"

              //     }
              //   }
              // }
            }
            : {
              MuiAppBar: {
                styleOverrides: {
                  colorDefault: {
                    backgroundImage: "none",
                    backgroundColor: "#181a26",
                    borderBottom: "1px solid rgba(50, 54, 71 ,0.90)",
                  },
                },
              },
              MuiDrawer: {
                styleOverrides: {
                  paper: {
                    backgroundImage: "none",
                  },

                },
              },

              // MuiCollapse: {
              //   styleOverrides: {
              //     wrapper:{
              //       backgroundColor: "#373a4a"
              //     }
              //   }
              // },
            }),
        },
        typography: {
          fontFamily: ["GothamBlack", "GothamMedium", "GothamRegular"],
          h1: {
            fontFamily: "GothamRegular",
          },
          h2: {
            fontFamily: "GothamRegular",
          },
          h3: {
            fontFamily: "GothamRegular",
          },
          h4: {
            fontFamily: "GothamMedium",
          },
          h5: {
            fontFamily: "GothamMedium",
          },

          h6: {
            fontFamily: "GothamMedium",
            fontSize: "1.1rem",
          },

          body1: {
            fontFamily: "GothamRegular",
            fontSize: "16px",
          },
          body2: {
            fontFamily: "GothamRegular",
            fontSize: "14px",
          },
          span: {
            fontFamily: "GothamRegular",
          },
          overline: {
            fontFamily: "GothamMedium",
          },
          caption: {
            fontFamily: "GothamRegular",
          },
          subtitle1: {
            fontFamily: "GothamRegular",
          },
          subtitle2: {
            fontFamily: "GothamMedium",
          },
          button: {
            fontFamily: "GothamMedium",
          },
        },
      }),
    [mode]
  );

  return (
    <BrowserRouter>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="mypolicy" element={<MyPolicyHome />} />
              <Route path="mypolicy/post-dragonpay-transaction" element={<MyPolicyHome redirectTo={7} />} />
              {/* <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="forgot-password-email" element={<ForgotPassword2 />} /> */}
              <Route path="registration" element={<UserRegistration />} />
              <Route path="registration-step2" element={<UserRegistration2 />} />
              <Route path="registration-step-otp" element={<UserRegistrationOTP />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              {/* <Route path="loan-calculator" element={<LoanAmortization />} /> */}
            </Routes>
          </AuthProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </BrowserRouter>
  );
}
