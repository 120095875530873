import { createSlice } from "@reduxjs/toolkit";
export const logoutControlSlice = createSlice({
  name: "logoutState",
  //this is the initial state
  initialState: {
    value: false,
  },
  //reducers: these are the functions for updating the state of loadingState
  reducers: {
    updateLogoutState: (state, action) => {
      console.log("updateLogoutState.payload", action.payload)
      const { payload } = action
      state.value = payload
    },
  },
});

export const { updateLogoutState } = logoutControlSlice.actions;
export default logoutControlSlice.reducer;
