import React from 'react'
import { Button } from '@mui/material'
import googleSVG from '../assets/brands/google-svg.svg';
import GoogleLogo from '../assets/brands/GoogleLogo';
import { styled } from '@mui/material/styles';

export default function GoogleButton(props) {
  const GoogleButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.8)',
    },
  }));
  
  return (
    <GoogleButton startIcon={<GoogleLogo/>} sx={{ backgroundColor: '#fff', 
    color:"#2e2e2e"}} onClick={props.onClick}variant="contained">{props.message}</GoogleButton>
  )
}
