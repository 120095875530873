import React, { forwardRef, createRef, useEffect, useState } from "react";
import { TablePagination } from "@mui/material";
import MaterialTable, { Column } from "@material-table/core";

import AddBox from "@mui/icons-material/AddBox";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";

const AddRef = forwardRef((props, ref) => <AddBox {...props} ref={ref} />)
const CheckRef = forwardRef((props, ref) => <Check {...props} ref={ref} />)
const ClearRef = forwardRef((props, ref) => <Clear {...props} ref={ref} />)
const DeleteRef = forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />)
const DetailPanelRef = forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
))
const EditRef = forwardRef((props, ref) => <Edit {...props} ref={ref} />)
const ExportRef = forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />)
const FilterRef = forwardRef((props, ref) => <FilterList {...props} ref={ref} />)
const FirstPageRef = forwardRef((props, ref) => <FirstPage {...props} ref={ref} />)
const LastPageRef = forwardRef((props, ref) => <LastPage {...props} ref={ref} />)
const NextPageRef = forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />)
const PreviousPageRef = forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
))
const ResetSearchRef = forwardRef((props, ref) => <Clear {...props} ref={ref} />)
const SearchRef = forwardRef((props, ref) => <Search {...props} ref={ref} />)
const SortArrowRef = forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />)
const ThirdStateCheckRef = forwardRef((props, ref) => <Remove {...props} ref={ref} />)
const ViewColumnRef = forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)

AddRef.displayName = "AddIcon"
CheckRef.displayName = "CheckIcon"
ClearRef.displayName = "ClearIcon"
DeleteRef.displayName = "DeleteIcon"
DetailPanelRef.displayName = "DetailPanelIcon"
EditRef.displayName = "EditIcon"
ExportRef.displayName = "ExportIcon"
FilterRef.displayName = "FilterIcon"
FirstPageRef.displayName = "1stPageIcon"
LastPageRef.displayName = "LastPageIcon"
NextPageRef.displayName = "NextPageIcon"
PreviousPageRef.displayName = "PrevPageIcon"
ResetSearchRef.displayName = "ResetSearchIcon"
SearchRef.displayName = "SearchIcon"
SortArrowRef.displayName = "SortArrowIcon"
ThirdStateCheckRef.displayName = "ThirdStateCheckIcon"
ViewColumnRef.displayName = "ViewColumnIcon"
const tableIcons = {
    Add: AddRef,
    Check: CheckRef,
    Clear: ClearRef,
    Delete: DeleteRef,
    DetailPanel: DetailPanelRef,
    Edit: EditRef,
    Export: ExportRef,
    Filter: FilterRef,
    FirstPage: FirstPageRef,
    LastPage: LastPageRef,
    NextPage: NextPageRef,
    PreviousPage: PreviousPageRef,
    ResetSearch: ResetSearchRef,
    Search: SearchRef,
    SortArrow: SortArrowRef,
    ThirdStateCheck: ThirdStateCheckRef,
    ViewColumn: ViewColumnRef,
};

export default function MaterialUITable(props) {
    const TABLE_DATA = props.data
    const isLoading = props.isLoading;
    const totalCount = props.totalCount;
    // console.log("props.totalCount", props.totalCount)
    const currentPage = props.currentPage;
    const rowsPerPage = props.rowsPerPage;
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5, 10, 25, 50, 100])
    const columns = props.columns;
    // const styles = useStyles();
    const tableRef = createRef();

    const ChangePage = (page, pageSize) => {
        props.changePage(page, pageSize);
    };

    useEffect(() => {
        let tmpRowsPerPageOptions = []
        if (TABLE_DATA.length > 100) {
            tmpRowsPerPageOptions = [5, 10, 25, 50, 100, TABLE_DATA.length]
        } else if (TABLE_DATA.length > 50) {
            tmpRowsPerPageOptions = [5, 10, 25, 50, TABLE_DATA.length]
        } else if (TABLE_DATA.length > 25) {
            tmpRowsPerPageOptions = [5, 10, 25, TABLE_DATA.length]
        } else if (TABLE_DATA.length > 10) {
            tmpRowsPerPageOptions = [5, 10, TABLE_DATA.length]
        } else if (TABLE_DATA.length > 5) {
            tmpRowsPerPageOptions = [5, TABLE_DATA.length]
        } else {
            tmpRowsPerPageOptions = [TABLE_DATA.length]
        }
        setRowsPerPageOptions(tmpRowsPerPageOptions);

    }, [TABLE_DATA])

    return (
        <MaterialTable
            icons={tableIcons}
            title={""}
            tableRef={tableRef}
            options={{
                showFirstLastPageButtons: false,
                draggable: false,
                headerStyle: {
                    // fontSize: '16px'
                },
            }} // options={{ sorting: true, filtering: true, showFirstLastPageButtons: false }}
            columns={columns}
            data={TABLE_DATA}
            components={{
                Pagination: (props) => (
                    <TablePagination
                        {...props}
                        count={totalCount}
                        page={currentPage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        showFirstButton={false}
                        showLastButton={false}
                    />
                ),
            }}
            onRowClick={(event, rowData, toggleDetailPanel) =>
                // props.getDataFromChild(rowData)
                console.log(rowData)
            }
            onPageChange={ChangePage}
            onRowsPerPageChange={(pageSize) => props.changeRowsPerPage(pageSize)}
            isLoading={isLoading}
            localization={{
                toolbar: {
                    searchPlaceholder: 'Filter'
                }
            }}

        />
    );
}
