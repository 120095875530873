import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 250,
    maxWidth: 500,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
};

export default function APIErrorModal(props) {
    const [modalAPIError, setModalAPIError] = useState(false);
    const [errorCode, setErrorCode] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        console.log("props?.response", props?.response);
        setModalAPIError(props?.open);
    }, [props?.open])

    useEffect(() => {
        console.log("props?.response", props?.response);

        if (props?.response?.Response?.Code != null) {
            setErrorCode(props?.response?.Response?.Code)
        } else if (props?.response?.Code != null) {
            setErrorCode(props?.response?.Code)
        } else if (props?.response?.code != null) {
            setErrorCode(props?.response?.code)
        }

        if (props?.response?.Response?.Message != null) {
            setErrorMessage(props?.response?.Response?.Message)
        } else if (props?.response?.Message != null) {
            setErrorMessage(props?.response?.Message)
        } else if (props?.response?.code != null) {
            setErrorMessage('API has Timed-out')
        }

    }, [props?.response])

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={modalAPIError}
            onClose={() => {
                (event, reason) => {
                    if (reason && reason == "backdropClick")
                        return;
                    setModalAPIError(false)
                }
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={modalAPIError}>
                <Box sx={style}>
                    <Typography id="transition-modal-title" variant="h6"
                        component="h2" color="text.primary">
                        API issue with {props?.apiName}
                    </Typography>

                    <Divider sx={{ mt: 3, mb: 1, width: '100%' }} />

                    <Box sx={{ mx: 1 }}>
                        <Typography
                            id="transition-modal-description"
                            align="left"
                            sx={{ mt: 2, fontWeight: "bold" }}
                            color="text.secondary"
                        >
                            Code:
                            <Typography
                                id="transition-modal-description"
                                align="left"
                                sx={{ mt: 1 }}
                                color="text.secondary"
                            >
                                {errorCode}
                            </Typography>
                        </Typography>

                        <Typography
                            id="transition-modal-description"
                            sx={{ mt: 3, fontWeight: "bold" }}
                            color="text.secondary"
                        >
                            Message:
                            <Typography
                                id="transition-modal-description"
                                sx={{ mt: 1 }}
                                color="text.secondary"
                            >
                                {errorMessage}
                            </Typography>
                        </Typography>

                        <Typography
                            id="transition-modal-description"
                            sx={{ mt: 5 }}
                            color="text.secondary"
                        >
                            If error keeps repeating please contact cocolife support team.
                        </Typography>
                    </Box>

                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                        {/* <Button variant="contained"
                            sx={{ mt: 4 }}
                            href="/mypolicy"
                            onClick={() => {
                                setModalAPIError(false)
                            }}>
                            Go back
                        </Button> */}

                        <Button variant="contained" onClick={() => {
                            setModalAPIError(false)
                            window.location.reload(true);
                            // { props?.reloadFunc(!props?.reloadPage) }
                        }} sx={{ mt: 4 }}>
                            Reload Page
                        </Button>
                    </Box>

                </Box>
            </Fade>
        </Modal>
    );
}
