import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { useSelector, useDispatch } from "react-redux";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import InfoIcon from "@mui/icons-material/Info";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from '@mui/icons-material/Home';

import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CalculateIcon from '@mui/icons-material/Calculate';

import {
  Box, Typography, Paper, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, Divider, Grid,
  Button, Modal, Fade, Backdrop, Checkbox,
  FormControlLabel, AppBar, Avatar, Collapse,
  CssBaseline, Drawer, IconButton, List,
  ListItemButton, ListItemIcon, ListItemText,
  Stack, Toolbar,
} from "@mui/material";

import cocolife from "../assets/cocolife-horizontal.png";
import DarkModeSwitch from "../components/DarkModeSwitch";
import PayOnline from "./PayOnline";
import MainContent from "./../MainContent";
import AddRemovePolicy from "./AddRemovePolicy";
import Coverages from "./Coverages";
import History from "./History";
import HomeContent from "./HomeContent";
import Ledger from "./Ledger";
import Loans from "./Loans";
import Funds from "./Funds";
import MyPolicies from "./MyPolicies";
import Others from "./Others";
import Downloadables from "./Downloadables";
import QuickGuide from "./QuickGuide";
import LoanableAmount from "./LoanableAmount";
import LoanAmortization from "./LoanAmortization";
import ProfileAccount from "./ProfileAccount";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropUp';
import { useAuth } from "../FirebaseAuth";
import { useNavigate } from "react-router-dom";
import { auth } from "../Firebase/firebase"
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import moment from 'moment-timezone';
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";
import { clearPolicyDetails } from "../features/Theming/PolicyNumberSlice";
import { updateLogoutState } from "../features/LogoutControl/LogoutControlSlice";
import { updateIdleState } from "../features/IdleControl/IdleControlSlice";
import { useIdleTimer } from 'react-idle-timer'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const drawerWidth = 240;

// let cTheme = createTheme(customtheme);

export default function MyPolicyHome(props) {
  const navigate = useNavigate();
  const location = useLocation();
  let dispatch = useDispatch();
  const { firebaseLogout, getCurrentFirebaseUser } = useAuth()

  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const IDLE_STATE = useSelector((state) => state.idleState.value);
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)
  const IS_TERM_PLAN = useSelector((state) => state.policyNumber.isTermPlan)

  // const { window } = props;
  const window2 = props.window;
  const [mobileOpen, setMobileOpen] = useState(false);
  // const [currentScreen, setCurrentScreen] = useState(<MyPolicies redirectToPayOnline={payOnline} />);
  const [currentScreen, setCurrentScreen] = useState(<HomeContent redirectToPayOnline={payOnline} redirectToViewPolicy={redirectToViewPolicy} />);
  const [toggleBasicInformation, setToggleBasicInformation] = useState(false);
  const [toggleLoanCalculator, setToggleLoanCalculator] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(10);

  const [firstName, setFirstName] = useState('')
  const [policyNumber, setPolicyNumber] = useState("");
  const [isVL, setIsVL] = useState(false);

  const [loadingWaitModal, setLoadingWaitModal] = useState(false);


  const handleKeyDown = (event) => {
    // console.log("event.keyCode", event.keyCode)
    if (event.keyCode == 44) {
      // Create a "hidden" input
      var aux = document.createElement("input");
      // Assign it the value of the specified element
      aux.setAttribute("value", "You can no longer printscreen. This is part of the new system security measure.");
      // Append it to the body
      document.body.appendChild(aux);
      // Highlight its content
      aux.select();
      // Copy the highlighted text
      document.execCommand("copy");
      // Remove it from the body
      document.body.removeChild(aux);
      alert("Print screen disabled.");
    }
  };

  useEffect(() => {
    async function init() {
      // moment.tz.setDefault("Asia/Manila");

      const geoData = await axios.get('https://geolocation-db.com/json/')
      console.log("geoData", geoData)
      // moment.tz.setDefault("America/Los_Angeles");

      console.log("IS_TERM_PLAN", IS_TERM_PLAN)
      console.log("isVL", isVL)
      console.log("process.env.REACT_APP_ENV", process.env.REACT_APP_ENV)
      console.log("is condition true?", (!IS_TERM_PLAN && !isVL && (process.env.REACT_APP_ENV !== 'prod-server')))
    }
    init()
  }, [])

  useEffect(() => {
    window.addEventListener('keyup', handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener('keyup', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    async function init() {
      // dispatch(updateLoadingState(false));
      // console.log("LOADING_STATE", LOADING_STATE)
      // console.log("IS_TERM_PLAN", IS_TERM_PLAN)
      console.log("!IS_TERM_PLAN", !IS_TERM_PLAN)
      console.log("process.env.REACT_APP_ENV === 'local'", process.env.REACT_APP_ENV === 'local')
      console.log("!isVL", !isVL)
      console.log("all checker", (!IS_TERM_PLAN && !isVL && (process.env.REACT_APP_ENV !== 'prod-server')))

      await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByPolicyNumber', {
        policyNumber: POLICY_DATA,
      }).then(async (getUserByPolicyNumberRes) => {
        if (getUserByPolicyNumberRes.data.user === false) {
          //  big error here
          console.log("User not found")
          return
        }
        console.log("getUserByPolicyNumberRes", getUserByPolicyNumberRes)
        console.log("getUserByPolicyNumberRes.data.user.recordset[0]", getUserByPolicyNumberRes.data.user.recordset[0])

        setFirstName(getUserByPolicyNumberRes.data.user.recordset[0].first_name)
      })
    }

    init()
  }, [])

  useEffect(() => {
    PolicyDataSetup()
  }, [])

  useEffect(() => {
    async function init() {

      auth.onAuthStateChanged(user => {
        // const pathname = window.location.pathname
        console.log("props", props);
        console.log("location", location);
        // console.log("pathname", pathname);

        console.log("Firebase auth", user)
        if (user != null) {
          console.log("Firebase onAuthStateChanged", user)
        } else {
          console.log("Navigating to auth since firebaseuser is null")
          navigate("/");
        }
      })

    }

    init()
  }, [])

  useEffect(() => {
    if (POLICY_DATA == null || POLICY_DATA == '') {
      firebaseLogout()
        .then(async (firebaseResponse) => {
          console.log("successful logout from firebase")

        }).catch((error) => {
          console.error("unsuccessful logout from firebase:", error);
        })

    } else {
      setPolicyNumber(POLICY_DATA)
    }
  }, [POLICY_DATA])

  useEffect(() => {
    // console.log("POLICY_DATA", POLICY_DATA)
    setPolicyNumber(POLICY_DATA)
    setIsVL(IS_VL)

    console.log("!IS_TERM_PLAN", !IS_TERM_PLAN)
    console.log("process.env.REACT_APP_ENV === 'local'", process.env.REACT_APP_ENV === 'local')
    console.log("!isVL", !isVL)
    console.log("all checker", (!IS_TERM_PLAN && !isVL && (process.env.REACT_APP_ENV !== 'prod-server')))
  }, [isVL, policyNumber, POLICY_DATA])

  useEffect(() => {
    // console.log("POLICY_DATA", POLICY_DATA)
    if (props.redirectTo != null && props.redirectTo != '' && !LOADING_STATE) {
      setSelectedIndex(7);
      setCurrentScreen(<PayOnline />)
    }
  }, [LOADING_STATE])

  const handleOnIdle = event => {
    console.log('user is idle', event)
    // console.log('last active', getLastActiveTime())

    // Log out
    firebaseLogout()
      .then(async (firebaseResponse) => {
        dispatch(updateIdleState('logged-out'));
        dispatch(updateLogoutState(true));
        dispatch(clearPolicyDetails());

        console.log("successful logout from firebase")

      }).catch((error) => {
        console.error("unsuccessful logout from firebase:", error);
      })
  }

  const handleOnActive = event => {
    // console.log('user is active', event)
    // console.log('time remaining', getRemainingTime())
  }

  const handleOnAction = event => {
    console.log('user no longer idle')
    // console.log("getLastActiveTime()", getLastActiveTime()/1000)
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    // timeout: miliseconds * seconds * min,
    // timeout: 1000 * 5,
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })


  const PolicyDataSetup = useCallback(async () => {
    setPolicyNumber(POLICY_DATA)
    setIsVL(IS_VL)
  }, [isVL, policyNumber])

  const handleListItemClick = (event, index, screen) => {
    if (LOADING_STATE) {
      setLoadingWaitModal(true)
    } else {
      setLoadingWaitModal(false)
      setSelectedIndex(index);
      setCurrentScreen(screen)
    }
  };

  function payOnline() {
    console.log("setSelectedIndex", 7)
    setSelectedIndex(7);
    console.log("<PayOnline />")
    setCurrentScreen(<PayOnline />)
  }

  function loanableAmount(loanBalance) {
    console.log("setSelectedIndex", 14)
    setSelectedIndex(14);
    console.log("<LoanAmortization />")
    setCurrentScreen(<LoanAmortization appliedLoan={loanBalance} />)
  }

  function redirectToViewPolicy(policyNumber) {
    console.log("Nav handleViewPolicy", policyNumber)
    setSelectedIndex(0);
    setToggleBasicInformation(true)
    setCurrentScreen(<MyPolicies redirectToPayOnline={payOnline} autoViewPolicyParent={true} redirectedPolicyNumber={policyNumber} />)
    // console.log("gumana ka lol")
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box>
      <Toolbar />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={loadingWaitModal}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setLoadingWaitModal(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={loadingWaitModal}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`Please wait while the page is loading`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setLoadingWaitModal(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <List sx={{ mt: 5 }}>
        <ListItemButton selected={selectedIndex === 10}
          // onClick={(event) => handleListItemClick(event, 10, <MyPolicies redirectToPayOnline={payOnline} />)}
          onClick={(event) => handleListItemClick(event, 10, <HomeContent redirectToPayOnline={payOnline} redirectToViewPolicy={redirectToViewPolicy} />)}
        >
          <ListItemIcon>
            <HomeIcon color="inherit" />
          </ListItemIcon>
          <Typography variant="body2" noWrap component="div">
            <ListItemText primary="Dashboard" disableTypography />
          </Typography>
        </ListItemButton>
        <ListItemButton onClick={value => { setToggleBasicInformation(!toggleBasicInformation) }}>

          <ListItemIcon color="inherit">
            <InfoIcon />
          </ListItemIcon>
          <Typography variant="body2" noWrap component="div">
            <ListItemText
              primary="Basic Information"
              disableTypography
            />
          </Typography>

        </ListItemButton>
        <Collapse in={toggleBasicInformation} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 7 }}
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0, <MyPolicies redirectToPayOnline={payOnline} />)}
            >
              <Typography variant="caption" noWrap component="div">
                <ListItemText primary="My Policies" disableTypography />
              </Typography>
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 7 }}
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1, <AddRemovePolicy />)}
            >
              <Typography variant="caption" noWrap component="div">
                <ListItemText
                  primary="Add or Remove Policies"
                  disableTypography
                />
              </Typography>
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2, <Coverages />)}>
          <ListItemIcon>
            <FactCheckIcon color="inherit" />
          </ListItemIcon>
          <Typography variant="body2" noWrap component="div">
            <ListItemText primary="Coverages" disableTypography />
          </Typography>
        </ListItemButton>

        <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3, <Ledger />)}>
          <ListItemIcon>
            <LocalAtmIcon />
          </ListItemIcon>
          <Typography variant="body2" noWrap component="div">
            <ListItemText primary="Ledger" disableTypography />
          </Typography>
        </ListItemButton>
        {

          !isVL && <ListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4, <Loans />)}>
            <ListItemIcon>
              <CreditScoreOutlinedIcon />
            </ListItemIcon>
            <Typography variant="body2" noWrap component="div">
              <ListItemText primary="Loans" disableTypography />
            </Typography>
          </ListItemButton>
        }

        {
          isVL &&
          <ListItemButton selected={selectedIndex === 12} onClick={(event) => handleListItemClick(event, 12, <Funds />)}>
            <ListItemIcon>
              <AccountBalanceWalletIcon />
            </ListItemIcon>
            <Typography variant="body2" noWrap component="div">
              <ListItemText primary="Funds" disableTypography />
            </Typography>
          </ListItemButton>
        }
        <ListItemButton selected={selectedIndex === 5} onClick={(event) => handleListItemClick(event, 5, <History />)}>
          <ListItemIcon>
            <HistoryOutlinedIcon />
          </ListItemIcon>
          <Typography variant="body2" noWrap component="div">
            <ListItemText primary="History" disableTypography />
          </Typography>
        </ListItemButton>
        {
          (!IS_TERM_PLAN && !isVL && (process.env.REACT_APP_ENV !== 'prod-server'))
          &&
          <>
            <ListItemButton onClick={value => { setToggleLoanCalculator(!toggleLoanCalculator) }}>
              <ListItemIcon color="inherit">
                <CalculateIcon />
              </ListItemIcon>
              <Typography variant="body2" noWrap component="div">
                <ListItemText
                  primary="Loan Calculator"
                  disableTypography
                />
              </Typography>
            </ListItemButton>

            <Collapse in={toggleLoanCalculator} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 7 }}
                  selected={selectedIndex === 6}
                  onClick={(event) => handleListItemClick(event, 6, <LoanableAmount redirectToLoanAmortization={loanableAmount} />)}
                >
                  <Typography variant="caption" noWrap component="div">
                    <ListItemText primary="Loanable Amount" disableTypography />
                  </Typography>
                </ListItemButton>

                <ListItemButton
                  sx={{ pl: 7 }}
                  selected={selectedIndex === 14}
                  onClick={(event) => handleListItemClick(event, 14, <LoanAmortization />)}
                >
                  <Typography variant="caption" noWrap component="div">
                    <ListItemText primary="Loan Amortization" disableTypography />
                  </Typography>
                </ListItemButton>
              </List>
            </Collapse>
          </>
        }
        {

          !isVL &&
          <ListItemButton selected={selectedIndex === 11} onClick={(event) => handleListItemClick(event, 11, <Others />)}>
            <ListItemIcon>
              <SupportAgentOutlinedIcon />
            </ListItemIcon>
            <Typography variant="body2" noWrap component="div">
              <ListItemText primary="Others" disableTypography />
            </Typography>
          </ListItemButton>
        }
        <ListItemButton selected={selectedIndex === 13} onClick={(event) => handleListItemClick(event, 13, <Downloadables />)}>
          <ListItemIcon>
            <DownloadForOfflineIcon />
          </ListItemIcon>
          <Typography variant="body2" noWrap component="div">
            <ListItemText primary="Downloadables" disableTypography />
          </Typography>
        </ListItemButton>

      </List>

      <Divider />

      {/* {
        !isVL && <> */}
      <List>
        <ListItemButton
          color="primary"
          selected={selectedIndex === 7}
          onClick={(event) => handleListItemClick(event, 7, <PayOnline />)}
        >
          <ListItemIcon>
            <AttachMoneyOutlinedIcon color="inherit" />
          </ListItemIcon>
          <Typography variant="body2" noWrap component="div">
            <ListItemText primary="Pay Online" disableTypography />
          </Typography>
        </ListItemButton>
      </List>
      <Divider />
      {/* </>
      } */}

      <List>
        {/* <ListItem>
        <Typography color="text.subtitle2" variant="overline">
              Account Details
            </Typography>
        </ListItem> */}

        <ListItemButton
          selected={selectedIndex === 8}
          onClick={(event) => handleListItemClick(event, 8, <ProfileAccount />)}
        >
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <Typography variant="body2" noWrap component="div">
            <ListItemText primary="Profile Account" disableTypography />
          </Typography>
        </ListItemButton>

        {/* <ListItemButton selected={selectedIndex === 9} onClick={(event) => handleListItemClick(event, 9, <QuickGuide />)}>
          <ListItemIcon>
            <HelpOutlineRoundedIcon />
          </ListItemIcon>
          <Typography variant="body2" noWrap component="div">
            <ListItemText primary="Quick Guide" disableTypography />
          </Typography>
        </ListItemButton> */}

        <ListItemButton component="a"
          onClick={() => {
            firebaseLogout()
              .then(async (firebaseResponse) => {
                console.log("successful logout from firebase")
                dispatch(updateLogoutState(true));
                dispatch(clearPolicyDetails());
              }).catch((error) => {
                console.error("unsuccessful logout from firebase:", error);
              })
          }}>
          <ListItemIcon>
            <ExitToAppRoundedIcon />
          </ListItemIcon>
          <Typography variant="body2" noWrap component="div">
            <ListItemText primary="Log Out" disableTypography />
          </Typography>
        </ListItemButton>
      </List>
      <Divider />

      <List>
        {" "}
        <DarkModeSwitch />
      </List>
    </Box>
  );

  const container =
    window2 !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: { xs: "block", md: "flex" } }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="default"
        sx={{
          width: `100%`,
          ml: { md: `${drawerWidth}px` },
          zIndex: 2000,

          //backgroundColor: "default.background",
          borderTop: "10px solid #005db9",
          boxShadow: "none",
        }}
        enableColorOnDark
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Stack direction="row" spacing={2} alignItems="center">
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Stack
                direction="row"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
                alignItems="center"
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={cocolife} style={{ height: "20px" }} />
                </Box>
                <Typography
                  color="neutral.main"
                  variant="subtitle"
                  // sx={{ fontWeight: 'bold' }}
                  noWrap
                  component="div"
                >
                  { }
                  {`MyPolicy | ${policyNumber}  `}
                </Typography>
              </Stack>
            </Stack>
          </div>
          <Avatar
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedIndex(8);
              setCurrentScreen(<ProfileAccount />)
            }}
          >
            {firstName.split('')[0]}
          </Avatar>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { xs: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
            padding: "20px",
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="div"
        sx={{
          height: "100%",
          flexGrow: 1,
          p: 3,
          width: { xs: "100%", md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <MainContent screen={currentScreen} />
      </Box>
    </Box>
  );
}
