import React, {
  useState,
  useEffect,
} from 'react';

import Box from "@mui/material/Box";

import Divider from "@mui/material/Divider";

import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";

import cocolife from "../assets/cocolife-horizontal.png";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import APIErrorModal from '../components/APIErrorModal';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import axios from "axios";
import * as Yup from 'yup';
import { updatePage1, updatePage2 } from "../features/Registration/RegistrationSlice";
import { useFormik, useField, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import moment from 'moment-timezone';
import { auth, firestoreDB } from "../Firebase/firebase";
import { collection, addDoc, getDocs } from "firebase/firestore";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 80,
  maxWidth: 500,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default function UserRegistration2() {
  const navigate = useNavigate();

  const PROVIDER_STATE = useSelector((state) => state.registration.provider);
  const PROVIDER_EMAIL_STATE = useSelector((state) => state.registration.providerEmail);
  const registrationSTATE = useSelector((state) => state.registration)

  const [apiName, setapiName] = React.useState(null);
  const [apiResponse, setapiResponse] = React.useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = React.useState(false);
  const [emailExistsErrorModalOpen, setEmailExistsErrorModal] = React.useState(false);
  const [mobileNumberExistsErrorModalOpen, setMobileNumberExistsErrorModal] = React.useState(false);
  const [usernameExistsErrorModalOpen, setUsernameExistsErrorModal] = React.useState(false);

  const [email, setEmail] = React.useState('');
  const [mobileNumber, setMobileNumber] = React.useState('');
  const [formValues, setFormValues] = React.useState({});
  const [modalPrivacyPolicy, setModalPrivacyPolicy] = useState(false);
  const [modalAcknowledgementSlip, setModalAcknowledgementSlip] = useState(false);
  const [agreedToPrivacyPolicy, setAgreedToPrivacyPolicy] = useState(true);
  const [agreedToAcknowledgementSlip, setAgreedToAcknowledgementSlip] = useState(false);
  const [regiSchema, setRegiSchema] = React.useState(

    Yup.object().shape({
      // emailAddress: Yup.string()
      //   .email('Invalid email')
      //   .required('Required'),
      // mobileNumber: Yup.string()
      //   .min(2, 'Too Short!')
      //   .max(50, 'Too Long!')
      //   .required('Required'),
      // username: Yup.string()
      //   // .email('Invalid username')
      //   .required('Required'),
      password: Yup.string()
        .matches(/^\S*$/, 'Whitespace is not allowed')
        .matches(/^(?=.*?[A-Z])/, 'At least one upper case letter is required')
        .matches(/^(?=.*?[a-z])/, 'At least one lower case letter is required')
        .matches(/^(?=.*?[0-9])/, 'At least one digit is required')
        .matches(/^(?=.*?[#?!@$%^&*-])/, 'At least one special character is required')
        .min(8, 'At least 8 characters')
        .max(50, 'Too Long!')
        .required('Required'),
      confirmPassword: Yup.string()
        .matches(/^\S*$/, 'Whitespace is not allowed')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
    })
  );

  const oldUserIDSTATE = useSelector((state) => state.registration?.oldUserID);

  let dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      // moment.tz.setDefault("Asia/Manila");
      // moment.tz.setDefault("America/Los_Angeles");
    }
    init()
  }, [])

  useEffect(() => {
    async function init() {
      // console.log("registrationSTATE", registrationSTATE);
      if (registrationSTATE.policyNumber == null || registrationSTATE.policyNumber == '') {
        console.log("No policyNumber saved, redirecting to login", registrationSTATE.policyNumber);
        navigate("/")
      } else {
        console.log("registrationSTATE:", registrationSTATE);
      }
    }
    init()
  }, [])

  const saveToStore = (inputs) => {
    try {
      dispatch(updatePage2(inputs))
    } catch (error) {
      console.log("redux error", error);
    }
  }

  useEffect(() => {
    async function init() {
      // console.log("oldUserIDSTATE", oldUserIDSTATE)
      // if (oldUserIDSTATE != '' && oldUserIDSTATE != null) {
      //   await axios.post(process.env.REACT_APP_API_URL + '/api/getOldUserAccountByID', {
      //     id: oldUserIDSTATE,
      //   }).then(async (getOldUserAccountRes) => {
      //     if (getOldUserAccountRes.data.user === false) {
      //       //  big error here
      //       console.log("User not found")
      //       return
      //     }
      //     console.log("getOldUserAccountRes", getOldUserAccountRes)

      //     let oldUserID = getOldUserAccountRes.data.user.recordset[0].id;
      //     console.log("oldUserID", oldUserID)

      //     setEmail(getOldUserAccountRes.data.user.recordset[0].email)
      //     setMobileNumber(getOldUserAccountRes.data.user.recordset[0].mobileNumber)

      //     console.log("formik", formik)

      //     if (getOldUserAccountRes.data.user.recordset[0].email != null && getOldUserAccountRes.data.user.recordset[0].email != '') {
      //       console.log("setting emailAddress", getOldUserAccountRes.data.user.recordset[0].email)
      //       formik.setFieldValue('emailAddress', getOldUserAccountRes.data.user.recordset[0].email, true)
      //       formik.setFieldValue('username', getOldUserAccountRes.data.user.recordset[0].email, true)
      //     }

      //     if (getOldUserAccountRes.data.user.recordset[0].mobileNumber != null && getOldUserAccountRes.data.user.recordset[0].mobileNumber != '') {
      //       // formik.setFieldValue('mobileNumber', getOldUserAccountRes.data.user.recordset[0].email, true)
      //     }

      //     // console.log("formik", formik)

      //   })
      // }
    }
    init()
  }, [])

  useEffect(() => {
    async function init() {
      console.log("PROVIDER_STATE", PROVIDER_STATE)
      console.log("PROVIDER_EMAIL_STATE", PROVIDER_EMAIL_STATE)
      if (PROVIDER_STATE === 'emailpassword') {
        setRegiSchema(
          Yup.object().shape({
            // emailAddress: Yup.string()
            //   .email('Invalid email')
            //   .required('Required'),
            // mobileNumber: Yup.string()
            //   .min(2, 'Too Short!')
            //   .max(50, 'Too Long!')
            //   .required('Required'),
            // username: Yup.string()
            //   // .email('Invalid username')
            //   .required('Required'),
            password: Yup.string()
              .matches(/^\S*$/, 'Whitespace is not allowed')
              .matches(/^(?=.*?[A-Z])/, 'At least one upper case letter is required')
              .matches(/^(?=.*?[a-z])/, 'At least one lower case letter is required')
              .matches(/^(?=.*?[0-9])/, 'At least one digit is required')
              .matches(/^(?=.*?[#?!@$%^&*-])/, 'At least one special character is required')
              .min(8, 'At least 8 characters')
              .max(50, 'Too Long!')
              .required('Required'),
            confirmPassword: Yup.string()
              .matches(/^\S*$/, 'Whitespace is not allowed')
              .oneOf([Yup.ref('password'), null], 'Passwords must match')
              .required('Required'),
          })
        )
      } else if (PROVIDER_STATE === 'facebook' || PROVIDER_STATE === 'apple' || PROVIDER_STATE === 'google') {
        console.log("No policyNumber saved, redirecting to login", registrationSTATE.policyNumber);
        navigate("/registration-step-otp")
      }
    }
    init()
  }, [])

  const backendValidate = async ({
    password,
  }) => {
    let regLogJsonReq = {
      type: "Registration",
      step: "2/6 - Input User Details",
      issue: "",
      description: "",
      status: 'F',
      policyNumber: registrationSTATE.policyNumber,
      firstName: registrationSTATE.firstName,
      middleName: registrationSTATE.middleName,
      lastName: registrationSTATE.lastName,
      suffix: registrationSTATE.suffix,
      birthday: registrationSTATE.birthday,
      email: registrationSTATE.emailAddress,
      mobileNumber: registrationSTATE.mobileNumber,
      provider: PROVIDER_STATE,
      sourceDev: "MyPolicy Website",
      sourceGroup: "MyPolicy Website",
    }

    try {
      // return await axios.post(process.env.REACT_APP_API_URL + '/api/checkIfEmailExists', {
      //   email: emailAddress,
      // }).then(async (checkIfEmailExistsRes) => {
      //   if (checkIfEmailExistsRes.data.emailExists) {
      //     // console.log("checkIfPolicyExistsRes.data.policyExists", checkIfPolicyExistsRes.data.policyExists);
      //     setEmailExistsErrorModal(true)

      //     regLogJsonReq.issue = "Duplicate Email";
      //     regLogJsonReq.description = "Duplicate Email: " + emailAddress;
      //     await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)
      //     return
      //   }


      //   // return await axios.post(process.env.REACT_APP_API_URL + '/api/checkIfMobileExists', {
      //   //   mobile: mobileNumber,
      //   // }).then(async (checkIfMobileExistsRes) => {
      //   //   if (checkIfMobileExistsRes.data.mobileExists) {
      //   //     // console.log("checkIfPolicyExistsRes.data.policyExists", checkIfPolicyExistsRes.data.policyExists);
      //   //     setMobileNumberExistsErrorModal(true)

      //   //     regLogJsonReq.issue = "Duplicate Mobile Number";
      //   //     regLogJsonReq.description = "Duplicate Mobile Number: " + mobileNumber;
      //   //     await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)
      //   //     return
      //   //   }

      //   return await axios.post(process.env.REACT_APP_API_URL + '/api/checkIfUsernameExists', {
      //     username,
      //   }).then(async (checkIfUsernameExistsRes) => {
      //     if (checkIfUsernameExistsRes.data.usernameExists) {
      //       // console.log("checkIfPolicyExistsRes.data.policyExists", checkIfPolicyExistsRes.data.policyExists);
      //       setUsernameExistsErrorModal(true)

      //       regLogJsonReq.issue = "Duplicate Username";
      //       regLogJsonReq.description = "Duplicate Username: " + username;
      //       await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)
      //       return
      //     }

      //     console.log('VALIDATED!!')
      //     return true
      //   })
      //   // })
      // })

      // const querySnapshot = await getDocs(collection(firestoreDB, "Table_User"));

      // let unique = true;

      // querySnapshot.forEach((doc) => {
      //   console.log(doc.id, doc.data());
      //   console.log('row user data', doc.data())
      //   if (doc.data().email == emailAddress) {
      //     console.log('setEmailExistsErrorModal')
      //     if (unique) {
      //       setEmailExistsErrorModal(true)
      //     }
      //     unique = false;
      //   } else if (doc.data().mobile_number == mobileNumber) {
      //     console.log('setMobileNumberExistsErrorModal')
      //     if (unique) {
      //       setMobileNumberExistsErrorModal(true)
      //     }
      //     unique = false;
      //   } else if (doc.data().username == username) {
      //     console.log('setUsernameExistsErrorModal')
      //     if (unique) {
      //       setUsernameExistsErrorModal(true)
      //     }
      //     unique = false;
      //   } else {
      //     console.log('VALIDATED!!')
      //     unique = true;
      //   }
      // });

      // console.log('unique', unique)
      // return unique

      console.log('VALIDATED!!')
      return true

    } catch (error) {
      console.log("axios error", error);
    }
  }


  const formik = useFormik({
    initialValues: {
      // emailAddress: email,
      // mobileNumber: mobileNumber,
      // username: '',
      password: '',
      confirmPassword: '',
    },
    enableReinitialize: true,
    validationSchema: regiSchema,
    onSubmit: async values => {
      // console.log({ values })
      console.log("formik.values", formik.values)
      const inputs = { ...values, policyAgreementDate: moment().format('MM-DD-YYYY hh:mm:ss a') }
      setFormValues({ ...inputs })

      // validate
      await backendValidate(inputs)
        .then((res) => {
          console.log("backendValidate res", res)
          // const correct = true
          if (res) {
            setAgreedToPrivacyPolicy(false)
            setAgreedToAcknowledgementSlip(false)
            setModalPrivacyPolicy(true)
          } else {
            // error
          }
        })

      // save record
      // saveToBackend({ ...values, birthday: dateThing })
    }
  })

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'background.default' }} >
      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={emailExistsErrorModalOpen}
        onClose={() => {
          setEmailExistsErrorModal(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={emailExistsErrorModalOpen}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6"
              component="h2" color="text.primary">
              Email already taken!
            </Typography>

            <Divider sx={{ mt: 3, mb: 1, width: '100%' }} />

            <Box sx={{ mx: 1 }}>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                The Email you have entered has already been taken.
              </Typography>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                If you think this is a mistake, please contact our customer support at 02- 88107888 for assistance.
              </Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
              <Button variant="contained"
                sx={{ mt: 4 }}
                onClick={() => {
                  setEmailExistsErrorModal(false)
                }}>
                Ok
              </Button>
            </Box>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={mobileNumberExistsErrorModalOpen}
        onClose={() => {
          setMobileNumberExistsErrorModal(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={mobileNumberExistsErrorModalOpen}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6"
              component="h2" color="text.primary">
              Mobile Number already taken!
            </Typography>

            <Divider sx={{ mt: 3, mb: 1, width: '100%' }} />

            <Box sx={{ mx: 1 }}>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                The Mobile Number you have entered has already been taken.
              </Typography>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                If you think this is a mistake, please contact our customer support at 02- 88107888 for assistance.
              </Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
              <Button variant="contained"
                sx={{ mt: 4 }}
                onClick={() => {
                  setMobileNumberExistsErrorModal(false)
                }}>
                Ok
              </Button>
            </Box>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={usernameExistsErrorModalOpen}
        onClose={() => {
          setUsernameExistsErrorModal(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={usernameExistsErrorModalOpen}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6"
              component="h2" color="text.primary">
              Username already taken!
            </Typography>

            <Divider sx={{ mt: 3, mb: 1, width: '100%' }} />

            <Box sx={{ mx: 1 }}>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                The Username you have entered has already been taken.
              </Typography>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                If you think this is a mistake, please contact our customer support at 02- 88107888 for assistance.
              </Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
              <Button variant="contained"
                sx={{ mt: 4 }}
                onClick={() => {
                  setUsernameExistsErrorModal(false)
                }}>
                Ok
              </Button>
            </Box>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalPrivacyPolicy}
        onClose={() => {
          setModalPrivacyPolicy(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalPrivacyPolicy}>
          <Box sx={{ ...style, maxWidth: 400 }}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Privacy Policy Statement
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Your privacy is important to us. By using this site, you agree to
              the use of cookies and your personal information in accordance
              with our{" "}
              <Link
                href="https://www.cocolife.com/about/privacy-policy/"
                target="_blank"
                sx={{ textDecoration: "none" }}
                color="text.linkActive"
              >
                Privacy Policy
              </Link>
            </Typography>

            <Stack direction="row">
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <Typography
                    color="text.secondary">
                    I AGREE to the Privacy Policy{' '}
                  </Typography>}
                checked={agreedToPrivacyPolicy}
                sx={{ mt: 4 }}
                onClick={() => {
                  setAgreedToPrivacyPolicy(!agreedToPrivacyPolicy)
                }} />
            </Stack>
            <Stack direction="row"
              sx={{ maxWidth: '70%' }}>
              <Typography
                sx={{ textAlign: 'left', marginLeft: 4, fontSize: '14px' }}
                color="text.secondary">
                {/* Please {' '} */}
                <Link
                  href="/privacy-policy"
                  target="_blank"
                  color="text.linkActive"
                  sx={{ textDecoration: "none" }}
                >
                  {/* click here  */}
                  Please view our Data Privacy Policy here &raquo;
                </Link>
                {/* to learn more about our Data Privacy. */}
              </Typography>
            </Stack>

            {agreedToPrivacyPolicy ?
              <Button variant="contained" onClick={async () => {
                setModalPrivacyPolicy(false)
                setModalAcknowledgementSlip(true)
                // setAgreedToPrivacyPolicy(!agreedToPrivacyPolicy)
              }} sx={{ mt: 4 }}>
                Proceed
              </Button>
              :
              <Button variant="contained" sx={{ mt: 4 }} disabled>
                Proceed
              </Button>}
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalAcknowledgementSlip}
        onClose={() => {
          setModalAcknowledgementSlip(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalAcknowledgementSlip}>
          <Box sx={{ ...style, maxWidth: 400 }}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Acknowledgement Slip
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '100%' }} />

            <Stack direction="row">
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <Typography
                    color="text.secondary">
                    I acknowledge the reciept of my Cocolife Insurance Policy.{' '}
                  </Typography>}
                checked={agreedToAcknowledgementSlip}
                sx={{ mt: 4 }}
                onClick={() => {
                  setAgreedToAcknowledgementSlip(!agreedToAcknowledgementSlip)
                }} />
            </Stack>

            {agreedToAcknowledgementSlip ?
              <Button variant="contained" onClick={async () => {
                setModalAcknowledgementSlip(false)
                let formValuesUpdated = formValues;
                formValuesUpdated.policyAgreementDate = moment().format('MM-DD-YYYY hh:mm:ss a')
                saveToStore(formValues)
                navigate("/registration-step-otp")
                // setAgreedToAcknowledgementSlip(!agreedToAcknowledgementSlip)
              }} sx={{ mt: 4 }}>
                Proceed
              </Button>
              :
              <Button variant="contained" sx={{ mt: 4 }} disabled>
                Proceed
              </Button>}
          </Box>
        </Fade>
      </Modal>


      <Container maxWidth="sm" sx={{ p: 2 }}>
        <Paper variant="outlined" sx={{ py: 5, px: 4 }}>
          <Stack spacing={4}>
            <Box>
              <img src={cocolife} style={{ height: "25px" }} />
            </Box>
            <Box>
              <Typography variant="h4" color="text.primary" sx={{ mb: 2 }}>
                User Registration
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }} >
                Only <strong>Cocolife Policy payors</strong> are allowed to register.
              </Typography>
              <Divider sx={{ mt: 1 }} />
            </Box>

            <FormControl sx={{ my: 1 }} fullWidth>
              <form onSubmit={formik.handleSubmit}>
                {/* {PROVIDER_STATE === 'emailpassword' || PROVIDER_STATE === 'facebook' || PROVIDER_STATE === 'apple' ?
                  <Stack>
                    <Typography variant="overline">Email address</Typography>

                    <TextField
                      name="emailAddress"
                      size="small"
                      variant="outlined"
                      hiddenLabel
                      value={formik.values.emailAddress}
                      onChange={(event) => {
                        formik.setFieldValue("emailAddress", event.target.value, true)
                        formik.setFieldValue("username", event.target.value)
                        formik.setFieldValue("username", event.target.value, true)
                      }}
                    />
                    {formik.errors.emailAddress ? <Typography color="red">{formik.errors.emailAddress}</Typography> : null}
                  </Stack>
                  :
                  <Stack>
                    <Typography variant="overline">Email address</Typography>

                    <TextField
                      name="emailAddress"
                      size="small"
                      variant="outlined"
                      hiddenLabel
                      // value={PROVIDER_EMAIL_STATE}
                      value={formik.values.emailAddress}
                      disabled
                    />
                    {formik.errors.emailAddress ? <Typography color="red">{formik.errors.emailAddress}</Typography> : null}
                  </Stack>
                } */}
                {/* <Stack sx={{ mt: 2 }}>
                  <Typography variant="overline">Mobile Number</Typography>

                  <TextField
                    name="mobileNumber"
                    size="small"
                    variant="outlined"
                    hiddenLabel
                    value={formik.values.mobileNumber}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.mobileNumber ? <Typography color="red">{formik.errors.mobileNumber}</Typography> : null}
                </Stack> */}
                {/* <Stack sx={{ mt: 2 }}>
                  <Typography variant="overline">Username</Typography>

                  <TextField
                    name="username"
                    size="small"
                    variant="outlined"
                    hiddenLabel
                    value={formik.values.username}
                    disabled
                  />
                  {formik.errors.username ? <Typography color="red">{formik.errors.username}</Typography> : null}
                </Stack> */}
                {PROVIDER_STATE === 'emailpassword' ?
                  <>
                    <Stack sx={{ mt: 2 }}>
                      <Typography variant="overline" >Password</Typography>

                      <TextField
                        name="password"
                        size="small"
                        variant="outlined"
                        type="password"
                        hiddenLabel
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.password ? <Typography color="red">{formik.errors.password}</Typography> : null}
                    </Stack>

                    <Stack sx={{ mt: 2 }}>
                      <Typography variant="overline" >Confirm Password</Typography>

                      <TextField
                        name="confirmPassword"
                        size="small"
                        variant="outlined"
                        type="password"
                        hiddenLabel
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.confirmPassword ? <Typography color="red">{formik.errors.confirmPassword}</Typography> : null}
                    </Stack>
                  </>
                  : null}


                {/* <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <FormControlLabel
                    sx={{ mt: 2, }}
                    control={<Checkbox />}
                    label={
                      <Typography>
                        By registering you agree to our {' '}
                        <Link
                          href="/privacy-policy"
                          target="_blank"
                          sx={{ textDecoration: "none" }}
                          color="text.linkActive"
                        >
                          Terms and Conditions (Privacy Notice)
                        </Link>
                      </Typography>}
                    checked={agreedToPrivacyPolicy}
                    onClick={() => {
                      setAgreedToPrivacyPolicy(!agreedToPrivacyPolicy)
                    }}
                  />
                </Box> */}


                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={3}
                  sx={{ mt: 5 }}
                >
                  <Button variant="text" size="large">
                    <Link
                      href="/registration"
                      sx={{ textDecoration: "none" }}
                      color="text.linkActive"
                    >
                      Back{" "}
                    </Link>
                  </Button>

                  <Button type="submit" variant="contained" sx={{ mt: 4 }}>
                    Proceed to OTP{" "}
                  </Button>
                </Stack>
              </form>
            </FormControl>

          </Stack>
        </Paper>
      </Container>
    </Box >
  );
}
