import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import LinearProgressWithLabel from "./LinearProgressWithLabel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 80,
  maxWidth: 500,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default function APILoadingOverlay(props) {

  // const [showThis, setShowThis] = useState(false);
  useEffect(() => {
    // setShowThis(props.open);
    setLoadingProgress(10);
  }, [props.open]);

  const [loadingProgress, setLoadingProgress] = useState(10);
  const [timerOnState, putTimerOnState] = useState();

  useEffect(() => {
    const timer = setInterval(() => {
      setLoadingProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 200);

    putTimerOnState(timer);

    return () => {
      clearInterval(timer);
    };
  }, [props.open])

  useEffect(() => {

    if (loadingProgress === 100) {

      // setShowThis(false);
      setLoadingProgress(10);

      // props.setOnParent(false);

      clearInterval(timerOnState);

    } else {
      // setShowThis(true);
    }
  }, [loadingProgress]);

  return (
    <>
      {props.open ?
        <LinearProgressWithLabel customlabel={props.customlabel} open={props.open} />
        :
        <>
          {props.children}
        </>
      }
    </>
  );
}
