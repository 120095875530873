import React, {
  useState,
  useEffect,
} from 'react';
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import ViewPolicy from "./ViewPolicy";
import { Link } from "@mui/material";
import APIErrorModal from "../components/APIErrorModal";
import UpdatePolicyButton from '../components/UpdatePolicyButton';
import { useSelector, useDispatch } from "react-redux";
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";
import APILoadingOverlay from "../components/APILoadingOverlay";
import axios from 'axios';

export default function MyPolicies({ redirectToPayOnline, autoViewPolicyParent = false, redirectedPolicyNumber }) {
  let dispatch = useDispatch();

  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)

  const [autoViewPolicy, setAutoViewPolicy] = useState(autoViewPolicyParent);
  const [viewPolicy, setViewPolicy] = useState(false);
  const [policyNumber, setPolicyNumber] = useState("");
  const [registeredPolicies, setRegisteredPolicies] = useState([]);
  const [policyNumberList, setPolicyNumberList] = useState([]);

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    dispatch(updateLoadingState(isLoading));
  }, [isLoading])

  useEffect(() => {
    async function init() {
      console.log("MyPolicyList handleViewPolicy", redirectedPolicyNumber)
      if (redirectedPolicyNumber != null) {
        setPolicyNumber(redirectedPolicyNumber)
      }
    }

    init()
  }, [])

  useEffect(() => {
    async function init() {
      // let registeredPolicies = {
      //   data: {
      //     "getRegisteredPoliciesResult": {
      //       "Data": {
      //         "RegisteredPolicies": [
      //           {
      //             "Plan": "FUTURE SAVINGS (PLATINUM)",
      //             "PolicyNumber": "T0095469",
      //             "PolicyStatus": "INFORCE"
      //           },
      //         ],
      //         "TotalCount": 1
      //       },
      //       "RequestedOn": "1/18/2022 6:02:56 PM",
      //       "Response": {
      //         "Code": "200",
      //         "Message": "Successful."
      //       },
      //       "Success": true
      //     }
      //   }
      // }

      // console.log("registeredPolicies", registeredPolicies)
      // console.log("viewPolicy", viewPolicy)

      // setRegisteredPolicies(registeredPolicies.data.getRegisteredPoliciesResult.Data.RegisteredPolicies);
    }

    init()
  }, [])

  useEffect(() => {
    async function init() {
      let currentAPICall = 'getRelatedPolicies';

      try {
        let policyNumberList = [
          POLICY_DATA,
        ]

        await axios.post(process.env.REACT_APP_API_URL + '/api/getAllPoliciesByCurrentPolicy', {
          policyNumber: POLICY_DATA
        }).then(async (getAllPoliciesByCurrentPolicyRes) => {
          // console.log("getAllPoliciesByCurrentPolicyRes", getAllPoliciesByCurrentPolicyRes);
          console.log("getAllPoliciesByCurrentPolicyRes.data.user.recordset", getAllPoliciesByCurrentPolicyRes.data.user.recordset);
          if (getAllPoliciesByCurrentPolicyRes.data.user === false) {
            console.log("error: user has no policies")
          } else {
            getAllPoliciesByCurrentPolicyRes.data.user.recordset.map((policyFromDB, index) => {
              console.log("policyFromDB", policyFromDB)
              console.log("policyFromDB.registered == 'true'", policyFromDB.registered == 'true')
              if (POLICY_DATA != policyFromDB.policy_number && policyFromDB.registered == 'true') {
                policyNumberList = [...policyNumberList, policyFromDB.policy_number]
              }
            })

            currentAPICall = 'getPolicyProfile';
            setPolicyNumberList([...policyNumberList]);
            let registeredPoliciesTMP = [];
            await Promise.all(
              policyNumberList.map((policyNumber, index) => {
                // console.log("policyNumber map", policyNumber)
                let policyProfileReqData = {
                  // policyNumber: 'T0095469',
                  policyNumber: policyNumber,
                }

                axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile',
                  policyProfileReqData
                ).then(async (res) => {
                  console.log("getPolicyProfile res.data.Data", res.data.Data);

                  if (res === 'null') {
                    setapiName('getPolicyProfile')
                    setapiResponse({
                      Code: '408',
                      Message: "API request timeout."
                    })
                    setAPIErrorModalOpen(true)
                    console.log('issue', 'getPolicyProfile res is null')
                    return
                  }

                  if (res.data.Success) {
                    await axios.post(process.env.REACT_APP_API_URL + '/api/checkOnlinePayEligibility', {
                      policyNumber: policyNumber
                    }).then(async (checkOnlinePayEligibilityRes) => {
                      console.log("checkOnlinePayEligibility res", checkOnlinePayEligibilityRes);
                      console.log("checkOnlinePayEligibility res.data.Data", checkOnlinePayEligibilityRes.data.Data);

                      if (checkOnlinePayEligibilityRes === 'null') {
                        setapiName('checkOnlinePayEligibility')
                        setapiResponse({
                          Code: '408',
                          Message: "API request timeout."
                        })
                        setAPIErrorModalOpen(true)
                        console.log('issue', 'checkOnlinePayEligibility res is null')
                        return
                      }

                      if (checkOnlinePayEligibilityRes.data.Success) {
                        if (checkOnlinePayEligibilityRes?.data?.Data?.Remarks != "Success" ||
                          policyNumber.split("")[0].toLowerCase() != 'v') {
                          if (process.env.REACT_APP_ENV === 'local') {
                            policyNumber = {
                              "Plan": res.data.Data.PlanName,
                              "PolicyNumber": policyNumber,
                              "PolicyStatus": res.data.Data.Status,
                              "PolicyPaymentEligible": true,
                            }
                          } else {
                            policyNumber = {
                              "Plan": res.data.Data.PlanName,
                              "PolicyNumber": policyNumber,
                              "PolicyStatus": res.data.Data.Status,
                              "PolicyPaymentEligible": false,
                            }
                          }
                        } else {
                          policyNumber = {
                            "Plan": res.data.Data.PlanName,
                            "PolicyNumber": policyNumber,
                            "PolicyStatus": res.data.Data.Status,
                            "PolicyPaymentEligible": true,
                          }
                        }
                        console.log('policyNumber to add', policyNumber)

                        if (registeredPoliciesTMP === null || registeredPoliciesTMP.length === 0) {
                          console.log('First Policy is', policyNumber)
                          registeredPoliciesTMP = [policyNumber];
                        } else {
                          console.log('Adding new policy', policyNumber)
                          registeredPoliciesTMP = [...registeredPoliciesTMP, policyNumber];
                        }

                        console.log('Setting all policies to state')
                        setRegisteredPolicies([...registeredPoliciesTMP]);

                      } else {
                        setapiName('checkOnlinePayEligibility')
                        setapiResponse(checkOnlinePayEligibilityRes.data)
                        setAPIErrorModalOpen(true)
                        console.log('issue', 'checkOnlinePayEligibility res', checkOnlinePayEligibilityRes.data.Response)
                        return
                      }
                    })
                  } else {
                    setapiName('getPolicyProfile')
                    setapiResponse(res.data)
                    setAPIErrorModalOpen(true)
                    console.log('issue', 'getPolicyProfile res', res.data.Response)
                    return
                  }
                })
              })
            )
          }

          setIsLoading(false)
        })
      } catch (error) {
        if (error.response) {
          console.error("Error.response:", error.response);
          setIsLoading(false);
          setapiName(currentAPICall)
          setapiResponse({
            Code: error.response.status,
            Message: error.response.statusText
          })
          setAPIErrorModalOpen(true)
          return
        } else {
          setIsLoading(false);
          setapiName(currentAPICall)
          setapiResponse({
            Code: '408',
            Message: "API request timeout."
          })
          setAPIErrorModalOpen(true)
          console.log('issue-', currentAPICall, error)
          return
        }
      }

    }

    init()
  }, [])

  useEffect(() => {
    async function init() {
      console.log("autoViewPolicy", autoViewPolicy)
      console.log("autoViewPolicyParent", autoViewPolicyParent)
      setViewPolicy(autoViewPolicy)
      setAutoViewPolicy(false);
    }

    init()
  }, [])

  // useEffect(() => {
  //   async function init() {
  //     console.log("registeredPolicies", registeredPolicies)
  //   }

  //   init()
  // }, [registeredPolicies])

  const handleViewPolicy = () => {
    console.log("PolicyNumber passed to View", policyNumber);
    if (viewPolicy === true) {
      setViewPolicy(false)
    }
    else {
      setViewPolicy(true)
    }
    // setViewPolicy(value);
  };

  const getPolicyNumber = () => {
    console.log("Passing to View Policy handleViewPolicy", redirectedPolicyNumber)
    return policyNumber;
  }

  return (
    <div>
      {viewPolicy === true ? (
        <div>
          <Box>
            <APILoadingOverlay customlabel={loadingCustomLabel}
              open={isLoading} setOnParent={(value) => setIsLoading(value)}>
              <Link
                onClick={handleViewPolicy}
                sx={{ textDecoration: "none", cursor: "pointer", mb: 5 }}
                color="text.linkActive"
              >
                Go Back to My Policies
              </Link>
            </APILoadingOverlay>
          </Box>
          <br />
          <ViewPolicy getPolicyNumber={getPolicyNumber} />
        </div>
      ) : (
        <Box>
          <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />

          <APILoadingOverlay customlabel={loadingCustomLabel}
            open={isLoading} setOnParent={(value) => setIsLoading(value)}>
            <Typography variant="h4" sx={{ mb: 2 }} color="text.primary">
              My Policies
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Select a policy from the list below
            </Typography>
            <Box sx={{ mt: 5 }}>
              <Typography variant="subtitle2">My Registered Policies</Typography>
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ fontWeight: "600" }}>
                      <TableCell align="left" sx={{ fontWeight: "600" }}>
                        Policy Number
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "600" }}>
                        Plan
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "600" }}>
                        Policy Status{" "}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "600" }}>
                        Actions
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "600" }}>

                      </TableCell>
                      {/* <TableCell align="center" sx={{ fontWeight: "600" }}>

                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  {registeredPolicies.length > 0 ?
                    registeredPolicies.map((row, index) => (
                      <TableBody key={index}>
                        {row.PolicyNumber === POLICY_DATA ?
                          <TableRow
                            hover
                            key={index}
                            sx={{
                              cursor: "pointer",
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.PolicyNumber}
                            </TableCell>
                            <TableCell align="center">{row.Plan}</TableCell>
                            <TableCell align="center">{row.PolicyStatus}</TableCell>
                            <TableCell align="center">
                              <Button onClick={(() => {
                                console.log("handleViewPolicy")
                                setPolicyNumber(row.PolicyNumber)
                                handleViewPolicy()
                              })}
                                variant="contained">
                                View Selected
                              </Button>
                            </TableCell>
                            {/* <TableCell align="center">
                              {POLICY_DATA != row.PolicyNumber && policyNumberList.length > 1 ?
                                < UpdatePolicyButton newPolicyNumber={row.PolicyNumber} />
                                : null}
                            </TableCell> */}
                            <TableCell align="center">
                              {row.PolicyPaymentEligible ?
                                <Button variant="contained" onClick={redirectToPayOnline}>Pay Online</Button>
                                :
                                null}
                            </TableCell>

                          </TableRow>
                          :
                          <TableRow
                            hover
                            key={index}
                            sx={{
                              cursor: "pointer",
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.PolicyNumber}
                            </TableCell>
                            <TableCell align="center">{row.Plan}</TableCell>
                            <TableCell align="center">{row.PolicyStatus}</TableCell>
                            {/* <TableCell align="center">
                              <Button onClick={(() => {
                                console.log("handleViewPolicy")
                                setPolicyNumber(row.PolicyNumber)
                                handleViewPolicy()
                              })}
                                variant="contained">
                                View
                              </Button>
                            </TableCell> */}
                            <TableCell align="center">
                              {POLICY_DATA != row.PolicyNumber && policyNumberList.length > 1 ?
                                < UpdatePolicyButton newPolicyNumber={row.PolicyNumber} />
                                : null}
                            </TableCell>
                            <TableCell align="center">
                              {/* {row.PolicyStatus.toLowerCase() != 'inforce' ?
                                <Button variant="contained" onClick={redirectToPayOnline}> Pay Online</Button>
                                :
                                null} */}
                            </TableCell>

                          </TableRow>}
                      </TableBody>
                    ))
                    : null}

                </Table>
              </TableContainer>
            </Box>
          </APILoadingOverlay>
        </Box>
      )}{" "}
    </div>
  );
}
