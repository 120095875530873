import React, {
  useState,
  useEffect,
} from 'react';
import {
  Divider, Grid, Button, Select, MenuItem, Box,
  Container, FormControl, Paper, Stack, Table,
  TableBody, TableCell, TableContainer, TableHead,
  TableRow, TextField, Typography,
} from "@mui/material";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import cocolife from "../assets/cocolife-horizontal.png";
import PrintIcon from '@mui/icons-material/Print';
import { useSelector, useDispatch } from "react-redux";
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";
import APILoadingOverlay from "../components/APILoadingOverlay";
import APIErrorModal from "../components/APIErrorModal";
import axios from 'axios';
import moment from 'moment-timezone';
import Finance from 'tvm-financejs';
import coco_logo from "../assets/cocolife-horizontal.png";
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { renderToString } from "react-dom/server";

export default function LoanAmortization({ appliedLoan }) {
  let dispatch = useDispatch();

  const finance = new Finance();

  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  // const POLICY_DATA = 'B0323698';
  // const POLICY_DATA = 'B0293553';
  // const USERNAME = useSelector((state) => state.username.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)

  const [modeOfPayment, setModeOfPayment] = useState(12);
  const [modeOfPaymentName, setModeOfPaymentName] = useState('Monthly');
  const [currency, setCurrency] = useState('')
  const [loanAmount, setLoanAmount] = useState(1)
  const [displayLoanAmount, setDisplayLoanAmount] = useState(1)
  const [numberOfYearsToPay, setNumberOfYearsToPay] = useState(0)
  const [numberOfMonthsToPay, setNumberOfMonthsToPay] = useState(12)
  const [interestRate, setInterestRate] = useState(0)
  const [firstPaymentDate, setFirstPaymentDate] = useState(moment().format('MM/DD/YYYY'))
  const [totalNumberOfMonthsToPay, setTotalNumberOfMonthsToPay] = useState(12)

  const [hasIllustrated, setHasIllustrated] = useState(false);

  const [accruedInterest, setAccruedInterest] = useState(0)
  const [principalBalanceAsOfFPD, setPrincipalBalanceAsOfFPD] = useState(0)
  const [outstandingBalanceAsOfFPD, setOutstandingBalanceAsOfFPD] = useState(0)
  const [periodicPayment, setPeriodicPayment] = useState(0)

  const [loanCalcParams, setLoanCalcParams] = useState({});
  const [loanTableData, setLoanTableData] = useState({});

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    dispatch(updateLoadingState(isLoading));
  }, [isLoading])

  useEffect(() => {
    async function init() {

      let loanCalcParamsTMP;

      await axios.post(process.env.REACT_APP_API_URL + '/api/getLoanCalculatorParams', {
        policyNumber: POLICY_DATA
      }).then(async (res) => {
        console.log("getLoanCalculatorParams res.data.Data", res.data.Data);
        if (res === 'null') {
          setapiName('getLoanCalculatorParams')
          setapiResponse({
            Code: '408',
            Message: "API request timeout."
          })
          setAPIErrorModalOpen(true)
          console.log('issue', 'getLoanCalculatorParams res is null')
          return
        }

        if (res.data.Success) {
          // setLoans(res.data.Data.PolicyLoan);

          loanCalcParamsTMP = res.data.Data;

          await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile', {
            policyNumber: POLICY_DATA,
          }).then(
            async (res) => {
              console.log("getPolicyProfile res.data?.Data", res.data?.Data)
              if (res === 'null') {
                setapiName('getPolicyProfile')
                setapiResponse({
                  Code: '408',
                  Message: "API request timeout."
                })
                setAPIErrorModalOpen(true)
                console.log('issue', 'getPolicyProfile res is null')
                return
              }

              if (res.data.Success) {
                console.log('policy profile updated')

                let issueDateTMP = res.data.Data.IssueDate
                let yearsDiff = moment().diff(moment(issueDateTMP, 'MM/DD/YYYY'), 'years')
                let nextAnnivDate = moment(issueDateTMP, 'MM/DD/YYYY').add(yearsDiff + 1, 'years')

                loanCalcParamsTMP = {
                  ...loanCalcParamsTMP,
                  ...res.data.Data,
                  NextAnnivDate: nextAnnivDate
                };

                await axios.post(process.env.REACT_APP_API_URL + '/api/getInsuredProfile', {
                  policyNumber: POLICY_DATA,
                }).then(
                  async (res) => {
                    // console.log("getInsuredProfile res.data.Data", res.data.Data);

                    if (res === 'null') {
                      setapiName('getInsuredProfile')
                      setapiResponse({
                        Code: '408',
                        Message: "API request timeout."
                      })
                      setAPIErrorModalOpen(true)
                      console.log('issue', 'getInsuredProfile res is null')
                      return
                    }

                    if (res.data.Success) {
                      console.log('insured profile updated')

                      loanCalcParamsTMP = {
                        ...loanCalcParamsTMP,
                        ...res.data.Data,
                        InsuredName: res.data.Data.Name,
                        InsuredBirthday: res.data.Data.Birthday
                      };


                      await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyOwnerProfile', {
                        policyNumber: POLICY_DATA,
                      }).then(
                        async (res) => {
                          // console.log("getPolicyOwnerProfile res.data.Data", res.data.Data);

                          if (res === 'null') {
                            setapiName('getPolicyOwnerProfile')
                            setapiResponse({
                              Code: '408',
                              Message: "API request timeout."
                            })
                            setAPIErrorModalOpen(true)
                            console.log('issue', 'getPolicyOwnerProfile res is null')
                            return
                          }

                          if (res.data.Success) {
                            console.log('policy owner profile updated')

                            loanCalcParamsTMP = {
                              ...loanCalcParamsTMP,
                              ...res.data.Data,
                              OwnerName: res.data.Data.Name,
                              OwnerBirthday: res.data.Data.Birthday
                            };
                            console.log("loanCalcParamsTMP", loanCalcParamsTMP)
                            console.log("loanCalcParams", loanCalcParams)
                            console.log("loanCalcParamsTMP.LoanBalance", loanCalcParamsTMP.LoanBalance)
                            console.log("appliedLoan", appliedLoan)

                            loanCalcParamsTMP.MaxLoanableAmount = loanCalcParamsTMP.LoanableAmount
                            loanCalcParamsTMP.LoanableAmount = loanCalcParamsTMP.LoanableAmount - loanCalcParamsTMP.LoanBalance
                            loanCalcParamsTMP.LoanableAmount = loanCalcParamsTMP.LoanableAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 4 })
                            loanCalcParamsTMP.LoanableAmount = parseFloat(loanCalcParamsTMP.LoanableAmount.replace(",", ""))

                            if (loanCalcParamsTMP.LoanableAmount < 0) loanCalcParamsTMP.LoanableAmount = 0

                            if (process.env.REACT_APP_ENV === 'local') {
                              console.log("local")
                              loanCalcParamsTMP.LoanableAmount = 31309.87
                              setLoanAmount(parseFloat(loanCalcParamsTMP.LoanableAmount))

                            } else if (appliedLoan > 0) {
                              console.log("appliedLoan > 0:", appliedLoan)

                              setLoanAmount(parseFloat(appliedLoan))
                              loanCalcParamsTMP.loanAmount = parseFloat(appliedLoan)

                            } else {
                              console.log("appliedLoan < 0:", appliedLoan)
                              // if (parseFloat(loanCalcParamsTMP.LoanableAmount) < 0) {
                              //   setLoanAmount(0)
                              // } else {
                              //   setLoanAmount(parseFloat(loanCalcParamsTMP.LoanableAmount))
                              // }

                              setLoanAmount(0)
                            }
                            console.log("loanCalcParamsTMP.LoanBalance 2", loanCalcParamsTMP.LoanBalance)

                            setLoanCalcParams(loanCalcParamsTMP)

                            setIsLoading(false)
                          } else {

                            setIsLoading(false)
                            setapiName('getPolicyOwnerProfile')
                            setapiResponse(res.data)
                            setAPIErrorModalOpen(true)
                            console.log('issue', 'getPolicyOwnerProfile res', res.data.Response)
                            return
                          }
                        })

                    } else {

                      setIsLoading(false)
                      setapiName('getInsuredProfile')
                      setapiResponse(res.data)
                      setAPIErrorModalOpen(true)
                      console.log('issue', 'getInsuredProfile res', res.data.Response)
                      return
                    }
                  })

              } else {

                setIsLoading(false)
                setapiName('getPolicyProfile')
                setapiResponse(res.data)
                setAPIErrorModalOpen(true)
                console.log('issue', 'getPolicyProfile res', res.data.Response)
                return
              }
            })

        } else {

          setIsLoading(false)
          setapiName('getLoanCalculatorParams')
          setapiResponse(res.data)
          setAPIErrorModalOpen(true)
          console.log('issue', 'getLoanCalculatorParams res', res.data.Response)
          return
        }
      })

      // let tmpPremiumPaymentList = [
      //   { 'date': "11/16/2021", 'principal': "24,2:66.25", 'interest': "-", 'payment': "-", 'outstanding': "24,2:66.25" },
      //   { 'date': "12/3/2021", 'principal': "24,2:66.25", 'interest': "113.02", 'payment': "-", 'outstanding': "24,3:79.27" },
      //   { 'date': "12/3/2021", 'principal': "24,2:66.25", 'interest': "-", 'payment': "4,1:47.75", 'outstanding': "20,2:31.52" },
      //   { 'date': "1/3/2022", 'principal': "20,2:31.52", 'interest': "171.83", 'payment': "4,1:47.75", 'outstanding': "16,2:55.60" },
      //   { 'date': "2/3/2022", 'principal': "16,2:55.60", 'interest': "138.06", 'payment': "4,1:47.75", 'outstanding': "12,2:45.91" },
      //   { 'date': "3/3/2022", 'principal': "12,2:45.91", 'interest': "93.94", 'payment': "4,1:47.75", 'outstanding': "8,1:92.10" },
      //   { 'date': "4/3/2022", 'principal': "8,1:92.10", 'interest': "69.58", 'payment': "4,1:47.75", 'outstanding': "4,1:13.93" },
      //   { 'date': "5/3/2022", 'principal': "4,1:13.93", 'interest': "33.81", 'payment': "4,1:47.75", 'outstanding': "(0.01)" },
      // ]

      // setLoanTableData(tmpPremiumPaymentList);
    }
    init()
  }, [])

  useEffect(() => {
    async function init() {


    }
    init()
  }, [currency])

  const computeLoanAmortization = () => {

    try {
      setDisplayLoanAmount(loanAmount);

      let currentDate = moment().format('MM/DD/YYYY')
      let annivDate = moment(loanCalcParams.NextAnnivDate).format('MM/DD/YYYY');

      if (process.env.REACT_APP_ENV === 'local') {
        annivDate = ('8/25/2022');
        loanCalcParams.NextAnnivDate = '8/25/2022'
      }

      let firstPaymentDateTmp = moment(firstPaymentDate)
      let daysBeforeFirstPaymentDate = moment(firstPaymentDate).diff(moment().subtract(1, 'day'), 'days')
      let monthsBeforeFirstPaymentDate = moment(firstPaymentDate).diff(moment(), 'months')

      let loanAmountTmp = parseFloat(loanAmount) + parseFloat(loanCalcParams.LoanBalance);
      let accruedInterest;
      let principalBalanceAsOfFPD = parseFloat(loanAmount) + parseFloat(loanCalcParams.LoanBalance);
      let outstandingBalanceAsOfFPD;
      let interestRate = loanCalcParams?.InterestRate
      let periodicInterestRate;
      let modeOfPaymentTmp = modeOfPayment;
      let numberOfMonthsInModeOfPayment;
      let numberOfYearsToPayTmp = numberOfYearsToPay;
      let numberOfMonthsToPayTmp = numberOfMonthsToPay;
      let totalNumberOfMonthsToPay;
      let totalPaymentPeriods;
      let periodicPaymentTmp = periodicPayment

      let firstInterestBeforeFPD = false;
      let currentDateIsEndOfMonth = false;

      if (modeOfPayment == 1) {
        numberOfMonthsInModeOfPayment = 12
      } else if (modeOfPayment == 2) {
        numberOfMonthsInModeOfPayment = 6
      } else if (modeOfPayment == 3) {
        numberOfMonthsInModeOfPayment = 4
      } else if (modeOfPayment == 12) {
        numberOfMonthsInModeOfPayment = 1
      }

      let loanTable = [];

      if (numberOfYearsToPayTmp > 0 && numberOfMonthsToPayTmp > 0) {
        totalNumberOfMonthsToPay = (parseFloat((numberOfYearsToPayTmp * 12)) + parseFloat(numberOfMonthsToPayTmp));
        console.log("totalNumberOfMonthsToPay", totalNumberOfMonthsToPay)
      } else if (numberOfYearsToPayTmp <= 0 && numberOfMonthsToPayTmp > 0) {
        totalNumberOfMonthsToPay = numberOfMonthsToPayTmp;
      } else if (numberOfYearsToPayTmp > 0 && numberOfMonthsToPayTmp <= 0) {
        totalNumberOfMonthsToPay = (numberOfYearsToPayTmp * 12);
      } else {
        totalNumberOfMonthsToPay = 1
      }
      console.log("totalNumberOfMonthsToPay", totalNumberOfMonthsToPay)

      totalPaymentPeriods = (totalNumberOfMonthsToPay / 12) * modeOfPaymentTmp;
      console.log("modeOfPaymentTmp", modeOfPaymentTmp)
      console.log("totalPaymentPeriods", totalPaymentPeriods)

      periodicInterestRate = interestRate / modeOfPaymentTmp;

      if (totalPaymentPeriods > 0) {
        outstandingBalanceAsOfFPD = ((loanAmountTmp * daysBeforeFirstPaymentDate * interestRate) / 365) + loanAmountTmp
        // outstandingBalanceAsOfFPD = finance.FV(periodicInterestRate, (parseFloat(daysBeforeFirstPaymentDate) / 365) * modeOfPayment, null, loanAmountTmp * -1)
        // console.log("outstandingBalanceAsOfFPD", outstandingBalanceAsOfFPD)
        console.log("outstandingBalanceAsOfFPD", outstandingBalanceAsOfFPD)
      }

      console.log("periodicInterestRate", periodicInterestRate)
      console.log("totalPaymentPeriods", totalPaymentPeriods)
      console.log("outstandingBalanceAsOfFPD", outstandingBalanceAsOfFPD)
      periodicPaymentTmp = finance.PMT(periodicInterestRate, totalPaymentPeriods, outstandingBalanceAsOfFPD * -1)
      console.log("periodicPaymentTmp", periodicPaymentTmp)

      console.log("periodicInterestRate", periodicInterestRate)
      console.log("number", 1)
      console.log("totalPaymentPeriods", totalPaymentPeriods)
      console.log("outstandingBalanceAsOfFPD", outstandingBalanceAsOfFPD)

      let loanRow = {
        date: '',
        payment: '-',
        principal: '-',
        principalColumn: '-',
        interest: '-',
        outstanding: 0,
        paymentNumber: 1,
      };
      loanRow.principalColumn = principalBalanceAsOfFPD;
      loanRow.outstanding = principalBalanceAsOfFPD;
      loanRow.date = moment().format('MM/DD/YYYY');

      loanTable = [loanRow]
      let totalDaysTmp = 0;
      let addedIndex = 0;
      let fpdIsSameAsCurrentDate = true;

      let passedBBlock = false

      console.log("totalNumberOfMonthsToPay", totalNumberOfMonthsToPay)
      console.log("monthsBeforeFirstPaymentDate", monthsBeforeFirstPaymentDate)
      console.log("monthsBeforeFirstPaymentDate", monthsBeforeFirstPaymentDate)
      // Create table
      for (var index = 0; index < parseFloat(totalNumberOfMonthsToPay) + parseFloat(monthsBeforeFirstPaymentDate); index++) {
        let indexTmp = index

        let loanRowTmp = { date: '', payment: '-', principal: '-', principalColumn: '-', interest: '-', outstanding: '-', paymentNumber: 1, };
        console.log("~~~LOOP START~~~")
        console.log("index 1", index)
        loanRowTmp.paymentNumber = index + 1;
        console.log("index 2", index)
        console.log("addedIndex", addedIndex)
        loanRowTmp.date = moment().add(index + 1, 'months').format('MM/DD/YYYY');
        console.log("loanRowTmp.date", loanRowTmp.date);
        console.log("firstPaymentDateTmp", firstPaymentDateTmp.format('MM/DD/YYYY'));

        // Check if Accrued Interest Row
        if (moment(loanRowTmp.date).isBefore(moment(firstPaymentDateTmp))) {
          console.log("FLOW - A: row date is before fpd");

          let previousInterest = loanTable[index]?.interest
          if (previousInterest === '-') {
            previousInterest = 0;
          }

          console.log("finance.IPMT(periodicInterestRate, 1, 1, outstandingBalanceAsOfFPD * -1)", finance.IPMT(periodicInterestRate, 1, 1, outstandingBalanceAsOfFPD * -1))

          loanRowTmp.principalColumn = principalBalanceAsOfFPD;
          // loanRowTmp.interest = finance.IPMT(periodicInterestRate, 1, totalNumberOfMonthsToPay + monthsBeforeFirstPaymentDate, null, outstandingBalanceAsOfFPD) + previousInterest
          loanRowTmp.interest = (((loanAmountTmp * (moment(loanRowTmp.date).diff(moment(loanTable[index].date), 'days'))) * interestRate) / 365) + previousInterest
          totalDaysTmp += moment(loanRowTmp.date).diff(moment(loanTable[index].date), 'days')
          console.log("days over daysBeforeFirstPaymentDate:", totalDaysTmp, ' / ', daysBeforeFirstPaymentDate)
          loanRowTmp.outstanding = principalBalanceAsOfFPD + loanRowTmp.interest;

          if (loanTable.length > 0) {
            loanTable = [...loanTable, loanRowTmp];
          } else {
            loanTable = [loanRowTmp];
          }

          console.log("FLOW - A - 1: loanTable", loanTable)

          if (moment(loanRowTmp.date).isSame(moment(firstPaymentDateTmp), 'months')) {
            // Check if Last Accrued and First Payment Row
            console.log("FLOW - A - 1 - 1: Date is same month as FPD")

            let loanRowTmp2 = { date: '', payment: '-', principal: '-', principalColumn: '-', interest: '-', outstanding: '-', paymentNumber: 1, };

            let previousInterest2 = loanRowTmp?.interest
            if (previousInterest2 === '-') {
              previousInterest2 = 0;
            }

            loanRowTmp2.date = moment(firstPaymentDateTmp).format('MM/DD/YYYY');

            // Add last Accrued Interest
            loanRowTmp2.principalColumn = principalBalanceAsOfFPD;
            loanRowTmp2.interest = (((loanAmountTmp * (moment(loanRowTmp2.date).diff(moment(loanRowTmp.date), 'days'))) * interestRate) / 365) + previousInterest2
            totalDaysTmp += moment(loanRowTmp2.date).diff(moment(loanRowTmp.date), 'days') - 1
            console.log("days over daysBeforeFirstPaymentDate:", totalDaysTmp, ' / ', daysBeforeFirstPaymentDate)
            loanRowTmp2.outstanding = principalBalanceAsOfFPD + loanRowTmp2.interest;

            if (loanTable.length > 0) {
              loanTable = [...loanTable, loanRowTmp2];
            } else {
              loanTable = [loanRowTmp2];
            }

            console.log("loanTable after last accured interest", loanTable)

            // Add first payment
            let loanRowTmp3 = { date: '', payment: '-', principal: '-', principalColumn: '-', interest: '-', outstanding: '-', paymentNumber: 1, };

            loanRowTmp3.principalColumn = principalBalanceAsOfFPD;
            loanRowTmp3.paymentNumber = 1;
            loanRowTmp3.payment = periodicPaymentTmp;
            let hiddenPrincipal = finance.PPMT(periodicInterestRate, 1, totalPaymentPeriods, outstandingBalanceAsOfFPD * -1);
            let hiddenInterest = finance.IPMT(periodicInterestRate, 1, totalPaymentPeriods, outstandingBalanceAsOfFPD * -1);
            loanRowTmp3.principal = 0;
            loanRowTmp3.interest = 0;
            loanRowTmp3.outstanding = outstandingBalanceAsOfFPD - hiddenPrincipal;
            // loanRowTmp3.outstanding = outstandingBalanceAsOfFPD - periodicPaymentTmp + loanRowTmp.interest;
            loanRowTmp3.date = moment(firstPaymentDateTmp).format('MM/DD/YYYY');

            if (loanTable.length > 0) {
              loanTable = [...loanTable, loanRowTmp3];
            } else {
              loanTable = [loanRowTmp3];
            }

            if (parseInt(moment(loanRowTmp.date).format('DD')) < parseInt(moment(firstPaymentDateTmp).format('DD'))) {
              console.log("FLOW - A - 1 - 1-1: ", parseInt(moment(loanRowTmp.date).format('DD')) > parseInt(moment(firstPaymentDateTmp).format('DD')))
              addedIndex += 2;
            }

            console.log("FLOW - A - 1 - 2: loanTable", loanTable)

            // console.log("loanTable after first payment", loanTable)
          }
        } else if (
          (moment(loanRowTmp.date).isSame(moment(firstPaymentDateTmp), 'months') &&
            moment(loanRowTmp.date).isSame(moment(firstPaymentDateTmp), 'years'))
          ||
          (moment(currentDate).isSame(moment(firstPaymentDateTmp), 'months') &&
            moment(currentDate).isSame(moment(firstPaymentDateTmp), 'years') &&
            fpdIsSameAsCurrentDate)
        ) {
          // Check if Last Accrued and First Payment Row
          console.log("FLOW - B: date on of first fpd");
          passedBBlock = true;

          if (moment(currentDate).isSame(moment(firstPaymentDateTmp), 'months') &&
            moment(currentDate).isSame(moment(firstPaymentDateTmp), 'years') &&
            fpdIsSameAsCurrentDate) {
            fpdIsSameAsCurrentDate = false;
          }

          let previousInterest = loanTable[index]?.interest
          if (previousInterest === '-') {
            previousInterest = 0;
          }

          loanRowTmp.date = moment(firstPaymentDateTmp).format('MM/DD/YYYY');

          // Add last Accrued Interest
          loanRowTmp.principalColumn = principalBalanceAsOfFPD;
          loanRowTmp.interest = (((loanAmountTmp * (moment(loanRowTmp.date).diff(moment(loanTable[index].date), 'days'))) * interestRate) / 365) + previousInterest
          // loanRowTmp.interest = (((loanAmountTmp * 1) * interestRate) / 365)
          totalDaysTmp += moment(loanRowTmp.date).diff(moment(loanTable[index].date), 'days') - 1
          console.log("days over daysBeforeFirstPaymentDate:", totalDaysTmp, ' / ', daysBeforeFirstPaymentDate)
          loanRowTmp.outstanding = principalBalanceAsOfFPD + loanRowTmp.interest;

          if (loanTable.length > 0) {
            loanTable = [...loanTable, loanRowTmp];
          } else {
            loanTable = [loanRowTmp];
          }

          console.log("loanTable after last accured interest", loanTable)

          // Add first payment
          let loanRowTmp2 = { date: '', payment: '-', principal: '-', principalColumn: '-', interest: '-', outstanding: '-', paymentNumber: 1, };

          loanRowTmp2.principalColumn = principalBalanceAsOfFPD;
          loanRowTmp2.paymentNumber = 1;
          loanRowTmp2.payment = periodicPaymentTmp;
          let hiddenPrincipal = finance.PPMT(periodicInterestRate, 1, totalPaymentPeriods, outstandingBalanceAsOfFPD * -1);
          let hiddenInterest = finance.IPMT(periodicInterestRate, 1, totalPaymentPeriods, outstandingBalanceAsOfFPD * -1);
          loanRowTmp2.principal = 0;
          // loanRowTmp2.interest = (((loanAmountTmp * 2) * interestRate) / 365)
          loanRowTmp2.outstanding = outstandingBalanceAsOfFPD - hiddenPrincipal;
          // loanRowTmp2.outstanding = outstandingBalanceAsOfFPD - periodicPaymentTmp + loanRowTmp.interest;
          loanRowTmp2.date = moment(firstPaymentDateTmp).format('MM/DD/YYYY');

          if (loanTable.length > 0) {
            loanTable = [...loanTable, loanRowTmp2];
          } else {
            loanTable = [loanRowTmp2];
          }

          addedIndex += 1;
          console.log("FLOW - B - 1: loanTable after first payment", loanTable)
        } else {
          // Start Payments row if date is after First Payment Date
          console.log("FLOW - C: Payments row after first payment date")
          // console.log("addedIndex", addedIndex)
          // console.log("current", index - monthsBeforeFirstPaymentDate + (addedIndex - 1))
          // console.log("last index", Math.ceil(totalNumberOfMonthsToPay / numberOfMonthsInModeOfPayment) - 1)

          if (addedIndex === 2 && (index - monthsBeforeFirstPaymentDate + (addedIndex - 1)) > Math.ceil(totalNumberOfMonthsToPay / numberOfMonthsInModeOfPayment) - 1) {
            // dont add row
            console.log("FLOW - C: Dont add row")
          } else if (passedBBlock) {
            console.log("FLOW - C - A: Payments row after first payment date")
            console.log("loanTable", loanTable)
            // console.log("index", index)
            // console.log("loanTable[index + addedIndex]", loanTable[index + 1])

            loanRowTmp.date = moment(firstPaymentDateTmp).add((index - monthsBeforeFirstPaymentDate + (addedIndex - 1)), 'months').format('MM/DD/YYYY');

            console.log("monthsBeforeFirstPaymentDate", monthsBeforeFirstPaymentDate)
            // console.log("index", index)
            // console.log("addedIndex", addedIndex)
            console.log("(index - monthsBeforeFirstPaymentDate + (addedIndex - 1)) + 1", (index - monthsBeforeFirstPaymentDate + (addedIndex - 1)) + 1)
            loanRowTmp.principalColumn = loanTable[index + addedIndex].outstanding;
            loanRowTmp.paymentNumber = (index - monthsBeforeFirstPaymentDate + (addedIndex - 1)) + 1;
            loanRowTmp.principal = finance.PPMT(periodicInterestRate, (index - monthsBeforeFirstPaymentDate + (addedIndex - 1)) + 1, totalPaymentPeriods, outstandingBalanceAsOfFPD * -1);
            loanRowTmp.interest = finance.IPMT(periodicInterestRate, (index - monthsBeforeFirstPaymentDate + (addedIndex - 1)) + 1, totalPaymentPeriods, outstandingBalanceAsOfFPD * -1);

            loanRowTmp.payment = periodicPaymentTmp;

            // if last iteration
            if ((index - monthsBeforeFirstPaymentDate + (addedIndex - 1)) == Math.ceil(totalNumberOfMonthsToPay / numberOfMonthsInModeOfPayment) - 1) {
              console.log("FLOW - C - A-A: last iteration")
              console.log("FLOW - C - A-A: last iteration: (index - monthsBeforeFirstPaymentDate + (addedIndex - 1)) + 1", (index - monthsBeforeFirstPaymentDate + (addedIndex - 1)) + 1)
              console.log("FLOW - C - A-A: last iteration: totalPaymentPeriods", totalPaymentPeriods)
              loanRowTmp.outstanding = Math.abs(Math.round((loanTable[index + addedIndex].outstanding - loanRowTmp.principal) * 100) / 100);
            } else {
              console.log("FLOW - C - A-B: not last iteration")
              // console.log("loanTable[index + addedIndex].outstanding", loanTable[index + addedIndex].outstanding)
              // console.log("loanRowTmp.principal", loanRowTmp.principal)
              loanRowTmp.outstanding = loanTable[index + addedIndex].outstanding - loanRowTmp.principal;
            }

            if (loanTable.length > 0) {
              loanTable = [...loanTable, loanRowTmp];
            } else {
              loanTable = [loanRowTmp];
            }
            console.log("FLOW - C - A - 1: Payments row after first payment date", loanTable)
          } else {
            console.log("FLOW - C - B: Payments row after first payment date")
            console.log("loanTable", loanTable)
            console.log("addedIndex", addedIndex)
            // console.log("index", index)
            // console.log("loanTable[index + addedIndex]", loanTable[index + 1])

            loanRowTmp.date = moment(firstPaymentDateTmp).add((index - monthsBeforeFirstPaymentDate + (addedIndex - 1)), 'months').format('MM/DD/YYYY');

            console.log("monthsBeforeFirstPaymentDate", monthsBeforeFirstPaymentDate)
            // console.log("index", index)
            // console.log("addedIndex", addedIndex)
            console.log("(index - monthsBeforeFirstPaymentDate + (addedIndex - 1)) ", (index - monthsBeforeFirstPaymentDate + (addedIndex - 1)))
            loanRowTmp.principalColumn = loanTable[index + addedIndex].outstanding;
            loanRowTmp.paymentNumber = (index - monthsBeforeFirstPaymentDate + (addedIndex - 1));
            loanRowTmp.principal = finance.PPMT(periodicInterestRate, (index - monthsBeforeFirstPaymentDate + (addedIndex - 1) + 1), totalPaymentPeriods, outstandingBalanceAsOfFPD * -1);
            loanRowTmp.interest = finance.IPMT(periodicInterestRate, (index - monthsBeforeFirstPaymentDate + (addedIndex - 1) + 1), totalPaymentPeriods, outstandingBalanceAsOfFPD * -1);

            loanRowTmp.payment = periodicPaymentTmp;

            // if last iteration
            if ((index - monthsBeforeFirstPaymentDate + (addedIndex - 1)) == Math.ceil(totalNumberOfMonthsToPay / numberOfMonthsInModeOfPayment) - 1) {
              console.log("FLOW - C - B-A: last iteration")
              console.log("FLOW - C - B-A: last iteration: (index - monthsBeforeFirstPaymentDate + (addedIndex - 1)) ", (index - monthsBeforeFirstPaymentDate + (addedIndex - 1)))
              console.log("FLOW - C - B-A: last iteration: totalPaymentPeriods", totalPaymentPeriods)
              loanRowTmp.outstanding = Math.abs(Math.round((loanTable[index + addedIndex].outstanding - loanRowTmp.principal) * 100) / 100);
            } else {
              console.log("FLOW - C - B-B: not last iteration")
              // console.log("loanTable[index + addedIndex].outstanding", loanTable[index + addedIndex].outstanding)
              // console.log("loanRowTmp.principal", loanRowTmp.principal)
              loanRowTmp.outstanding = loanTable[index + addedIndex].outstanding - loanRowTmp.principal;
            }

            if (loanTable.length > 0) {
              loanTable = [...loanTable, loanRowTmp];
            } else {
              loanTable = [loanRowTmp];
            }
            console.log("FLOW - C - B - 1: Payments row after first payment date", loanTable)

          }
        }
        console.log("~~~LOOP END~~~")
      }

      let newLoanTableWithoutDatesBeforeFPD = []
      // console.log("Removing dates before FPD")

      // Remove rows Before FPD except for row 1
      loanTable.map(async (row, index) => {
        // console.log("Date is: ", row.date)

        if (index == 0) {
          if (newLoanTableWithoutDatesBeforeFPD.length > 0) {
            newLoanTableWithoutDatesBeforeFPD = [...newLoanTableWithoutDatesBeforeFPD, row];
          } else {
            newLoanTableWithoutDatesBeforeFPD = [row];
          }
        } else if (index > 0) {
          if (moment(row.date).isSameOrAfter(moment(firstPaymentDateTmp), 'months') &&
            moment(row.date).isSameOrAfter(moment(firstPaymentDateTmp), 'days')) {
            // console.log("Adding date: ", row.date)

            if (newLoanTableWithoutDatesBeforeFPD.length > 0) {
              newLoanTableWithoutDatesBeforeFPD = [...newLoanTableWithoutDatesBeforeFPD, row];
            } else {
              newLoanTableWithoutDatesBeforeFPD = [row];
            }
          } else {
            // console.log("Removing date: ", row.date)
          }
        } else {
          // console.log("Skipping date: ", row.date)
        }
      })

      loanTable = newLoanTableWithoutDatesBeforeFPD;

      // Add anniversary row to tableData
      let annivDateCounter = 0;
      let newLoanTableWithAnniv = [];
      let useAddAnnivRowNextMonth = false;
      let useIgnoreFirstpaymentRowOnAnniv = false;
      console.log("loanTable before anniv", loanTable)

      loanTable.map(async (row, index) => {
        annivDate = moment(moment(annivDate).format('MM/DD') + moment(row.date).format('/YYYY')).format('MM/DD/YYYY');
        // console.log("Anniv Start: annivDate", annivDate)

        if (moment(loanTable[0].date).isSame(moment(loanTable[1].date), 'days') && index == 0) {
          console.log("Ignored first row date of loan table")
          return
        }

        // if (index == 1) {
        //   console.log("Ignored second row date of loan table")
        //   return
        // }

        // console.log("Anniv Start: 1", moment(annivDate).format('MM') == moment(row.date).format('MM'), moment(annivDate).format('MM/DD/YYYY'), moment(row.date).format('MM/DD/YYYY'))
        // console.log("Anniv Start: 1", !moment(annivDate).isSame(moment(row.date), 'days'))
        // console.log("Anniv Start: 1", !moment(annivDate).isSame(moment(firstPaymentDateTmp), 'days'))
        console.log("index", index)
        console.log(" moment(row.date).format('YYYY-MM-DD')", moment(row.date).format('YYYY-MM-DD'))
        console.log("moment(annivDate).isAfter(moment(row.date))", moment(annivDate).isAfter(moment(row.date)))
        console.log("index != 0", index != 0)
        console.log("moment(annivDate).isBefore(moment(row.date))", moment(annivDate).isBefore(moment(row.date)))
        console.log("index != (loanTable.length - 1)", index != (loanTable.length - 1))
        if (useIgnoreFirstpaymentRowOnAnniv) {
          // console.log("Anniv Start - A: Using useIgnoreFirstpaymentRowOnAnniv", row.date)
          useIgnoreFirstpaymentRowOnAnniv = false
          if (newLoanTableWithAnniv.length > 0) {
            newLoanTableWithAnniv = [...newLoanTableWithAnniv, row];
          } else {
            newLoanTableWithAnniv = [row];
          }
        } else if (useAddAnnivRowNextMonth) {
          console.log("Anniv Start - A")
          addAnnivRow()
          useAddAnnivRowNextMonth = false
        } else if (moment(annivDate).format('MM') == moment(row.date).format('MM') &&
          !moment(annivDate).isSame(moment(row.date), 'days') &&
          !moment(annivDate).isSame(moment(firstPaymentDateTmp), 'days') &&
          (moment(annivDate).isAfter(moment(row.date)) || index > 3) &&
          (moment(annivDate).isBefore(moment(row.date)) || index != (loanTable.length - 1))) {
          // console.log("Anniv Start - C: date is anniv month", row.date)
          console.log("moment(annivDate).format('YYYY-MM-DD')", moment(annivDate).format('YYYY-MM-DD'))
          console.log("moment(row.date).format('YYYY-MM-DD')", moment(row.date).format('YYYY-MM-DD'))


          if (moment(annivDate).isBefore(moment(row.date), 'days')) {
            // console.log(annivDate, "anniv month is before", row.date, 'row date')
            console.log("Anniv Start - B")
            addAnnivRow()
            // useAddAnnivRowNextMonth = true
          } else {
            // console.log(annivDate, "anniv month is after", row.date, 'row date')

            if (moment(annivDate).isSame(moment(firstPaymentDate), 'months')) {
              // console.log("useIgnoreFirstpaymentRowOnAnniv")
              useIgnoreFirstpaymentRowOnAnniv = true
            }

            useAddAnnivRowNextMonth = true
            if (newLoanTableWithAnniv.length > 0) {
              newLoanTableWithAnniv = [...newLoanTableWithAnniv, row];
            } else {
              newLoanTableWithAnniv = [row];
            }
          }

        } else {
          // console.log("Anniv Start - D: ")
          if (newLoanTableWithAnniv.length > 0) {
            newLoanTableWithAnniv = [...newLoanTableWithAnniv, row];
          } else {
            newLoanTableWithAnniv = [row];
          }
        }

        function addAnnivRow() {
          let loanRowTmp = {
            date: '',
            payment: '-',
            principal: '-',
            principalColumn: '-',
            interest: '-',
            outstanding: '-',
            paymentNumber: row.paymentNumber + .1
          };
          loanRowTmp.date = moment(moment(annivDate).format('MM/DD') + moment(row.date).format('/YYYY')).add(annivDateCounter, 'years').format('MM/DD/YYYY');
          // console.log("Anniv Start: loanRowTmp.date", loanRowTmp.date)
          // tmpFirstPaymentDateTmp = moment(firstPaymentDateTmp).subtract(1, 'months');

          // if ((moment(tmpFirstPaymentDateTmp).)) {

          // } else 
          if (moment(loanRowTmp.date).isBefore(moment(firstPaymentDateTmp))) {
            // If Anniv is in Accrued Interest Row
            // console.log("Anniv is in Accrued Interest Row")
            let previousInterest = loanTable[index - 1]?.interest
            if (previousInterest === '-') {
              previousInterest = 0;
            }
            // console.log("previousInterest", previousInterest)

            loanRowTmp.principalColumn = principalBalanceAsOfFPD;
            try {
              console.log("loanTable", loanTable)
              console.log("index", index)
              console.log("loanTable[index - 1]", loanTable[index - 1])
              console.log("loanTable[index - 1].date", loanTable[index - 1].date)
              console.log("loanRowTmp.date", loanRowTmp.date)
            } catch (error) {
              console.log("date error", error)
            }
            try {
              console.log("loanRowTmp", loanRowTmp)
              loanRowTmp.interest = (((loanAmountTmp * (moment(loanRowTmp.date).diff(moment(loanTable[index - 1].date), 'days'))) * interestRate) / 365) + previousInterest
            } catch (error) {
              console.log("loanRowTmp.interest error", error)
            }
            // console.log("loanTable[index - 1].date", loanTable[index - 1].date)
            // console.log("loanRowTmp.interest", loanRowTmp.interest)
            totalDaysTmp += moment(loanRowTmp.date).diff(moment(loanTable[index].date), 'days')
            // console.log("days over daysBeforeFirstPaymentDate:", totalDaysTmp, ' / ', daysBeforeFirstPaymentDate)
            loanRowTmp.outstanding = loanTable[index - 1].outstanding + loanRowTmp.interest - previousInterest;

            if (newLoanTableWithAnniv.length > 0) {
              newLoanTableWithAnniv = [...newLoanTableWithAnniv, loanRowTmp];
            } else {
              newLoanTableWithAnniv = [loanRowTmp];
            }

            let loanRowTmp2 = {
              date: '',
              payment: '-',
              principal: '-',
              principalColumn: '-',
              interest: '-',
              outstanding: '-',
              paymentNumber: row.paymentNumber + .2
            };

            loanRowTmp2.date = moment(row.date).format('MM/DD/YYYY');

            previousInterest = loanTable[index - 1]?.interest
            if (previousInterest === '-') {
              previousInterest = 0;
            }

            loanRowTmp2.principalColumn = principalBalanceAsOfFPD;
            loanRowTmp2.interest = (((loanAmountTmp * (moment(loanRowTmp2.date).diff(moment(loanRowTmp.date), 'days'))) * interestRate) / 365) + previousInterest
            // console.log("Anniv Start: loanAmountTmp:", loanAmountTmp)
            // console.log("Anniv Start: days:", moment(loanRowTmp2.date).diff(moment(loanRowTmp.date), 'days'))
            // console.log("Anniv Start: interestRate:", interestRate)
            // console.log("Anniv Start: previousInterest:", previousInterest)
            totalDaysTmp += moment(loanRowTmp2.date).diff(moment(loanTable[index].date), 'days')
            // console.log("days over daysBeforeFirstPaymentDate:", totalDaysTmp, ' / ', daysBeforeFirstPaymentDate)
            loanRowTmp2.outstanding = loanRowTmp.outstanding + loanRowTmp.interest - previousInterest;

            if (newLoanTableWithAnniv.length > 0) {
              newLoanTableWithAnniv = [...newLoanTableWithAnniv, loanRowTmp2];
            } else {
              newLoanTableWithAnniv = [loanRowTmp2];
            }

          } else {
            // If Anniv is in Payment Period Row   
            // console.log("Anniv is in Payment Period Row")
            let previousInterest = loanTable[index - 1]?.interest
            if (previousInterest === '-') {
              previousInterest = 0;
            }

            console.log("index", index)
            console.log("loanTable", loanTable)
            console.log("loanTable[index - 1]", loanTable[index - 1])
            loanRowTmp.principalColumn = loanTable[index - 1].outstanding;
            loanRowTmp.paymentNumber = row.paymentNumber + .1;
            // loanRowTmp.interest = ((loanTable[index + 1].principalColumn + periodicPaymentTmp - loanTable[index - 1].outstanding) / moment(row.date).diff(moment(loanTable[index - 1].date), 'days')) * moment(loanRowTmp.date).diff(moment(loanTable[index - 1].date), 'days')
            loanRowTmp.interest = (((loanAmountTmp * (moment(loanRowTmp.date).diff(moment(loanTable[index - 1].date), 'days'))) * interestRate) / 365)
            // console.log("Anniv Start: loanAmountTmp:", loanAmountTmp)
            // console.log("Anniv Start: days:", moment(loanRowTmp.date).diff(moment(loanTable[index - 1].date), 'days'))
            // console.log("Anniv Start: interestRate:", interestRate)
            // console.log("Anniv Start: interest:", (((loanAmountTmp * (moment(loanRowTmp.date).diff(moment(loanTable[index - 1].date), 'days'))) * interestRate) / 365))

            loanRowTmp.principal = (periodicPaymentTmp / (moment(row.date).diff(moment(loanTable[index - 1].date), 'days'))) - loanRowTmp.interest;
            // console.log("loanRowTmp.principal", loanRowTmp.principal)
            // console.log("(periodicPaymentTmp / (moment(row.date).diff(moment(loanTable[index - 1].date), 'days'))).principal", (periodicPaymentTmp / (moment(row.date).diff(moment(loanTable[index - 1].date), 'days'))))

            loanRowTmp.payment = '-';

            loanRowTmp.outstanding = loanTable[index - 1].outstanding + loanRowTmp.interest;
            // console.log("loanRowTmp.outstanding", loanRowTmp.outstanding)

            // console.log("loanRowTmp", loanRowTmp)

            if (newLoanTableWithAnniv.length > 0) {
              newLoanTableWithAnniv = [...newLoanTableWithAnniv, loanRowTmp];
            } else {
              newLoanTableWithAnniv = [loanRowTmp];
            }

            let loanRowTmp2 = {
              date: '',
              payment: '-',
              principal: '-',
              principalColumn: '-',
              interest: '-',
              outstanding: '-',
              paymentNumber: row.paymentNumber + .2
            };

            loanRowTmp2.date = moment(row.date).format('MM/DD/YYYY');

            console.log("loanTable.length", loanTable.length)
            console.log("index + 1", index + 1)
            var nextPrincipalColumn = 0
            if (loanTable.length != (index + 1)) {
              nextPrincipalColumn = loanTable[index + 1].principalColumn
            }

            loanRowTmp2.principalColumn = loanRowTmp.principalColumn;
            loanRowTmp2.interest = ((nextPrincipalColumn + periodicPaymentTmp - loanTable[index - 1].outstanding) / moment(row.date).diff(moment(loanTable[index - 1].date), 'days')) * moment(row.date).diff(moment(loanRowTmp.date), 'days')
            // console.log("loanRowTmp2.date 1", loanRowTmp2.date)
            // console.log("loanRowTmp2.date 2", loanTable[index - 1].date)
            // console.log("loanRowTmp2.interest 1", (((loanAmountTmp * (moment(loanRowTmp2.date).diff(moment(loanTable[index - 1].date), 'days'))) * interestRate) / 365))
            // console.log("loanRowTmp2.interest 2", loanRowTmp2.interest)
            loanRowTmp2.principal = finance.PPMT(periodicInterestRate, (index - monthsBeforeFirstPaymentDate) + 1, totalPaymentPeriods, outstandingBalanceAsOfFPD * -1);
            // console.log("loanRowTmp2 index", periodicInterestRate)
            // console.log("loanRowTmp2 index", index)
            // console.log("loanRowTmp2 monthsBeforeFirstPaymentDate", monthsBeforeFirstPaymentDate)
            // console.log("loanRowTmp2 totalPaymentPeriods", totalPaymentPeriods)
            // console.log("loanRowTmp2 outstandingBalanceAsOfFPD", outstandingBalanceAsOfFPD)
            // console.log("loanRowTmp2 finance.PPMT(periodicInterestRate, (index - monthsBeforeFirstPaymentDate) + 1, totalPaymentPeriods, outstandingBalanceAsOfFPD * -1)", finance.PPMT(periodicInterestRate, (index - monthsBeforeFirstPaymentDate) + 1, totalPaymentPeriods, outstandingBalanceAsOfFPD * -1))
            // loanRowTmp2.principal = ((periodicPaymentTmp / (moment(loanRowTmp2.date).diff(moment(loanTable[index - 1].date), 'days'))) - loanRowTmp2.interest) + loanRowTmp.principal;
            // console.log("loanTable[index - 1]", loanTable[index - 1])

            loanRowTmp2.payment = periodicPaymentTmp;

            // if last iteration
            if ((index - monthsBeforeFirstPaymentDate) == Math.ceil(totalNumberOfMonthsToPay / numberOfMonthsInModeOfPayment) - 1) {
              loanRowTmp2.outstanding = Math.abs(Math.round((loanTable[index + 1].outstanding - loanRowTmp2.principal) * 100) / 100);
            } else {
              loanRowTmp2.outstanding = nextPrincipalColumn;
            }

            // console.log("loanRowTmp2", loanRowTmp2)

            if (newLoanTableWithAnniv.length > 0) {
              newLoanTableWithAnniv = [...newLoanTableWithAnniv, loanRowTmp2];
            } else {
              newLoanTableWithAnniv = [loanRowTmp2];
            }

          }

          annivDateCounter++;
        }

        console.log("newLoanTableWithAnniv", newLoanTableWithAnniv)
      })

      setOutstandingBalanceAsOfFPD(outstandingBalanceAsOfFPD)
      setPrincipalBalanceAsOfFPD(principalBalanceAsOfFPD)
      setPeriodicPayment(periodicPaymentTmp)
      setTotalNumberOfMonthsToPay(totalNumberOfMonthsToPay)

      setLoanTableData(newLoanTableWithAnniv)
      // setLoanTableData(loanTable)

      setHasIllustrated(true);

      console.log("newLoanTableWithAnniv", newLoanTableWithAnniv);
    } catch (error) {
      console.log("computeLoanAmortization error", error)
    }
  };



  function formatTableData(value, type) {
    switch (type) {
      case 'empty':
        if (value == '' || value == null || value == '-') {
          return '--'
        } else {
          return value
        }
      case 'decimal':
        if (value == '' || value == null || value == '-') {
          return '--'
        } else {
          return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
      case 'decimal-round':
        if (value == '' || value == null || value == '-') {
          return '--'
        } else {
          return (Math.round((value) * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
      case 'decimal-round-allow-zero':
        return (Math.round((value) * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      case 'date':
        if (value == '' || value == null || value == '-') {
          return '--'
        } else {
          return moment(value, "MM/DD/YYYY hh:mm:ss a").format('MMMM D, YYYY')
        }

      default:
        if (value == '' || value == null || value == '-') {
          return '--'
        } else {
          return value
        }
    }

  }


  //PRINTING 
  const HEIGHT = 842.4 * 0.749
  const WIDTH = 597.6 * 0.749
  const pdf = new jsPDF('p', 'pt', [597.6, 842.4]);

  const COCO_HEADER = () => (
    <div>
      <div style={{ display: "flex", flex: 1, flexDirection: "row", maxHeight: "10vh", color: 'black' }}>
        <div style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
          <img alt={"coco_logo"} src={coco_logo} style={{ width: "100%", alignSelf: "center" }} />
        </div>
        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "flex-end", paddingLeft: "15%" }}>
          <div style={{ display: "flex", flex: 1, maxHeight: "5vh", backgroundColor: "rgba(24,99,168,1)", alignItems: "center" }}>
            <div style={{ fontSize: "1.1em", fontWeight: "bold", color: "white" }}>
              POLICYHOLDER
            </div>
          </div>
          <div style={{ display: "flex", flex: 1, maxHeight: "2vh", backgroundColor: "rgba(32,176,75,1)", alignItems: "center" }}>
            <div style={{ fontSize: "0.8em", fontWeight: "bold", marginLeft: "", color: "white" }}>
              LOAN PAYMENT AMORTIZATION
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const POLICY_STYLE = { fontSize: "0.6em", letterSpacing: "0.1em", textAlign: "left", marginTop: "1vh" }
  const HEAD_BIG_TEXT_CONTAINER = { display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(24,99,168,1)" }
  const HEADER_BIG_TEXT = { fontSize: "0.9em", letterSpacing: "0.1em", color: "white", fontWeight: "bold" }
  const COCO_POLICY_INFO = () => (
    <div style={{ display: "flex", flex: 1, flexDirection: "row", marginTop: "3vh", color: 'black' }}>
      <div style={{ display: "flex", flex: 7, flexDirection: "column" }}>
        <div style={{ display: "flex", flex: 1, flexDirection: "row", paddingLeft: `${WIDTH * 0.025}pt` }}>

          <div style={{ display: "flex", flexDirection: "column", width: '40%' }}>
            <div style={POLICY_STYLE}>
              {
                `Policy Number: ${POLICY_DATA}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `Next Anniversary Date: ${moment(loanCalcParams?.NextAnnivDate).format('MM/DD/YYYY')}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `Loan Balance: ${(Math.round((parseFloat(loanAmount) + parseFloat(loanCalcParams.LoanBalance)) * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `As of: ${moment().format("MM/DD/YYYY")}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `First Payment Date: ${moment(firstPaymentDate).format("MM/DD/YYYY")}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `ACCRD INT: ${(Math.round((outstandingBalanceAsOfFPD - (parseFloat(loanAmount) + parseFloat(loanCalcParams.LoanBalance))) * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
              }
              <br />
              (from As of date to FPD)
            </div>
            <div style={POLICY_STYLE}>
              {
                `PRINCIPAL BAL as of FPD: ${(Math.round((principalBalanceAsOfFPD) * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `O/S BAL as of FPD: ${(Math.round((outstandingBalanceAsOfFPD) * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `Interest Rate: ${(loanCalcParams?.InterestRate * 100) + "% "}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `Mode of Loan Repayment: ${modeOfPaymentName}`
              }
            </div>
            {/* <div style={POLICY_STYLE}>
              {
                `Number of Years to Repay: ${formatTableData(totalNumberOfMonthsToPay / 12, 'decimal-round')}`
              }
            </div> */}
            <div style={POLICY_STYLE}>
              {
                `Number of Months to Repay: ${totalNumberOfMonthsToPay}`
              }
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", paddingLeft: "10%", width: '60%' }}>
            <div style={{
              ...POLICY_STYLE, border: '1px solid black',
              paddingTop: '3px', paddingBottom: '3px', paddingLeft: '5px', paddingRight: '5px'
            }}>
              <strong>Disclaimer:</strong><br />
              This illustration is only an estimate. Actual values will be based on the actual
              date of your loan payment(s) and the actual interest rate(s). <br />
              <br />
              We will deduct all loans (including Premium Loans) and their interest from
              any money payable by us under this Policy. If at any time the total policy loan
              becomes more than the Sum of the Cash Value shown in the Table of Non-forfeiture
              Values and the Cash value of the Paid-up Additions, and the amount of Dividend
              Accumulations, if applicable, your Policy will automatically terminate without
              any value on that date.  <br />
            </div>
          </div>

        </div>
      </div>
    </div>
  )

  const COCO_PREMIUM_DETAILS = () => (
    <div style={{ display: "flex", flex: 1, backgroundColor: "rgba(207,236,240,0.0)", width: `${WIDTH}pt`, height: `${HEIGHT}pt` }}>
      <div style={{ paddingTop: `${HEIGHT * 0.0125}pt`, paddingBottom: `${HEIGHT * 0.0125}pt`, paddingRight: `${WIDTH * 0.025}pt`, paddingLeft: `${WIDTH * 0.025}pt` }}>
        {COCO_HEADER()}
        {COCO_POLICY_INFO()}
        {/* <div style={{ backgroundColor: "rgba(207,236,240,0.9)", fontSize: "0.5em", letterSpacing: "0.1em", textAlign: "left", marginTop: "7vh" }}>
          {`${new Date()}`}
        </div> */}
      </div>
    </div>
  )

  const PRINT_LOAN_CALC_REPORT = () => {
    // const string = renderToString(<COCO_BILLING_DETAILS />);
    // pdf.html(string).then(() => {
    //   pdf.autoPrint({ variant: 'non-conform' });
    //   pdf.save(`Billing Notice - ${POLICY_DATA}`)
    //   window.open(pdf.output('bloburl'), '_blank')
    // })'
    let filtredLoanTableData = loanTableData

    filtredLoanTableData.map((row, index) => {
      row.principalColumn = formatTableData(row.principalColumn, 'decimal-round')
      row.interest = formatTableData(row.interest, 'decimal-round')
      row.payment = formatTableData(row.payment, 'decimal-round')
      row.outstanding = formatTableData(row.outstanding, 'decimal-round-allow-zero')
    })

    const string = renderToString(<COCO_PREMIUM_DETAILS />);
    pdf.html(string).then(() => {
      pdf.autoTable({
        head: [{
          date: "Date",
          principalColumn: "Principal",
          interest: "Interest",
          payment: "Payment",
          outstanding: "Outstanding"
        }],
        body: loanTableData,
        startY: 380,
        showHead: 'firstPage',
      })

      //added for pooters
      const pageCount = pdf.internal.getNumberOfPages();
      pdf.setFont('helvetica', 'italic')
      pdf.setFontSize(8)
      for (var i = 1; i <= pageCount; i++) {
        pdf.setPage(i)
        pdf.text(`Date/Time: ${new Date()}`, 37, pdf.internal.pageSize.height - 18);
        pdf.text(`Page: ${String(i)}`, pdf.internal.pageSize.width - 74, pdf.internal.pageSize.height - 18);

      }

      // pdf.setFont('times', 'normal')
      // pdf.setFontSize(12)
      // let posY = pdf.lastAutoTable.finalY;
      // pdf.text(`Total Records: ${filteredPremiumPayments.length}`, 40, posY + 20);
      // pdf.text(`Total Payments: ${computeTotalPayments()}`, pdf.internal.pageSize.width / 2, posY + 20);
      //END OF for footers
    })
      .then(() => {
        pdf.autoPrint({ variant: 'non-conform' });
        pdf.save(`Loan Amortization - ${POLICY_DATA}`)
        window.open(pdf.output('bloburl'), '_blank')
        // setModalSetPrintPaymentDate(false)
      })
  };



  return (
    <div>
      <Box
        sx={{ minHheight: "100vh", backgroundColor: "background.default", pt: 5 }}
      >
        <Container maxWidth="md" sx={{ px: 5, py: 5 }}>
          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={6}> */}
          <img src={cocolife} style={{ width: "200px" }} />
          <Typography variant="h5" color="text.primary" sx={{ mt: 3, mb: 2 }}>
            Loan Amortization Calculator
          </Typography>

          <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />

          <APILoadingOverlay customlabel={loadingCustomLabel}
            open={isLoading} setOnParent={(value) => setIsLoading(value)}>

            {parseFloat(loanCalcParams.LoanBalance) > 0 ?
              <>
                <Typography variant="body1" color="text.primary">
                  User has an outstanding loan balance
                </Typography>
              </>
              :
              <>
                {loanCalcParams.LoanableAmount > 0 ?
                  <Typography variant="body1" color="text.primary">
                    Please input your loan details
                  </Typography>
                  :
                  <Typography variant="body1" color="text.primary">
                    User has reached max loanable amount
                  </Typography>
                }
              </>
            }

            <Stack sx={{ my: 1, minWidth: 120 }}>
              <Grid container spacing={3}>

                <Grid item xs={12} s={6} md={6}>
                  <Stack>
                    {/* {parseFloat(parseFloat(loanCalcParams.LoanBalance)) > 0 ?
                      <>
                        <Typography variant="overline" color="text.primary">
                          Outstanding Loan Balance
                        </Typography>

                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          value={parseFloat(parseFloat(loanCalcParams.LoanBalance))}
                          type="number"
                          disabled
                        />
                      </>
                      :
                      <>
                        {parseFloat(loanCalcParams.LoanableAmount) > 0 ?
                          <>
                            <Typography variant="overline" color="text.primary">
                              Loan Amount
                            </Typography>

                            < TextField
                              id="outlined-basic"
                              size="small"
                              variant="outlined"
                              value={loanAmount}
                              type="number"
                              onChange={event => {
                                if (event.target.value < 0) {
                                  setLoanAmount(0)
                                } else if (event.target.value > loanCalcParams.LoanableAmount) {
                                  setLoanAmount(loanCalcParams.LoanableAmount)
                                } else {
                                  setLoanAmount(event.target.value)
                                }
                              }}
                            />
                          </>
                          :
                          <>
                            <Typography variant="overline" color="text.primary">
                              Loan Amount
                            </Typography>

                            <TextField
                              id="outlined-basic"
                              size="small"
                              variant="outlined"
                              value={0}
                              type="number"
                              disabled
                            />
                          </>
                        }
                      </>
                    } */}
                    <Typography variant="overline" color="text.primary">
                      Applied Loan Amount
                    </Typography>

                    < TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      value={loanAmount}
                      type="number"
                      onChange={event => {
                        if (event.target.value < 0) {
                          setLoanAmount(0)
                        } else if (event.target.value > loanCalcParams.LoanableAmount) {
                          setLoanAmount(loanCalcParams.LoanableAmount)
                        } else {
                          setLoanAmount(event.target.value)
                        }
                      }}
                    />

                  </Stack>
                </Grid>
                <Grid item xs={12} s={6} md={6}>
                  <Stack>

                    <Typography variant="overline" color="text.primary">
                      Outstanding Loan as of {moment().format('MM/DD/YYYY')}
                    </Typography>

                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      value={parseFloat(loanCalcParams.LoanBalance)}
                      type="number"
                      disabled
                    />

                  </Stack>
                </Grid>

                {/* <Grid item xs={12} s={6} md={4}>
                  <Stack>
                    <Typography variant="overline" color="text.primary">
                      Number of Years to Pay
                    </Typography>

                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      value={numberOfYearsToPay}
                      type="number"
                      onChange={event => {
                        if (event.target.value < 0) {
                          setNumberOfYearsToPay(0)
                        } else if (event.target.value > 60) {
                          setNumberOfYearsToPay(60)
                        } else {
                          setNumberOfYearsToPay(event.target.value)
                        }
                      }}
                    />
                  </Stack>
                </Grid> */}

                <Grid item xs={12} s={6} md={6}>
                  <Stack>
                    <Typography variant="overline" color="text.primary">
                      Number of Months to Pay
                    </Typography>

                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      value={numberOfMonthsToPay}
                      type="number"
                      onChange={event => {
                        let maxMonths = 12 * 60;

                        if (event.target.value < 1) {
                          setNumberOfMonthsToPay(1)
                        } else if (event.target.value > maxMonths) {
                          setNumberOfMonthsToPay(maxMonths)
                        } else {
                          setNumberOfMonthsToPay(event.target.value)
                        }
                      }}
                    />

                    {/* <Select
                      labelId="number-of-months-label"
                      id="number-of-months-select"
                      value={numberOfMonthsToPay}
                      onChange={(event) => {
                        setNumberOfMonthsToPay(event.target.value)
                      }}
                      size="small"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="0">0</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7</MenuItem>
                      <MenuItem value="8">8</MenuItem>
                      <MenuItem value="9">9</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="11">11</MenuItem>
                      <MenuItem value="12">12</MenuItem>
                    </Select> */}
                  </Stack>
                </Grid>

                <Grid item xs={12} s={6} md={6}>
                  <Stack>
                    <Typography variant="overline" color="text.primary">
                      First Payment Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        inputFormat="MM/dd/yyyy"
                        value={firstPaymentDate}
                        onChange={(value) => {
                          setFirstPaymentDate(moment(value))
                        }}
                        maxDate={new Date(moment().add(loanCalcParams.MaxFirstPaymentYear, 'years').format("MM/DD/YYYY"))}
                        minDate={new Date(moment().format("MM/DD/YYYY"))}
                        renderInput={(params) => <TextField size="small"{...params} />}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>

                <Grid item xs={12} s={6} md={6}>
                  <Stack>
                    <Typography variant="overline" color="text.primary">
                      Mode of Payment
                    </Typography>

                    <Select
                      labelId="mode-of-payment-label"
                      id="mode-of-payment-select"
                      value={modeOfPayment.toString()}
                      onChange={(event) => {
                        setModeOfPayment(parseFloat(event.target.value));
                        if (event.target.value == 12) {
                          setModeOfPaymentName('Monthly')
                        } else if (event.target.value == 3) {
                          setModeOfPaymentName('Quarterly')
                        } else if (event.target.value == 2) {
                          setModeOfPaymentName('Semi-annual')
                        } else if (event.target.value == 1) {
                          setModeOfPaymentName('Annual')
                        }
                      }}
                      size="small"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {/* <MenuItem value="1">Annual (Once per year)</MenuItem>
                      <MenuItem value="2">Semi-annual (twice per year)</MenuItem>
                      <MenuItem value="3">Quarterly (4 times per year)</MenuItem> */}
                      <MenuItem value="12">Monthly (12 times per year)</MenuItem>
                    </Select>
                  </Stack>
                </Grid>

              </Grid>
            </Stack>



            <Paper variant="outlined" sx={{ py: 3, px: 4, mt: 3 }}>
              <Typography variant="h6" color="text.primary">
                Policy Details
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Policy Number{" "}
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    {POLICY_DATA + " "}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Max Loanable Amount{" "}
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    {loanCalcParams?.MaxLoanableAmount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " "}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Currency
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    {loanCalcParams?.Currency + " "}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Interest Rate
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    {(loanCalcParams?.InterestRate * 100) + "% "}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Next Policy Anniversary
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    {moment(loanCalcParams?.NextAnnivDate).format('MMMM DD, YYYY') + " "}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Policy Loan Date
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    {moment().format('MMMM DD, YYYY')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Policy Owner Name
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    {loanCalcParams?.OwnerName + " "}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Insured Name
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    {loanCalcParams?.InsuredName + " "}
                  </Typography>
                </Grid>

                <Divider />
              </Grid>
            </Paper>

            <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
              {/* {loanCalcParams.LoanableAmount ?
                <Button
                  variant="contained"
                  size="large"
                  sx={{ mt: 3 }}
                  onClick={computeLoanAmortization}
                // href="/mypolicy"
                >
                  Illustrate Loan Amortization
                </Button>
                :
                <>
                  {loanCalcParams.MaxLoanableAmount <= 0 ?
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ mt: 3 }}
                      disabled
                    // href="/mypolicy"
                    >
                      Max Loan Reached
                    </Button>
                    :
                    <>
                      {loanAmount > 0 ?
                        <Button
                          variant="contained"
                          size="large"
                          sx={{ mt: 3 }}
                          onClick={computeLoanAmortization}
                        // href="/mypolicy"
                        >
                          Illustrate Loan Amortization
                        </Button>
                        :
                        <Button
                          variant="contained"
                          size="large"
                          sx={{ mt: 3 }}
                          disabled
                        // href="/mypolicy"
                        >
                          Illustrate Loan Amortization
                        </Button>
                      }
                    </>
                  }
                </>
              } */}

              {loanAmount > 0 ?
                <Button
                  variant="contained"
                  size="large"
                  sx={{ mt: 3 }}
                  onClick={computeLoanAmortization}
                // href="/mypolicy"
                >
                  Illustrate Loan Amortization
                </Button>
                :
                <Button
                  variant="contained"
                  size="large"
                  sx={{ mt: 3 }}
                  disabled
                // href="/mypolicy"
                >
                  Illustrate Loan Amortization
                </Button>
              }
            </Box>

            {/* Loan Repayment Amortization
          Section */}

            {hasIllustrated ?
              <>
                <Paper variant="outlined" sx={{ py: 3, px: 4, mt: 5 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ mb: 3 }}
                  >
                    <Typography variant="h6" color="text.primary">
                      Loan Repayment Amortization
                    </Typography>
                    <Button startIcon={<PrintIcon />}
                      onClick={(() => {
                        PRINT_LOAN_CALC_REPORT()
                      })}>
                      Print
                    </Button>
                  </Stack>
                  <Grid container spacing={{ xs: 0, sm: 5 }}>
                    <Grid item xs={12} sm={6}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body">Policy Number</Typography>
                        <Typography variant="body">
                          <strong>{POLICY_DATA}</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body">Next Anniversary Date</Typography>
                        <Typography variant="body">
                          <strong>{moment(loanCalcParams?.NextAnnivDate).format('MM/DD/YYYY')}</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body">Loan Balance</Typography>
                        <Typography variant="body">
                          <strong>{(Math.round((parseFloat(displayLoanAmount) + parseFloat(loanCalcParams.LoanBalance)) * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body">As of:</Typography>
                        <Typography variant="body">
                          <strong>{moment().format("MM/DD/YYYY")}</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body">First Payment Date:</Typography>
                        <Typography variant="body">
                          <strong>{moment(firstPaymentDate).format("MM/DD/YYYY")}</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body">
                          ACCRD INT:
                          <br />
                          <Typography variant="caption">
                            (from as of date to FPD)
                          </Typography>
                        </Typography>
                        <Typography variant="body">
                          <strong>{(Math.round((outstandingBalanceAsOfFPD - (parseFloat(displayLoanAmount) + parseFloat(loanCalcParams.LoanBalance))) * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body">
                          PRINCIPAL BAL as of FPD:
                        </Typography>
                        <Typography variant="body">
                          <strong>{(Math.round((principalBalanceAsOfFPD) * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body">O/S BAL as of FPD:</Typography>
                        <Typography variant="body">
                          <strong>{(Math.round((outstandingBalanceAsOfFPD) * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body">Interest Rate</Typography>
                        <Typography variant="body">
                          <strong>{(loanCalcParams?.InterestRate * 100) + "% "}</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body">Mode of Loan Repayment</Typography>
                        <Typography variant="body">
                          <strong>Monthly</strong>
                        </Typography>
                      </Box>
                      {/* <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body">
                          Number of Years to Repay
                        </Typography>
                        <Typography variant="body">
                          <strong>{formatTableData(totalNumberOfMonthsToPay / 12, 'decimal-round')}</strong>
                        </Typography>
                      </Box> */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body">
                          Number of Months to Repay
                        </Typography>
                        <Typography variant="body">
                          <strong>{totalNumberOfMonthsToPay}</strong>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Typography variant="body">Payment</Typography>
                        <Typography variant="body">
                          <strong>{(Math.round((periodicPayment) * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <TableContainer sx={{ mt: 5 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow sx={{ fontWeight: "600" }}>
                          <TableCell align="left" sx={{ fontWeight: "600" }}>
                            Date
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "600" }}>
                            Principal
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "600" }}>
                            Interest{" "}
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "600" }}>
                            Payment
                          </TableCell>

                          <TableCell align="right" sx={{ fontWeight: "600" }}>
                            Outstanding
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loanTableData.length > 0 ?
                          <>
                            {
                              loanTableData.map((row, index) => (
                                <TableRow
                                  hover
                                  key={index}
                                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                  align="left"
                                >
                                  <TableCell align="left">{row.date}</TableCell>
                                  <TableCell align="right">{formatTableData(row.principalColumn, 'decimal-round')}</TableCell>
                                  <TableCell align="right">{formatTableData(row.interest, 'decimal-round')}</TableCell>
                                  <TableCell align="right">{formatTableData(row.payment, 'decimal-round')}</TableCell>
                                  <TableCell align="right">{formatTableData(row.outstanding, 'decimal-round-allow-zero')}</TableCell>
                                </TableRow>
                              ))
                            }
                          </>
                          : null}
                        <TableRow />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>

                <Paper variant="outlined" sx={{ py: 3, px: 4, mt: 5 }}>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="h6">
                      <strong>Disclaimer:</strong><br />
                      This illustration is only an estimate. Actual values will be based on the actual
                      date of your loan payment(s) and the actual interest rate(s). <br />
                      <br />
                      We will deduct all loans (including Premium Loans) and their interest from
                      any money payable by us under this Policy. If at any time the total policy loan
                      becomes more than the Sum of the Cash Value shown in the Table of Non-forfeiture
                      Values and the Cash value of the Paid-up Additions, and the amount of Dividend
                      Accumulations, if applicable, your Policy will automatically terminate without
                      any value on that date.  <br />
                      <br />
                    </Typography>
                    <Typography variant="caption">
                      For any inquiries, you may contact our Customer Care at{" "}
                      <a href="tel:8810-7888">8810-7888</a> or email at{" "}
                      <a href="mailto:customer_service@cocolife.com">
                        customer_service@cocolife.com
                      </a>
                    </Typography>
                  </Box>
                </Paper>
              </>
              : null}

          </APILoadingOverlay>
        </Container>
      </Box>
    </div >
  );
}
