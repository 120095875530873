import React from 'react'
import { SvgIcon } from '@mui/material'
import {ReactComponent as Logo} from './facebook-svg.svg'
export default function FacebookLogo() {
  return (
  <SvgIcon>
      <Logo/>
  </SvgIcon>
  )
}
