import 'dotenv/config';
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import { saveState } from "./localStorage";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

//Subscribe()
//Saves the state (from store.js) automatically(Using Subscribe()) when value is updated. 
//Stores data in local storage in browser using saveState() in localStorage.js

store.subscribe(() => {
  saveState(store.getState(), 'themeState');
  // console.log("saved state hehe");
});

const disableConsole = () => {
  if (process.env.REACT_APP_ENV != 'local' && process.env.REACT_APP_ENV != 'test-server') {
    console.log = function () { }
  }
}

ReactDOM.render(
  <React.StrictMode>
    {disableConsole()}
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
