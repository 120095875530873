import React, { useEffect, useState } from "react";
import {
  Box, Typography, Paper, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow
} from "@mui/material";
import moment from 'moment-timezone';
import MaterialUITable from "../components/MaterialUITable";
import { useSelector, useDispatch } from "react-redux";
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";
import APILoadingOverlay from "../components/APILoadingOverlay";
import APIErrorModal from "../components/APIErrorModal";
import axios from 'axios';

export default function Loans(props) {
  let dispatch = useDispatch();

  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  // const USERNAME = useSelector((state) => state.username.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)

  const [loans, setLoans] = useState({});
  const [pageNumber, setPageNumber] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    dispatch(updateLoadingState(isLoading));
  }, [isLoading])

  useEffect(() => {
    async function init() {

      let policyLoanReqData = {
        // policyNumber: 'T0095469',
        policyNumber: POLICY_DATA,
      }

      await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyLoan', policyLoanReqData)
        .then(async (res) => {
          console.log("getPolicyLoan res.data.Data", res.data.Data);

          if (res === 'null') {
            setapiName('getPolicyLoan')
            setapiResponse({
              Code: '408',
              Message: "API request timeout."
            })
            setAPIErrorModalOpen(true)
            console.log('issue', 'getPolicyLoan res is null')
            return
          }

          if (res.data.Success) {


            res.data.Data.PolicyLoan.map((item, index) => {
              if (moment(item.TransactionDate).isValid()) {
                item.TransactionDate = moment(item.TransactionDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
              }
              item.AccumulatedAmount = item.AccumulatedAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              item.Interest = item.Interest.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              item.TransactionAmount = item.TransactionAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              item.InterestRate = item.InterestRate.toString() + '%'
            })

            setLoans(res.data.Data.PolicyLoan);

          } else {
            setapiName('getPolicyLoan')
            setapiResponse(res.data)
            setAPIErrorModalOpen(true)
            console.log('issue', 'getPolicyLoan res', res.data.Response)
            return
          }

          setIsLoading(false)
        })
    }
    init()
  }, [])

  return (
    <Box>
      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />
      <Typography variant="h4" color="text.primary" sx={{ mb: 5 }}>
        Loans
      </Typography>

      <APILoadingOverlay customlabel={loadingCustomLabel}
        open={isLoading} setOnParent={(value) => setIsLoading(value)}>

        {/* <Typography variant="h6">Policy Loans</Typography>
      <Typography variant="body1" color="text.secondary">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </Typography> */}

        <Box sx={{ mt: 8 }}>
          {/* <Typography variant="h6" sx={{ mb: 2 }}>
          Policy Agent History / Transfer of Business
        </Typography> */}

          {loans.length > 0 ?
            <MaterialUITable
              data={loans}
              isLoading={false}
              totalCount={loans.length}
              currentPage={pageNumber}
              rowsPerPage={rowsPerPage}
              columns={[
                {
                  title: "Transaction Date",
                  field: "TransactionDate",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Transaction Amount",
                  field: "TransactionAmount",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid',
                    textAlign: 'right'
                  },
                },
                {
                  title: "Interest",
                  field: "Interest",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid',
                    textAlign: 'right'
                  },
                },
                {
                  title: "Transaction Type",
                  field: "TransactionType",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Interest Rate",
                  field: "InterestRate",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid',
                    textAlign: 'right'
                  },
                },
                {
                  title: "Accumulated Amount",
                  field: "AccumulatedAmount",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid',
                    textAlign: 'right'
                  },
                },
                {
                  title: "Transaction Number",
                  field: "TransactionNumber",
                },
              ]}
              changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
              changePage={(page, pNumber) => setPageNumber(page)}
            />
            :
            <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
              No Data Available
            </Typography>}
        </Box>
      </APILoadingOverlay>
    </Box>
  );
}
