import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box, Typography, Paper, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, Divider, Grid,
  Button, Modal, Fade, Backdrop, Checkbox,
  FormControlLabel
} from "@mui/material";
import moment from 'moment-timezone';
import MaterialUITable from "../components/MaterialUITable";
import { useSelector, useDispatch } from "react-redux";
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";
import APIErrorModal from "../components/APIErrorModal";
import APILoadingOverlay from "../components/APILoadingOverlay";
import axios from 'axios';

//for billing form printing
import coco_logo from "../assets/cocolife-horizontal.png";
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { renderToString } from "react-dom/server";

export default function History() {
  let dispatch = useDispatch();

  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  // const USERNAME = useSelector((state) => state.username.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)

  const [ACAHistory, setACAHistory] = useState({});
  const [postDatedCheckHistory, setPostDatedCheckHistory] = useState({});
  const [billings, setBillings] = useState({});
  const [billingsTableData, setBillingsTableData] = useState({});
  const [billingNotice, setBillingNotice] = useState({});
  const [pageNumber, setPageNumber] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [payPDF, setPayPDF] = useState(false);
  const [payFBR, setPayFBR] = useState(false);
  const [payLoanInterest, setPayLoanInterest] = useState(false);
  const [premiumAmount, setPremiumAmount] = useState(0);
  const [pdfAmount, setPDFAmount] = useState(0);
  const [fbrAmount, setFBRAmount] = useState(0);
  const [loanInterestAmount, setLoanInterestAmount] = useState(0);
  const [totalAmountDue, setTotalAmountDue] = useState(0);

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);

  const [modalBillingNotWithin31Days, setModalBillingNotWithin31Days] = useState(false);
  const [modalSelectPaymentSummary, setModalSelectPaymentSummary] = useState(false);

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [isLoading, setIsLoading] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 350,
    maxWidth: 600,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  useEffect(() => {
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    dispatch(updateLoadingState(isLoading));
  }, [isLoading])

  useEffect(() => {
    let tmpTotalAmountDue = premiumAmount;
    console.log("tmpTotalAmountDue", tmpTotalAmountDue);

    if (payPDF) {
      tmpTotalAmountDue += pdfAmount;
      console.log("tmpTotalAmountDue + payPDF", tmpTotalAmountDue);
    }
    if (payFBR) {
      tmpTotalAmountDue += fbrAmount;
      console.log("tmpTotalAmountDue + payFBR", tmpTotalAmountDue);
    }
    if (payLoanInterest) {
      tmpTotalAmountDue += loanInterestAmount;
      console.log("tmpTotalAmountDue + payLoanInterest", tmpTotalAmountDue);
    }

    setTotalAmountDue(tmpTotalAmountDue)
  }, [modalSelectPaymentSummary, payPDF, payFBR, payLoanInterest]);

  useEffect(() => {
    console.log("billingNotice was just updated", billingNotice)
  }, [billingNotice])

  useEffect(() => {
    async function init() {
      let acaHistoryReqData = {
        // policyNumber: 'T0095469',
        policyNumber: POLICY_DATA,
      }

      await axios.post(process.env.REACT_APP_API_URL + '/api/getACAHistory', acaHistoryReqData)
        .then(async (res) => {
          console.log("getACAHistory res.data.Data", res.data.Data);

          if (res === 'null') {
            setapiName('getACAHistory')
            setapiResponse({
              Code: '408',
              Message: "API request timeout."
            })
            setAPIErrorModalOpen(true)
            console.log('issue', 'getACAHistory res is null')
            return
          }

          if (res.data.Success) {
            res.data.Data.ACAHistory.map((item, index) => {
              item.index = index + 1;
              item.ChargedDate = moment(item.ChargedDate).isValid() ? moment(item.ChargedDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY') : null;
              item.DueDate = moment(item.DueDate).isValid() ? moment(item.DueDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY') : null;
              item.Amount = item.Amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
              item.AccountNumber = '#' + item.AccountNumber;
            })

            setACAHistory(res.data.Data.ACAHistory);

            let billingsReqData = {
              // policyNumber: 'T0095469',
              policyNumber: POLICY_DATA,
            }

            await axios.post(process.env.REACT_APP_API_URL + '/api/getBillings', billingsReqData)
              .then(async (res) => {
                console.log("getBillings res.data.Data", res.data.Data);

                if (res === 'null') {
                  setapiName('getBillings')
                  setapiResponse({
                    Code: '408',
                    Message: "API request timeout."
                  })
                  setAPIErrorModalOpen(true)
                  console.log('issue', 'getBillings res is null')
                  return
                }

                if (res.data.Success) {
                  let newBillingNoticeList = [];

                  await Promise.all(
                    res.data.Data.Billings.map(async (item, index) => {
                      if (moment(item.DateGenerated).isValid()) {
                        // item.DateGenerated = moment(item.DateGenerated, "MMMM DD, YYYY").format('MMMM DD, YYYY')
                        item.DateGenerated = moment(item.DateGenerated, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                      }
                      if (moment(item.DueDate).isValid()) {
                        item.DueDate = moment(item.DueDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                        // item.DueDate = moment('10-20-2021', "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                      }
                      if (moment(item.NextDueDate).isValid()) {
                        item.NextDueDate = moment(item.NextDueDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                      }
                      item.Premium = item.Premium.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                      item.index = index

                      let currentDateAfter31Days = moment(item.DueDate, 'MM-DD-YYYY').add(31, 'days')
                      let currentDateBefore31Days = moment(item.DueDate, 'MM-DD-YYYY').subtract(31, 'days')


                      if (moment(item.DateGenerated).isValid()) {
                        // item.DateGenerated = moment(item.DateGenerated, "MMMM DD, YYYY").format('MMMM DD, YYYY')
                        let newDateGenerated = moment(item.DateGenerated, "MM/DD/YYYY hh:mm:ss a").format('MM/DD/YYYY')

                        // if (moment(currentDateAfter31Days).isSameOrAfter(moment()) &&
                        //   moment(currentDateBefore31Days).isSameOrBefore(moment())) {
                        let billingNoticeDetailsReqData = {
                          // policyNumber: 'T0095469',
                          policyNumber: POLICY_DATA,
                          dateGenerated: newDateGenerated,
                        }

                        // console.log("billingNoticeDetailsReqData", index + 1, billingNoticeDetailsReqData, item.DateGenerated);

                        await axios.post(process.env.REACT_APP_API_URL + '/api/getBillingNoticeDetails',
                          billingNoticeDetailsReqData
                        ).then(async (res) => {
                          // console.log("getBillingNoticeDetails res", index + 1, res.data.Data);

                          if (res === 'null') {
                            setapiName('getBillingNoticeDetails')
                            setapiResponse({
                              Code: '408',
                              Message: "API request timeout."
                            })
                            setAPIErrorModalOpen(true)
                            console.log('issue', 'getBillingNoticeDetails res is null')
                            return
                          }

                          if (res.data.Success) {
                            if (moment(res.data.Data.IssueDate).isValid()) {
                              res.data.Data.IssueDate = moment(res.data.Data.IssueDate).format('MMMM DD, YYYY')
                            }

                            if (index == 0) {
                              setBillingNotice(res.data.Data)
                            }

                            item.BillingNotice = res.data.Data

                            if (newBillingNoticeList.length > 0) {
                              newBillingNoticeList = [...newBillingNoticeList, res.data.Data]
                            } else {
                              newBillingNoticeList = [res.data.Data]
                            }
                          } else {
                            setapiName('getBillingNoticeDetails')
                            setapiResponse(res.data)
                            setAPIErrorModalOpen(true)
                            console.log('issue', 'getBillingNoticeDetails res', res.data.Response)
                            return
                          }

                        })
                        // }
                      }

                    })
                  )

                  // console.log("newBillingNoticeList", newBillingNoticeList)

                  // setBillingNotice(res.data.Data)
                  setBillings(res.data.Data);
                  setBillingsTableData(res.data.Data.Billings);

                  let billingNoticeDetailsReqData = {
                    // policyNumber: 'T0095469',
                    policyNumber: POLICY_DATA,
                    dateGenerated: moment().format('MM/DD/YYYY'),
                  }

                  let postDatedCheckHistoryReq = {
                    // policyNumber: 'T0095469',
                    policyNumber: POLICY_DATA,
                  }

                  await axios.post(process.env.REACT_APP_API_URL + '/api/getPostDatedCheckHistory',
                    postDatedCheckHistoryReq
                  ).then(async (res) => {
                    console.log("getPostDatedCheckHistory res.data.Data", res.data.Data);

                    if (res === 'null') {
                      setapiName('getPostDatedCheckHistory')
                      setapiResponse({
                        Code: '408',
                        Message: "API request timeout."
                      })
                      setAPIErrorModalOpen(true)
                      console.log('issue', 'getPostDatedCheckHistory res is null')
                      return
                    }

                    if (res.data.Success) {
                      if (res.data.Data.PostDatedCheckHistory.length > 0) {
                        res.data.Data.PostDatedCheckHistory.map((item, index) => {
                          if (moment(item.ChargedDate).isValid()) {
                            item.ChargedDate = moment(item.ChargedDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                          }
                          if (moment(item.DueDate).isValid()) {
                            item.DueDate = moment(item.DueDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                          }
                          if (moment(item.ORDate).isValid()) {
                            item.ORDate = moment(item.ORDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                          }
                          item.CheckAmount = item.CheckAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

                          if (item.ReasonForRejection === '' || item.ReasonForRejection === null) {
                            item.ReasonForRejection = '--'
                          }
                          if (item.Remarks === '' || item.Remarks === null) {
                            item.Remarks = '--'
                          }
                        })

                        setPostDatedCheckHistory(res.data.Data.PostDatedCheckHistory);
                      } else {
                        await axios.post(process.env.REACT_APP_API_URL + '/api/getCheckPaymentsHistory', billingsReqData)
                          .then(async (res) => {
                            console.log("getCheckPaymentsHistory res.data.Data", res.data.Data);

                            if (res === 'null') {
                              setapiName('getCheckPaymentsHistory')
                              setapiResponse({
                                Code: '408',
                                Message: "API request timeout."
                              })
                              setAPIErrorModalOpen(true)
                              console.log('issue', 'getCheckPaymentsHistory res is null')
                              return
                            }

                            if (res.data.Success) {
                              res.data.Data.CheckPaymentsHistory.map((item, index) => {
                                if (moment(item.ChargedDate).isValid()) {
                                  item.ChargedDate = moment(item.ChargedDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                                }
                                if (moment(item.ORDate).isValid()) {
                                  item.ORDate = moment(item.ORDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                                }
                                if (moment(item.DueDate).isValid()) {
                                  item.DueDate = moment(item.DueDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                                }
                                item.CheckAmount = item.CheckAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

                                if (item.ReasonForRejection === '' || item.ReasonForRejection === null) {
                                  item.ReasonForRejection = '--'
                                }
                                if (item.Remarks === '' || item.Remarks === null) {
                                  item.Remarks = '--'
                                }
                              })

                              setPostDatedCheckHistory(res.data.Data.CheckPaymentsHistory);
                            } else {
                              setapiName('getCheckPaymentsHistory')
                              setapiResponse(res.data)
                              setAPIErrorModalOpen(true)
                              console.log('issue', 'getCheckPaymentsHistory res', res.data.Response)
                              return
                            }
                          })

                      }
                    } else {
                      setapiName('getPostDatedCheckHistory')
                      setapiResponse(res.data)
                      setAPIErrorModalOpen(true)
                      console.log('issue', 'getPostDatedCheckHistory res', res.data.Response)
                      return
                    }
                  })


                } else {
                  setapiName('getBillings')
                  setapiResponse(res.data)
                  setAPIErrorModalOpen(true)
                  console.log('issue', 'getBillings res', res.data.Response)
                  return
                }
              })

          } else {
            setapiName('getACAHistory')
            setapiResponse(res.data)
            setAPIErrorModalOpen(true)
            console.log('issue', 'getACAHistory res', res.data.Response)
            return
          }

          setIsLoading(false)
        })
    }
    init()
  }, [])



  //for billing form printing
  const HEIGHT = 842.4 * 0.749
  const WIDTH = 597.6 * 0.749
  const pdf = new jsPDF('p', 'pt', [597.6, 842.4]);

  // const textStyle = { fontSize: "0.5em", letterSpacing: "0.1em" }
  // const POLICY_STYLE = { fontSize: "0.5em", letterSpacing: "0.1em", textAlign: "left", marginTop: "1vh" }
  // const SUMMARY_STYLE = { fontSize: "0.5em", letterSpacing: "0.1em", textAlign: "right", marginTop: "1vh" }
  // const HEADER_BIG_TEXT = { fontSize: "1.1em", color: "white", fontWeight: "bold" }
  const textStyle = { fontSize: "0.5em", letterSpacing: "0.07em" }
  const POLICY_STYLE = { fontSize: "0.5em", letterSpacing: "0.07em", textAlign: "left", marginTop: "1vh" }
  const SUMMARY_STYLE = { fontSize: "0.5em", letterSpacing: "0.07em", textAlign: "right", marginTop: "1vh" }
  const HEADER_BIG_TEXT = { fontSize: "1.1em", color: "white", fontWeight: "bold" }

  const COCO_HEADER = (billingNoticeTmp) => {
    console.log("COCO_HEADER billingNoticeTmp", billingNoticeTmp)
    return (
      <div style={{ color: "black" }}>
        <div style={{ display: "flex", flex: 1, flexDirection: "row", maxHeight: "10vh" }}>
          <div style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
            <img alt={"coco_logo"} src={coco_logo} style={{ width: "100%", alignSelf: "center" }} />
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "flex-end", paddingLeft: "25%" }}>
            <div style={{ display: "flex", flex: 1, maxHeight: "5vh", backgroundColor: "rgba(24,99,168,1)", alignItems: "center" }}>
              <div style={{ fontSize: "1.1em", fontWeight: "bold", color: "white" }}>
                {`BILLING NOTICE`}
              </div>
            </div>
            <div style={{ display: "flex", flex: 1, maxHeight: "2vh", backgroundColor: "rgba(32,176,75,1)", alignItems: "center" }}>
              <div style={{ fontSize: "0.8em", fontWeight: "bold", marginLeft: "", color: "white" }}>
                {`POLICYHOLDER COPY`}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flex: 1, flexDirection: "row", maxHeight: "10vh", marginTop: "3vh" }}>
          <div style={{ display: "flex", flex: 1, flexDirection: "column", paddingLeft: `${WIDTH * 0.025}pt` }}>
            <div style={textStyle}>
              {`${billingNoticeTmp.PayorName}`}
            </div>
            <div style={textStyle}>
              {`${billingNoticeTmp.Address1}`}
            </div>
            <div style={textStyle}>
              {`${billingNoticeTmp.Address2}`}
            </div>
            <div style={textStyle}>
              {`${billingNoticeTmp.Address3}`}
            </div>
            <div style={textStyle}>
              {`${billingNoticeTmp.ZipCode}`}
            </div>
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "flex-end", paddingLeft: "25%" }}>
            <div style={textStyle}>
              {`COCOLIFE Building, `}
            </div>
            <div style={textStyle}>
              {`6807 Ayala Avenue, Makati City`}
            </div>
            <div style={textStyle}>
              {`Call Center: 8810-7888`}
            </div>
            <div style={textStyle}>
              {`Fax No: 8812-9039`}
            </div>
            <div style={textStyle}>
              {`Email: customer_service@cocolife.com`}
            </div>
            <div style={textStyle}>
              {`Website: www.cocolife.com`}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const COCO_POLICY_INFO = (billingNoticeTmp) => {
    console.log("COCO_POLICY_INFO billingNoticeTmp", billingNoticeTmp)
    return (
      <div style={{ display: "flex", flex: 1, flexDirection: "row", minHeight: "40vh", marginTop: "1vh", color: "black" }}>
        <div style={{ display: "flex", flex: 7, flexDirection: "column", textAlign: "center" }}>
          <div style={{ display: "flex", alignItems: "center", textAlign: "start", backgroundColor: "rgba(24,99,168,1)" }}>
            <div style={HEADER_BIG_TEXT}>
              {`YOUR POLICY INFORMATION`}
            </div>
          </div>
          {/* <div style={{ paddingLeft: `${WIDTH * 0.025}pt`, textAlign: "start" }}> */}
          <div style={{ display: "flex", flex: 1, flexDirection: "row", paddingLeft: `${WIDTH * 0.025}pt` }}>
            <div style={{ display: "flex", flex: 3, flexDirection: "column" }}>
              <div style={POLICY_STYLE}>
                {`Policy No:`}
              </div>
              <div style={POLICY_STYLE}>
                {`Plan: `}
              </div>
              <div style={POLICY_STYLE}>
                {`Face Amount: `}
              </div>
              <div style={POLICY_STYLE}>
                {`Effective Date: `}
              </div>
              <div style={POLICY_STYLE}>
                {`Due Date: `}
              </div>
              <div style={POLICY_STYLE}>
                {`Mode: `}
              </div>
              <div style={POLICY_STYLE}>
                {`Premium: `}
              </div>
            </div>
            <div style={{ display: "flex", flex: 6, flexDirection: "column" }}>
              <div style={POLICY_STYLE}>
                {`${POLICY_DATA}`}
              </div>
              <div style={POLICY_STYLE}>
                {`${billingNoticeTmp.Plan}`}
              </div>
              <div style={POLICY_STYLE}>
                <span style={{ letterSpacing: "0.05em", }}>
                  {`${billingNoticeTmp.PolicyCurrency} ${billingNoticeTmp.FaceAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                </span>
              </div>
              <div style={POLICY_STYLE}>
                {`${billingNoticeTmp.IssueDate}`}
              </div>
              <div style={POLICY_STYLE}>
                {`${moment(billingNoticeTmp.DueDate).format('MMMM DD, YYYY')}`}
              </div>
              <div style={POLICY_STYLE}>
                {`${billingNoticeTmp.PayMode}`}
              </div>
              <div style={POLICY_STYLE}>
                {`${billingNoticeTmp.PolicyCurrency} ${billingNoticeTmp.PremiumDue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", backgroundColor: "rgba(24,99,168,1)", marginTop: "3vh" }}>
            <div style={HEADER_BIG_TEXT}>
              YOUR ACCOUNT SUMMARY
            </div>
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "row", paddingLeft: `${WIDTH * 0.025}pt` }}>
            <div style={{ display: "flex", flex: 6, flexDirection: "column" }}>
              {
                billingNoticeTmp.OutstandingLoan != 0 ?
                  <div style={POLICY_STYLE}>
                    {`***TOTAL OUTSTANDING LOAN as of `}
                    {`${moment(billingNoticeTmp.LatestLoanTransDate).isValid ? moment(billingNoticeTmp.LatestLoanTransDate).format('MM/DD/YYYY') : ''}`}
                  </div>
                  :
                  null
              }
              <div style={POLICY_STYLE}>
                {
                  `Premium`
                }
              </div>
              {
                // payPDF ?
                billingNoticeTmp.PDF != 0 ?
                  <div style={POLICY_STYLE}>
                    {`PDF`}
                  </div>
                  :
                  null
              }
              {
                // payFBR ?
                billingNoticeTmp.FBR != 0 ?
                  <div style={POLICY_STYLE}>
                    {`FBR`}
                  </div>
                  :
                  null
              }
              {
                // payLoanInterest ?
                billingNoticeTmp.LoanInterest != 0 ?
                  <div style={POLICY_STYLE}>
                    {`Loan Interest`}
                  </div>
                  :
                  null
              }
              <div style={POLICY_STYLE}>
                {
                  `TOTAL AMOUNT DUE`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  `Statement Period as of ${moment(billingNoticeTmp.DueDate).format('MMMM DD, YYYY')}`
                }
              </div>
            </div>
            <div style={{ display: "flex", flex: 3, flexDirection: "column", paddingRight: "10%" }}>
              {
                billingNoticeTmp.OutstandingLoan != 0 ?
                  <div style={SUMMARY_STYLE}>
                    {`${billingNoticeTmp.PolicyCurrency} ${billingNoticeTmp.OutstandingLoan.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                  </div>
                  :
                  null
              }
              <div style={SUMMARY_STYLE}>
                {
                  `${billingNoticeTmp.PolicyCurrency} ${billingNoticeTmp.PremiumDue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                }
              </div>
              {
                // payPDF != 0 ?
                billingNoticeTmp.PDF != 0 ?
                  <div style={SUMMARY_STYLE}>
                    {`${billingNoticeTmp.PolicyCurrency} ${billingNoticeTmp.PDF.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                  </div>
                  :
                  null
              }
              {
                // payFBR != 0 ?
                billingNoticeTmp.FBR != 0 ?
                  <div style={SUMMARY_STYLE}>
                    {`${billingNoticeTmp.PolicyCurrency} ${billingNoticeTmp.FBR.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                  </div>
                  :
                  null
              }
              {
                // payLoanInterest != 0 ?
                billingNoticeTmp.LoanInterest != 0 ?
                  <div style={SUMMARY_STYLE}>
                    {`${billingNoticeTmp.PolicyCurrency} ${billingNoticeTmp.LoanInterest.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                  </div>
                  :
                  null
              }
              <div style={SUMMARY_STYLE}>
                {
                  // `${billingNoticeTmp.PolicyCurrency} ${totalAmountDue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                  `${billingNoticeTmp.PolicyCurrency} ${billingNoticeTmp.TotalAmountDue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                }
              </div>
              <div style={SUMMARY_STYLE}>
                {
                  ``
                }
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flex: 3, flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(32,176,75,1)" }}>
            <div style={HEADER_BIG_TEXT}>
              NEWS BRIEF
            </div>
          </div>

          <div style={{ borderStyle: "solid", borderWidth: 1, borderColor: "white", height: "100%" }}>

          </div>
        </div>
      </div >
    )
  }

  const COCO_FOOTER = (billingNoticeTmp) => {
    console.log("COCO_FOOTER billingNoticeTmp", billingNoticeTmp)
    return (
      <div style={{ color: "black" }}>
        <div style={{ display: "flex", flex: 1, flexDirection: "row", maxHeight: "10vh", marginTop: "3vh", color: "black" }}>
          <div style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
            <img alt={"coco_logo"} src={coco_logo} style={{ width: "100%", alignSelf: "center" }} />
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "flex-end", paddingLeft: "25%" }}>
            <div style={{ display: "flex", flex: 1, maxHeight: "2vh", alignItems: "center" }}>

              <div style={{ fontSize: "0.8em", fontWeight: "bold", marginLeft: "", color: "rgba(24,99,168,1)" }}>
                COCOLIFE COPY
              </div>
            </div>
            <div style={{ display: "flex", flex: 1, maxHeight: "5vh", backgroundColor: "rgba(32,176,75,1)", alignItems: "center" }}>
              <div style={HEADER_BIG_TEXT}>
                PAYMENT DETAILS
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flex: 12, flexDirection: "row", maxHeight: "10vh", marginTop: "3vh", paddingLeft: "2%" }}>
          <div style={{ display: "flex", flex: 2, flexDirection: "column" }}>
            <div style={{ ...textStyle, marginTop: "0.5vh" }}>
              {
                `Payment Due Date: `
              }
            </div>
            <div style={{ ...textStyle, marginTop: "0.5vh" }}>
              {
                `Amount Due: `
              }
            </div>
          </div>
          <div style={{ display: "flex", flex: 2, flexDirection: "column" }}>
            <div style={{ ...textStyle, marginTop: "0.5vh" }}>
              {
                `${moment(billingNoticeTmp.DueDate).format('MMMM DD, YYYY')}`
              }
            </div>
            <div style={{ ...textStyle, marginTop: "0.5vh" }}>
              {
                `${billingNoticeTmp.PolicyCurrency} ${billingNoticeTmp.TotalAmountDue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
              }
            </div>
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>

          </div>
          <div style={{ display: "flex", flex: 3, flexDirection: "column" }}>
            <div style={{ ...textStyle, marginTop: "0.5vh" }}>
              {
                `POLICY NUMBER: `
              }
            </div>
            <div style={{ ...textStyle, marginTop: "0.5vh" }}>
              {
                `POLICY HOLDER'S NAME:`
              }
            </div>
            <div style={{ ...textStyle, marginTop: "0.5vh" }}>
              {
                `AMOUNT PAID: `
              }
            </div>
            {/* <div style={{...textStyle, marginTop: "0.5vh"}}>
              {
                `AMOUNT PAID: ${billingNoticeTmp.TotalAmountDue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
              }
            </div> */}
          </div>
          <div style={{ display: "flex", flex: 4, flexDirection: "column" }}>
            <div style={{ ...textStyle, marginTop: "0.5vh" }}>
              {
                `${POLICY_DATA}`
              }
            </div>
            <div style={{ ...textStyle, marginTop: "0.5vh" }}>
              {
                `${billingNoticeTmp.PayorName}`
              }
            </div>
            <div style={{ ...textStyle, marginTop: "0.5vh" }}>
              {
                `DATE: `
              }
            </div>
            {/* <div style={{...textStyle, marginTop: "0.5vh"}}>
              {
                `AMOUNT PAID: ${billingNoticeTmp.TotalAmountDue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
              }
            </div> */}
          </div>
        </div>
      </div>
    )
  }

  const COCO_BILLING_DETAILS = (props) => {
    console.log("COCO_BILLING_DETAILS props", props)
    let billingNoticeTmp = props.billingnotice

    let nextDueDate = '';
    if (billingNoticeTmp.NextDueDate != null && billingNoticeTmp.NextDueDate != '') {
      nextDueDate = moment(billingNoticeTmp.NextDueDate).format('MMMM DD, YYYY')
    }

    console.log("COCO_BILLING_DETAILS billingNoticeTmp", billingNoticeTmp)
    return (
      <div style={{
        display: "flex", flex: 1, backgroundColor: "rgba(207,236,240,0.9)", color: "black",
        width: `${WIDTH}pt`, height: `${HEIGHT}pt`
      }}>
        <div style={{ paddingTop: `${HEIGHT * 0.0125}pt`, paddingBottom: `${HEIGHT * 0.0125}pt`, paddingRight: `${WIDTH * 0.025}pt`, paddingLeft: `${WIDTH * 0.025}pt` }}>
          {COCO_HEADER(billingNoticeTmp)}
          <div style={{ fontSize: "0.5em", letterSpacing: "0.07em", textAlign: "center", marginTop: "2vh" }}>
            Please make check payable to Cocolife.
          </div>
          {COCO_POLICY_INFO(billingNoticeTmp)}
          <div style={{ fontSize: "0.5em", letterSpacing: "0.07em", textAlign: "left", marginTop: "2vh", paddingLeft: `${WIDTH * 0.025}pt`, paddingRight: `${WIDTH * 0.025}pt` }}>
            Please disregard this notice if the amount payable shown has already been paid. Your next premium due date is on {nextDueDate}.
          </div>
          {
            billingNoticeTmp.OutstandingLoan > 0 ?
              <div style={{ fontSize: "0.5em", letterSpacing: "0.07em", textAlign: "left", marginTop: "2vh", paddingLeft: `${WIDTH * 0.025}pt`, paddingRight: `${WIDTH * 0.025}pt` }}>
                ***Outstanding policy loan as of date of last transaction. To restore the full benefits of your policy, you may repay your loan anytime in full or in part. You may call our Customer Care Center at 88107888 for loan repayment computation.
              </div>
              :
              null
          }
          {COCO_FOOTER(billingNoticeTmp)}
          {/* <div style={{ backgroundColor: "rgba(207,236,240,0.9)", fontSize: "0.5em", letterSpacing: "0.07em", textAlign: "left", marginTop: "7vh" }}>
            Please refer to the back of the statement for payment centers & other important informations.
          </div> */}
        </div>
      </div>
    )
  }

  const PRINT_BILLING_NOTICE = (billingNoticeTmp) => {
    console.log("billingNoticeTmp.DueDate", billingNoticeTmp.DueDate)

    // COCO_BILLING_DETAILS(billingNoticeTmp)
    const string = renderToString(<COCO_BILLING_DETAILS billingnotice={billingNoticeTmp} />);

    pdf.html(string).then(() => {
      // pdf.autoPrint({ variant: 'non-conform' });
      // pdf.save(`Billing Notice - ${POLICY_DATA}`)
      window.open(pdf.output('bloburl'), '_blank')
    })
  };

  const handlePrintBillingNoticeBtn = (rowData) => {
    console.log("rowData.BillingNotice", rowData.BillingNotice)

    let debug = true;

    if (process.env.REACT_APP_ENV === 'local' && debug) {
      rowData.BillingNotice.OutstandingLoan = 10067
      rowData.BillingNotice.PremiumDue = 10067
      rowData.BillingNotice.PDF = 2334
      rowData.BillingNotice.FBR = 897
      rowData.BillingNotice.LoanInterest = 170
    }

    setPremiumAmount(rowData.BillingNotice.PremiumDue)
    setPDFAmount(rowData.BillingNotice.PDF)
    setFBRAmount(rowData.BillingNotice.FBR)
    setLoanInterestAmount(rowData.BillingNotice.LoanInterest)

    setBillingNotice(rowData.BillingNotice)

    // setModalSelectPaymentSummary(true);

    setBillingNotice(rowData.BillingNotice)
    PRINT_BILLING_NOTICE(rowData.BillingNotice);
  }

  return (
    <Box>
      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalBillingNotWithin31Days}
        onClose={() => {
          setModalBillingNotWithin31Days(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalBillingNotWithin31Days}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              You cannot print the Billing notice before 31 days of the due date or after 31 days of the due date.
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalBillingNotWithin31Days(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalSelectPaymentSummary}
        onClose={() => {
          setModalSelectPaymentSummary(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalSelectPaymentSummary}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Payment Summary
            </Typography>

            <TableContainer>
              <Table sx={{}} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ fontWeight: "600" }}>
                    <TableCell align="left" sx={{ fontWeight: "600" }}>
                      Item
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "600" }}>
                      Amount
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody sx={{ paddingTop: 2 }}>
                  <TableRow
                    hover
                    sx={{ border: 0, padding: '0px' }}
                  >
                    <TableCell align="left" component="th" scope="row" sx={{ border: 0, padding: '0px', paddingTop: 1 }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={true}
                        label="Premium"
                      />
                    </TableCell>
                    <TableCell align="right" sx={{ border: 0, padding: '0px', paddingTop: 1 }}>
                      {premiumAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    hover
                    sx={{ border: 0, padding: '0px' }}
                  >
                    <TableCell align="left" component="th" scope="row" sx={{ border: 0, padding: '0px' }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={pdfAmount == 0 ? false : payPDF}
                        label="PDF"
                        onClick={() => {
                          pdfAmount == 0 ? null : setPayPDF(!payPDF)
                        }}
                        disabled={pdfAmount == 0 ? true : false}
                      />
                    </TableCell>
                    <TableCell align="right" sx={{ border: 0, padding: '0px' }}>
                      {pdfAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    hover
                    sx={{ border: 0, padding: '0px' }}
                  >
                    <TableCell align="left" component="th" scope="row" sx={{ border: 0, padding: '0px' }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={fbrAmount == 0 ? false : payFBR}
                        label="FBR"
                        onClick={() => {
                          fbrAmount == 0 ? null : setPayFBR(!payFBR)
                        }}
                        disabled={fbrAmount == 0 ? true : false}
                      />
                    </TableCell>
                    <TableCell align="right" sx={{ border: 0, padding: '0px' }}>
                      {fbrAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    hover
                    sx={{ border: 0, padding: '0px' }}
                  >
                    <TableCell align="left" component="th" scope="row" sx={{ padding: '0px', paddingBottom: 1 }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={loanInterestAmount == 0 ? false : payLoanInterest}
                        label="Policy Loan Interest"
                        onClick={() => {
                          loanInterestAmount == 0 ? null : setPayLoanInterest(!payLoanInterest)
                        }}
                        disabled={loanInterestAmount == 0 ? true : false}
                      />
                    </TableCell>
                    <TableCell align="right" sx={{ padding: '0px', paddingBottom: 1 }}>
                      {loanInterestAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    hover
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" component="th" scope="row" sx={{ border: 0 }}>
                      <Typography variant="subtitle2">
                        Total Amount Due
                      </Typography>
                    </TableCell>
                    <TableCell align="right" sx={{ border: 0 }}>
                      ₱ {totalAmountDue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </TableCell>
                  </TableRow>

                  <TableRow />
                </TableBody>
              </Table>
            </TableContainer>


            <Button variant="contained"
              onClick={() => {
                setModalSelectPaymentSummary(false)
                setBillingNotice(billingNotice)
                PRINT_BILLING_NOTICE(billingNotice);
              }}
              sx={{ mt: 1 }}>
              Print
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Typography variant="h4" sx={{ mb: 5 }} color="text.primary">
        History{" "}
      </Typography>

      <APILoadingOverlay customlabel={loadingCustomLabel}
        open={isLoading} setOnParent={(value) => setIsLoading(value)}>
        {/* <Typography variant="h6">Auto-Charge Arrangement Details</Typography>
      <Typography variant="body1" color="text.secondary">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </Typography> */}

        <Box sx={{ mt: 5 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Auto-Charge Arrangement History
          </Typography>

          {ACAHistory.length > 0 ?
            <MaterialUITable
              data={ACAHistory}
              isLoading={false}
              totalCount={ACAHistory.length}
              currentPage={pageNumber}
              rowsPerPage={rowsPerPage}
              columns={[
                {
                  title: "Number",
                  field: "index",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Recurring Type",
                  field: "RecurringType",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Terminal/Bank",
                  field: "TerminalBank",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Account Number",
                  field: "AccountNumber",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Due Date",
                  field: "DueDate",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Charged Date",
                  field: "ChargedDate",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Amount",
                  field: "Amount",
                  // align: 'right',
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid',
                    textAlign: 'right'
                  },
                },
                {
                  title: "Status",
                  field: "Status",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "OR Number",
                  field: "ORNumber",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Remarks",
                  field: "Remarks",
                },
              ]}
              changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
              changePage={(page, pNumber) => setPageNumber(page)}
            />
            :
            <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
              No Data Available
            </Typography>}
        </Box>



        <Box sx={{ mt: 5 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Post Dated Check History
          </Typography>

          {postDatedCheckHistory.length > 0 ?
            <MaterialUITable
              data={postDatedCheckHistory}
              isLoading={false}
              totalCount={postDatedCheckHistory.length}
              currentPage={pageNumber}
              rowsPerPage={rowsPerPage}
              columns={[
                {
                  title: "Due Date",
                  field: "DueDate",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Bank/Branch",
                  field: "BankBranch",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Check Number",
                  field: "CheckNumber",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Charged Date",
                  field: "ChargedDate",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Check Amount",
                  field: "CheckAmount",
                  // align: 'right',
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid',
                    textAlign: 'right'
                  },
                },
                {
                  title: "Status",
                  field: "Status",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Remarks",
                  field: "Remarks",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Reason For Rejection",
                  field: "ReasonForRejection",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "OR Date",
                  field: "ORDate",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "OR Number",
                  field: "ORNumber",
                },
              ]}
              changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
              changePage={(page, pNumber) => setPageNumber(page)}
            />
            :
            <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
              No Data Available
            </Typography>}
        </Box>



        <Box sx={{ mt: 5 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Billings
              </Typography>
            </Grid>
            {/* {billingsTableData.length > 0 ?
              //  {billingNotice.TotalAmountDue != 0 ? 
              < Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  sx={{ mb: 3 }}
                  variant="contained"
                  onClick={() => {
                    let currentDateAfter31Days = moment().add(31, 'days')
                    let currentDateBefore31Days = moment().subtract(31, 'days')
                    // console.log("moment currentDateAfter31Days", moment(currentDateAfter31Days))
                    // console.log("moment currentDateBefore31Days", moment(currentDateBefore31Days))

                    if (moment(currentDateAfter31Days).isSameOrAfter(moment('03/02/2022')) &&
                      moment(currentDateBefore31Days).isSameOrBefore(moment('03/02/2022'))) {
                      PRINT_BILLING_NOTICE();
                    } else {
                      setModalBillingNotWithin31Days(true)
                      // console.log(moment(currentDateAfter31Days), "is after", moment('03/02/2022'))
                      // console.log(moment(currentDateAfter31Days).isSameOrAfter(moment('03/02/2022')))
                      // console.log(moment(currentDateBefore31Days), "is before", moment('03/02/2022'))
                      // console.log(moment(currentDateBefore31Days).isSameOrBefore(moment('03/02/2022')))
                    }
                  }} autoFocus >
                  Print Billing Notice
                </Button>
              </Grid>
              : null} */}
          </Grid>

          {billingsTableData.length > 0 ?
            <MaterialUITable
              data={billingsTableData}
              isLoading={false}
              totalCount={billingsTableData.length}
              currentPage={pageNumber}
              rowsPerPage={rowsPerPage}
              columns={[
                {
                  title: "Address",
                  field: "Address",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "ZipCode",
                  field: "ZipCode",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Due Date",
                  field: "DueDate",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Next Due Date",
                  field: "NextDueDate",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "PayMode",
                  field: "PayMode",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Premium",
                  field: "Premium",
                  // align: 'right',
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid',
                    textAlign: 'right'
                  },
                },
                {
                  title: "Date Generated",
                  field: "DateGenerated",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid',
                  },
                },
                {
                  title: "Actions",
                  field: "internal_action",
                  render: (rowData) => {
                    if (rowData != null) {
                      // console.log("rowData", rowData.index, rowData)
                      // console.log("rowData.BillingNotice", rowData.index, rowData.BillingNotice)

                      let currentDateAfter31Days = moment(rowData.DueDate, 'MM-DD-YYYY').add(31, 'days')
                      let currentDateBefore31Days = moment(rowData.DueDate, 'MM-DD-YYYY').subtract(31, 'days')

                      let debug = true;

                      if (process.env.REACT_APP_ENV === 'local' && debug) {
                        // console.log("moment(currentDateAfter31Days).isSameOrAfter(moment('10-20-2021', 'MM-DD-YYYY')", moment(currentDateAfter31Days).isSameOrAfter(moment('10-20-2021', 'MM-DD-YYYY')))
                        // console.log("moment(currentDateBefore31Days).isSameOrBefore(moment('10-20-2021', 'MM-DD-YYYY')", moment(currentDateBefore31Days).isSameOrAfter(moment('10-20-2021', 'MM-DD-YYYY')))
                        // if (moment(currentDateAfter31Days).isSameOrAfter(moment('10-20-2021', 'MM-DD-YYYY')) &&
                        //   moment(currentDateBefore31Days).isSameOrBefore(moment('10-20-2021', 'MM-DD-YYYY'))) {
                        return (
                          <Button
                            sx={{ mb: 3 }}
                            variant="contained"
                            onClick={async () => {
                              if (moment(currentDateAfter31Days).isSameOrAfter(moment('10-20-2021', 'MM-DD-YYYY')) &&
                                moment(currentDateBefore31Days).isSameOrBefore(moment('10-20-2021', 'MM-DD-YYYY'))) {
                                handlePrintBillingNoticeBtn(rowData)
                              } else {
                                setModalBillingNotWithin31Days(true)
                              }
                            }} autoFocus >
                            Print Billing Notice
                          </Button>
                        )
                        // }
                      } else {
                        if (moment(currentDateAfter31Days).isSameOrAfter(moment()) &&
                          moment(currentDateBefore31Days).isSameOrBefore(moment())) {
                          return (
                            <Button
                              sx={{ mb: 3 }}
                              variant="contained"
                              onClick={() => {
                                if (moment(currentDateAfter31Days).isSameOrAfter(moment()) &&
                                  moment(currentDateBefore31Days).isSameOrBefore(moment())) {
                                  handlePrintBillingNoticeBtn(rowData)
                                } else {
                                  setModalBillingNotWithin31Days(true)
                                }
                              }} autoFocus >
                              Print Billing Notice
                            </Button>
                          )
                        }
                      }
                    }
                  }
                },
              ]}
              changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
              changePage={(page, pNumber) => setPageNumber(page)}
            />
            :
            <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
              No Data Available
            </Typography>}
        </Box>
      </APILoadingOverlay >
    </Box >
  );
}
