import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box, Typography, Paper, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, Grid
} from "@mui/material";
import moment from 'moment-timezone';
import MaterialUITable from "../components/MaterialUITable";
import { useSelector, useDispatch } from "react-redux";
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";
import APILoadingOverlay from "../components/APILoadingOverlay";
import APIErrorModal from "../components/APIErrorModal";
import axios from 'axios';


// const premiumPaymentList = [
//   PremiumPayments("200626", "200626", "99944798", "06-26-2020", "4,592.04", "Premium Payment"),
//   PremiumPayments("232626", "203226", "11244223", "03-26-2020", "4,592.04", "Premium Payment"),
//   PremiumPayments("238926", "788826", "87844877", "03-26-2020", "4,592.04", "Premium Payment"),
//   PremiumPayments("232355", "738990", "87555447", "02-26-2020", "4,592.04", "Premium Payment"),
// ]

export default function Funds(props) {
  let dispatch = useDispatch();

  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)

  const [unitPrice, setUnitPrice] = useState({});
  const [accountValuesTableData, setAccountValuesTableData] = useState({});
  const [accountValues, setAccountValues] = useState({});
  const [fundAllocation, setFundAllocation] = useState({});
  const [pageNumber, setPageNumber] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    dispatch(updateLoadingState(isLoading));
  }, [isLoading])

  useEffect(() => {
    async function init() {

      try {
        await axios.post(process.env.REACT_APP_API_URL + '/api/getUnitPrices', {
          policyNumber: POLICY_DATA,
        }).then(async (res) => {
          console.log("getUnitPrices res.data.Data", res.data.Data);

          if (res === 'null') {
            setapiName('getUnitPrices')
            setapiResponse({
              Code: '408',
              Message: "API request timeout."
            })
            setAPIErrorModalOpen(true)
            console.log('issue', 'getUnitPrices res is null')
            return
          }

          if (res.data.Success) {
            res.data.Data.UnitPrices.map((item, index) => {
              item.DBFND = item.DBFND.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
              item.EQFND = item.EQFND.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
              item.FXFND = item.FXFND.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
              item.IGFND = item.IGFND.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
              item.PBFND = item.PBFND.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
              if (moment(item.UnitPriceDate).isValid()) {
                item.UnitPriceDate = moment(item.UnitPriceDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
              }
            })

            setUnitPrice(res.data.Data.UnitPrices);


            await axios.post(process.env.REACT_APP_API_URL + '/api/getAccountValues', {
              policyNumber: POLICY_DATA,
            }).then(async (res) => {
              console.log("getAccountValues res.data.Data", res.data.Data);

              if (res === 'null') {
                setapiName('getAccountValues')
                setapiResponse({
                  Code: '408',
                  Message: "API request timeout."
                })
                setAPIErrorModalOpen(true)
                console.log('issue', 'getAccountValues res is null')
                return
              }

              if (res.data.Success) {
                let newAccountValuesDataList = [];
                let newAccountValues = {};

                res.data.Data.AccountValues.map((item, index) => {
                  item.AccountValueAmount = item.AccountValueAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                  item.AccountValueUnit = item.AccountValueUnit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                  item.CurrentUnitPrice = item.CurrentUnitPrice.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })

                  if (item.Fund != 'TOTAL ACCOUNT VALUE') {
                    // if (item.Plan != 'SUBTOTAL') {
                    if (newAccountValuesDataList.length > 0) {
                      newAccountValuesDataList = [...newAccountValuesDataList, item]
                    } else {
                      newAccountValuesDataList = [item]
                    }
                  } else if (item.Fund == 'TOTAL ACCOUNT VALUE') {
                    newAccountValues.total = item;
                  }
                  // console.log("item.Fund", item.Fund)
                })

                newAccountValues.NavPriceAsOfDate = res.data.Data.NavPriceAsOfDate;
                if (moment().isValid()) {
                  newAccountValues.NavPriceAsOfDate = moment(res.data.Data.NavPriceAsOfDate, 'MM/DD/YYYY').format('MMMM DD, YYYY');
                }
                // console.log("newAccountValues", newAccountValues)

                setAccountValuesTableData(newAccountValuesDataList);
                setAccountValues(newAccountValues);

                await axios.post(process.env.REACT_APP_API_URL + '/api/getFundAllocation', {
                  policyNumber: POLICY_DATA,
                }).then(async (res) => {
                  console.log("getFundAllocation res.data.Data", res.data.Data);

                  if (res === 'null') {
                    setapiName('getFundAllocation')
                    setapiResponse({
                      Code: '408',
                      Message: "API request timeout."
                    })
                    setAPIErrorModalOpen(true)
                    console.log('issue', 'getFundAllocation res is null')
                    return
                  }

                  if (res.data.Success) {

                    setFundAllocation(res.data.Data.FundAllocation)

                  } else {
                    setapiName('getFundAllocation')
                    setapiResponse(res.data)
                    setAPIErrorModalOpen(true)
                    console.log('issue', 'getFundAllocation res', res.data.Response)
                    return
                  }
                })


              } else {
                setapiName('getAccountValues')
                setapiResponse(res.data)
                setAPIErrorModalOpen(true)
                console.log('issue', 'getAccountValues res', res.data.Response)
                return
              }
            })
          } else {
            setapiName('getUnitPrices')
            setapiResponse(res.data)
            setAPIErrorModalOpen(true)
            console.log('issue', 'getUnitPrices res', res.data.Response)
            return
          }

          setIsLoading(false)
        })
      } catch (error) {
        console.log("error", error)
      }

    }
    init()
  }, [])

  return (
    <Box>
      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />
      <Typography variant="h4" color="text.primary" sx={{ mb: 5 }}>
        Funds{" "}
      </Typography>

      <APILoadingOverlay customlabel={loadingCustomLabel}
        open={isLoading} setOnParent={(value) => setIsLoading(value)}>
        {/* <Typography variant="h6">Premium Payments</Typography>
      <Typography variant="body1"  color="text.secondary">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </Typography> */}

        {/* //Second Table */}
        {/* <Typography variant="overline">Policies</Typography> */}

        <Box sx={{ mt: 7 }}>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Unit Price
          </Typography>

          {unitPrice.length > 0 ?
            <>
              <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    DBFND
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    Dollar Bond Fund (USD)
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    EQFND
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    Peso Equity Fund (PHP)
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    FXFND
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    Peso Fixed Income Fund (PHP)
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    IGFND
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    Peso Income and Growth Fund (PHP)
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    PBFND
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    Peso Bond Fund (PHP)
                  </Typography>
                </Grid>
              </Grid>

              <MaterialUITable
                data={unitPrice}
                isLoading={false}
                totalCount={unitPrice.length}
                currentPage={pageNumber}
                rowsPerPage={rowsPerPage}
                columns={[
                  {
                    title: "DBFND",
                    field: "DBFND",
                    // align: 'right',
                    headerStyle: {
                      borderRight: '1px solid'
                    },
                    cellStyle: {
                      borderRight: '1px solid',
                      textAlign: 'right'
                    }
                  },
                  {
                    title: "EQFND",
                    field: "EQFND",
                    // align: 'right',
                    headerStyle: {
                      borderRight: '1px solid'
                    },
                    cellStyle: {
                      borderRight: '1px solid',
                      textAlign: 'right'
                    }
                  },
                  {
                    title: "FXFND",
                    field: "FXFND",
                    // align: 'right',
                    headerStyle: {
                      borderRight: '1px solid'
                    },
                    cellStyle: {
                      borderRight: '1px solid',
                      textAlign: 'right'
                    }
                  },
                  {
                    title: "IGFND",
                    field: "IGFND",
                    // align: 'right',
                    headerStyle: {
                      borderRight: '1px solid'
                    },
                    cellStyle: {
                      borderRight: '1px solid',
                      textAlign: 'right'
                    }
                  },
                  {
                    title: "PBFND",
                    field: "PBFND",
                    // align: 'right',
                    headerStyle: {
                      borderRight: '1px solid'
                    },
                    cellStyle: {
                      borderRight: '1px solid',
                      textAlign: 'right'
                    }
                  },
                  {
                    title: "Unit Price Date",
                    field: "UnitPriceDate"
                  },
                ]}
                changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                changePage={(page, pNumber) => setPageNumber(page)}
              />
            </>
            :
            <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
              No Data Available
            </Typography>}

          <Typography variant="h6" sx={{ mt: 8, mb: 3 }}>
            Account Values
          </Typography>

          {accountValuesTableData.length > 0 ?
            <>
              <MaterialUITable
                data={accountValuesTableData}
                isLoading={false}
                totalCount={accountValuesTableData.length}
                currentPage={pageNumber}
                rowsPerPage={rowsPerPage}
                columns={[
                  {
                    title: "Fund",
                    field: "Fund",
                    headerStyle: {
                      borderRight: '1px solid'
                    },
                    cellStyle: {
                      borderRight: '1px solid'
                    },
                  },
                  {
                    title: "Fund Description",
                    field: "FundDescription",
                    headerStyle: {
                      borderRight: '1px solid'
                    },
                    cellStyle: {
                      borderRight: '1px solid'
                    },
                  },
                  {
                    title: "Current Unit Price",
                    field: "CurrentUnitPrice",
                    // align: 'right',
                    headerStyle: {
                      borderRight: '1px solid'
                    },
                    cellStyle: {
                      borderRight: '1px solid',
                      textAlign: 'right'
                    }
                  },
                  {
                    title: "Account Value Unit",
                    field: "AccountValueUnit",
                    // align: 'right',
                    headerStyle: {
                      borderRight: '1px solid'
                    },
                    cellStyle: {
                      borderRight: '1px solid',
                      textAlign: 'right'
                    }
                  },
                  {
                    title: "Account Value Amount",
                    field: "AccountValueAmount",
                    cellStyle: {
                      textAlign: 'right'
                    }
                  },
                ]}
                changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                changePage={(page, pNumber) => setPageNumber(page)}
              />

              {/* accountValues */}
              <Grid container spacing={2} sx={{ mb: 4, mt: 2, }}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography variant="body1" color="text.subtitle2" sx={{ fontWeight: "300" }}>
                    Nav Price as of:
                  </Typography>
                  <Typography variant="h6" color="text.primary" sx={{ fontWeight: "300" }}>
                    {/* {` ${accountValues?.NavPriceAsOfDate}`} */}
                    {` ${accountValues?.NavPriceAsOfDate}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography variant="body1" color="text.subtitle2" sx={{ fontWeight: "300" }}>
                    Total Account Value Unit
                  </Typography>
                  <Typography variant="h6" color="text.primary" sx={{ fontWeight: "300" }}>
                    {` ${accountValues?.total?.AccountValueUnit}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography variant="body1" color="text.subtitle2" sx={{ fontWeight: "300" }}>
                    Total Account Value Amount
                  </Typography>
                  <Typography variant="h6" color="text.primary" sx={{ fontWeight: "300" }}>
                    {` ${accountValues?.total?.AccountValueAmount}`}
                  </Typography>
                </Grid>
              </Grid>
            </>
            :
            <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
              No Data Available
            </Typography>}

          <Typography variant="h6" sx={{ mt: 8, mb: 3 }}>
            Fund Allocation
          </Typography>

          {fundAllocation.length > 0 ?
            <MaterialUITable
              data={fundAllocation}
              isLoading={false}
              totalCount={fundAllocation.length}
              currentPage={pageNumber}
              rowsPerPage={rowsPerPage}
              columns={[
                {
                  title: "Fund Description",
                  field: "FundDescription",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Fund",
                  field: "Fund",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Allocation",
                  field: "Allocation",
                  cellStyle: {
                    textAlign: 'right'
                  }
                },
              ]}
              changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
              changePage={(page, pNumber) => setPageNumber(page)}
            />
            :
            <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
              No Data Available
            </Typography>}

        </Box>
      </APILoadingOverlay>
    </Box>
  );
}
