import React, {
  useState,
  useEffect,
} from 'react';
import {
  Divider, Grid, Button, Select, MenuItem, Box,
  Container, FormControl, Paper, Stack, Table,
  TableBody, TableCell, TableContainer, TableHead,
  TableRow, TextField, Typography,
} from "@mui/material";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import cocolife from "../assets/cocolife-horizontal.png";
import PrintIcon from '@mui/icons-material/Print';
import { useSelector, useDispatch } from "react-redux";
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";
import APILoadingOverlay from "../components/APILoadingOverlay";
import APIErrorModal from "../components/APIErrorModal";
import axios from 'axios';
import moment from 'moment-timezone';
import Finance from 'tvm-financejs';
import coco_logo from "../assets/cocolife-horizontal.png";
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { renderToString } from "react-dom/server";

export default function LoanableAmount({ redirectToLoanAmortization }) {
  let dispatch = useDispatch();

  const finance = new Finance();

  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  // const POLICY_DATA = 'B0293553';
  // const USERNAME = useSelector((state) => state.username.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)

  const [currency, setCurrency] = useState('')
  const [loanAmount, setLoanAmount] = useState(0)

  const [loanCalcParams, setLoanCalcParams] = useState({});

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    dispatch(updateLoadingState(isLoading));
  }, [isLoading])

  useEffect(() => {
    async function init() {

      let loanCalcParamsTMP;

      await axios.post(process.env.REACT_APP_API_URL + '/api/getLoanCalculatorParams', {
        policyNumber: POLICY_DATA
      }).then(async (res) => {
        console.log("getLoanCalculatorParams res.data.Data", res.data.Data);
        if (res === 'null') {
          setapiName('getLoanCalculatorParams')
          setapiResponse({
            Code: '408',
            Message: "API request timeout."
          })
          setAPIErrorModalOpen(true)
          console.log('issue', 'getLoanCalculatorParams res is null')
          return
        }

        if (res.data.Success) {
          // setLoans(res.data.Data.PolicyLoan);

          if (process.env.REACT_APP_ENV === 'local'
            && (res.data.Data.LoanableAmount == null || res.data.Data.LoanableAmount == '')) {
            // res.data.Data.LoanBalance = 123000
            res.data.Data.LoanableAmount = 100000
          }

          res.data.Data.LoanableAmount = res.data.Data.LoanableAmount - res.data.Data.LoanBalance
          res.data.Data.LoanableAmount = res.data.Data.LoanableAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 4 })
          res.data.Data.LoanableAmount = parseFloat(res.data.Data.LoanableAmount.replace(",", ""))

          if (res.data.Data.LoanableAmount < 0) res.data.Data.LoanableAmount = 0

          setLoanCalcParams(res.data.Data);
          setIsLoading(false)

        } else {

          setIsLoading(false)
          setapiName('getLoanCalculatorParams')
          setapiResponse(res.data)
          setAPIErrorModalOpen(true)
          console.log('issue', 'getLoanCalculatorParams res', res.data.Response)
          return
        }
      })
    }

    init()
  }, [])

  useEffect(() => {
    async function init() {

    }
    init()
  }, [currency])

  return (
    <div>
      <Box
        sx={{ minHheight: "100vh", backgroundColor: "background.default", pt: 5 }}
      >
        <Container maxWidth="sm" sx={{ px: 5, py: 5 }}>
          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={6}> */}
          <img src={cocolife} style={{ width: "200px" }} />
          <Typography variant="h5" color="text.primary" sx={{ mt: 3, mb: 2 }}>
            Apply for loan
          </Typography>

          <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />

          <APILoadingOverlay customlabel={loadingCustomLabel}
            open={isLoading} setOnParent={(value) => setIsLoading(value)}>

            {/* {loanCalcParams.LoanBalance > 0 ?
              <Typography variant="body1" color="text.primary">
                User has an outstanding loan balance
              </Typography>
              :
              <Typography variant="body1" color="text.primary">
                Please input your desired loan amount
              </Typography>
            } */}
            <Typography variant="body1" color="text.primary">
              Please input your desired loan amount
            </Typography>

            <Divider sx={{ mt: 3, mb: 3, width: '100%' }} />

            <Stack sx={{ mb: 1, minWidth: 120 }}>
              <Grid container spacing={3}>

                <Grid item xs={12} sm={12} md={12}>
                  <Stack>
                    {/* {parseInt(loanCalcParams.LoanBalance) > 0 ?
                      <>
                        <Typography variant="overline" color="text.primary">
                          Outstanding Loan Amount
                        </Typography>

                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          value={loanCalcParams.LoanBalance}
                          type="number"
                          disabled
                        />
                      </>
                      :
                      <>
                        <Typography variant="overline" color="text.primary">
                          Loan Amount
                        </Typography>

                        < TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          value={loanAmount}
                          type="number"
                          onChange={event => {
                            if (event.target.value < 0) {
                              setLoanAmount(0)
                            } else if (event.target.value > loanCalcParams.LoanableAmount) {
                              setLoanAmount(loanCalcParams.LoanableAmount)
                            } else {
                              setLoanAmount(event.target.value)
                            }
                          }}
                        />
                      </>
                    } */}

                    <Typography variant="overline" color="text.primary">
                      Loanable Amount
                    </Typography>

                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      value={loanCalcParams.LoanableAmount}
                      type="number"
                      disabled
                    />

                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Stack>
                    <Typography variant="overline" color="text.primary">
                      Applied Loan Amount
                    </Typography>

                    {/* {parseInt(loanCalcParams.LoanBalance) > 0 ?
                      <TextField
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        value={0}
                        type="number"
                        disabled
                      />
                      :
                      <TextField
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        value={loanCalcParams.LoanableAmount}
                        type="number"
                      />
                    } */}

                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      value={loanAmount}
                      type="number"
                      onChange={event => {
                        if (event.target.value < 0) {
                          setLoanAmount(0)
                        } else if (event.target.value > loanCalcParams.LoanableAmount) {
                          setLoanAmount(loanCalcParams.LoanableAmount)
                        } else {
                          setLoanAmount(event.target.value)
                        }
                      }}
                    />
                  </Stack>
                </Grid>

              </Grid>
            </Stack>

            <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
              {/* {loanCalcParams.LoanBalance > 0 ?
                <Button
                  variant="contained"
                  size="large"
                  sx={{ mt: 3 }}
                  onClick={(() => {
                    redirectToLoanAmortization(loanCalcParams.LoanBalance)
                  })}
                // href="/mypolicy"
                >
                  View Loan Amortization
                </Button>
                :
                <>
                  {loanCalcParams.LoanableAmount <= 0 ?
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ mt: 3 }}
                      disabled
                    // href="/mypolicy"
                    >
                      Max Loan Reached
                    </Button>
                    :
                    <>
                      {loanAmount > 0 ?
                        <Button
                          variant="contained"
                          size="large"
                          sx={{ mt: 3 }}
                          onClick={(() => {
                            redirectToLoanAmortization(loanAmount)
                          })}
                        // href="/mypolicy"
                        >
                          View Loan Amortization
                        </Button>
                        :
                        <Button
                          variant="contained"
                          size="large"
                          sx={{ mt: 3 }}
                          disabled
                        // href="/mypolicy"
                        >
                          View Loan Amortization
                        </Button>
                      }
                    </>
                  }
                </>
              } */}

              <Button
                variant="contained"
                size="large"
                sx={{ mt: 3 }}
                onClick={(() => {
                  redirectToLoanAmortization(loanAmount)
                })}
              // href="/mypolicy"
              >
                View Loan Amortization
              </Button>
            </Box>

          </APILoadingOverlay>
        </Container>
      </Box>
    </div >
  );
}
