import { createSlice } from "@reduxjs/toolkit";

export const policyNumberSlice = createSlice({
  name: "policyNumber",
  //this is the initial state
  initialState: {
    value: "initial policy state",
    isVL: false,
    isTermPlan: false
  },
  //reducers: these are the functions for updating the state of dark mode / light mode
  reducers: {
    updatePolicyNumber: (state, action) => {
      const { payload } = action
      state.value = payload
      // state.isVL = payload[0] === "V" || payload[0] === "v" ? true : false
    },
    updateIsVL: (state, action) => {
      const { payload } = action
      state.isVL = payload
    },
    updateIsTermPlan: (state, action) => {
      const { payload } = action
      state.isTermPlan = payload
    },
    updatePolicyDetails: (state, action) => {
      const { policyNumber, isVL, isTermPlan } = action.payload
      console.log("new policyNumber", policyNumber)
      console.log("new isVL", isVL)
      console.log("new isTermPlan", isTermPlan)
      state.value = policyNumber
      state.isVL = isVL
      state.isTermPlan = isTermPlan
    },
    clearPolicyNumber: (state) => {
      state.value = null;
    },
    clearPolicyDetails: (state) => {
      state.value = null
      state.isVL = false
      state.isTermPlan = false
    },
  },

});


export const {
  updatePolicyNumber,
  clearPolicyNumber,
  updateIsVL,
  updateIsTermPlan,
  updatePolicyDetails,
  clearPolicyDetails } = policyNumberSlice.actions;

export default policyNumberSlice.reducer;
