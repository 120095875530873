import React from 'react'
import { SvgIcon } from '@mui/material'
import {ReactComponent as Logo} from './google-svg.svg'
export default function GoogleLogo() {
  return (
    <SvgIcon>
        <Logo/>
    </SvgIcon>
  )
}
