import React, {
  useState,
  useEffect,
} from 'react';

import Box from "@mui/material/Box";

import Divider from "@mui/material/Divider";

import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import cocolife from "../assets/cocolife-horizontal.png";
import Link from "@mui/material/Link";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useFormik, useField, useFormikContext } from "formik";
import { DatePicker } from "@mui/lab";
import APIErrorModal from "../components/APIErrorModal";
import axios from "axios";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { trackOldUserID, updatePage1, page2, setProvider } from "../features/Registration/RegistrationSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 80,
  maxWidth: 500,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

function UserRegistration(props) {
  const PROVIDER_STATE = useSelector((state) => state.registration.provider);
  const registrationSTATE = useSelector((state) => state.registration)
  const oldUserIDSTATE = useSelector((state) => state.registration?.oldUserID);

  const navigate = useNavigate();

  const [hasSubmitted, setHasSubmitted] = React.useState(false);

  const [agreedToPrivacyPolicy, setAgreedToPrivacyPolicy] = useState(false);
  const [agreedToAcknowledgementSlip, setAgreedToAcknowledgementSlip] = useState(false);
  const [formValues, setFormValues] = React.useState({});

  const [apiName, setapiName] = React.useState(null);
  const [apiResponse, setapiResponse] = React.useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = React.useState(false);
  const [policyErrorModal, setPolicyErrorModal] = React.useState(false);
  const [policyExistsErrorModal, setPolicyExistsErrorModal] = React.useState(false);
  const [emailExistsErrorModalOpen, setEmailExistsErrorModal] = React.useState(false);
  const [mobileNumberExistsErrorModalOpen, setMobileNumberExistsErrorModal] = useState(false);
  const [modalPrivacyPolicy, setModalPrivacyPolicy] = useState(false);
  const [modalAcknowledgementSlip, setModalAcknowledgementSlip] = useState(false);

  let dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      // moment.tz.setDefault("Asia/Manila");
      // moment.tz.setDefault("America/Los_Angeles");
    }
    init()
  }, [])

  useEffect(() => {
    async function init() {
      if (PROVIDER_STATE == null || PROVIDER_STATE == '') {
        dispatch(setProvider('emailpassword'));
      }
    }

    init()
  }, [PROVIDER_STATE])

  useEffect(() => {
    async function init() {
      console.log("oldUserIDSTATE", oldUserIDSTATE)
      if (oldUserIDSTATE != '' && oldUserIDSTATE != null) {
        await axios.post(process.env.REACT_APP_API_URL + '/api/getOldUserAccountByID', {
          id: oldUserIDSTATE,
        }).then(async (getOldUserAccountRes) => {
          if (getOldUserAccountRes.data.user === false) {
            //  big error here
            console.log("User not found")
            return
          }
          console.log("getOldUserAccountRes", getOldUserAccountRes)

          let oldUserID = getOldUserAccountRes.data.user.recordset[0].id;
          console.log("oldUserID", oldUserID)

          console.log("formik", formik)

          if (getOldUserAccountRes.data.user.recordset[0].email != null && getOldUserAccountRes.data.user.recordset[0].email != '') {
            console.log("setting emailAddress", getOldUserAccountRes.data.user.recordset[0].email)
            formik.setFieldValue('emailAddress', getOldUserAccountRes.data.user.recordset[0].email, true)
            formik.setFieldValue('username', getOldUserAccountRes.data.user.recordset[0].email, true)
          }

          if (getOldUserAccountRes.data.user.recordset[0].mobileNumber != null && getOldUserAccountRes.data.user.recordset[0].mobileNumber != '') {
            formik.setFieldValue('mobileNumber', getOldUserAccountRes.data.user.recordset[0].mobile_number, true)
          }

          // console.log("formik", formik)

        })
      }
    }
    init()
  }, [])

  const saveToStore = (inputs) => {
    try {
      dispatch(updatePage1(inputs))
    } catch (error) {
      console.log("redux error", error);
    }
  }

  const backendValidate = async ({
    policyNumber,
    firstName,
    middleName,
    lastName,
    suffix,
    birthday,
    mobileNumber,
    emailAddress
  }) => {
    let regLogJsonReq = {
      type: "Registration",
      step: "1/6 - Validate Policy",
      issue: "",
      description: "",
      status: 'F',
      policyNumber: policyNumber,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      suffix: suffix,
      birthday: birthday,
      email: emailAddress,
      mobileNumber: mobileNumber,
      provider: PROVIDER_STATE,
      sourceDev: "MyPolicy Website",
      sourceGroup: "MyPolicy Website",
    }

    try {

      // Check if policy is existing here
      return await axios.post(process.env.REACT_APP_API_URL + '/api/checkIfPolicyExists', {
        policyNumber,
      }).then(async (checkIfPolicyExistsRes) => {
        // console.log("checkIfPolicyExistsRes", checkIfPolicyExistsRes);
        if (checkIfPolicyExistsRes.data.policyExists) {
          // console.log("checkIfPolicyExistsRes.data.policyExists", checkIfPolicyExistsRes.data.policyExists);
          regLogJsonReq.issue = "Duplicate Policy Number"
          await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

          setPolicyExistsErrorModal(true)
          return
        }
        console.log("birthday", birthday);


        return await axios.post(process.env.REACT_APP_API_URL + '/api/validatePolicy', {
          policyNumber,
          firstName,
          middleName,
          lastName,
          suffix,
          birthday,
          mobileNumber,
          emailAddress,
          // birthday: stringifyDate(birthday)
        }).then(async (res) => {
          console.log("validatePolicy res.data.Data", res.data.Data);

          if (res === 'null') {
            setapiName('validatePolicy')
            setapiResponse({
              Code: '408',
              Message: "API request timeout."
            })
            setAPIErrorModalOpen(true)
            console.log('issue', 'validatePolicy res is null')

            regLogJsonReq.issue = "API 'validatePolicy' failed to respond";
            await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)
            return
          } else if (res.data.Success) {

            if (res.data.Data?.PersonID == 0 && process.env.REACT_APP_ENV != 'local') {
              console.log('issue', 'validatePolicy res is null')
              setPolicyErrorModal(true)

              regLogJsonReq.issue = "Policy Data is invalid according to validatePolicy API";
              await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)
              return
            } else {

              return await axios.post(process.env.REACT_APP_API_URL + '/api/checkIfMobileExists', {
                mobile: mobileNumber,
              }).then(async (checkIfMobileExistsRes) => {
                console.log("checkIfMobileExistsRes", checkIfMobileExistsRes);
                if (checkIfMobileExistsRes.data.mobileExists) {
                  // console.log("checkIfPolicyExistsRes.data.policyExists", checkIfPolicyExistsRes.data.policyExists);
                  setMobileNumberExistsErrorModal(true)

                  regLogJsonReq.issue = "Duplicate Mobile Number";
                  regLogJsonReq.description = "Duplicate Mobile Number: " + mobileNumber;
                  await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)
                  return
                } else {

                  return await axios.post(process.env.REACT_APP_API_URL + '/api/checkIfEmailExists', {
                    email: emailAddress,
                  }).then(async (checkIfEmailExistsRes) => {
                    console.log("checkIfEmailExistsRes", checkIfEmailExistsRes);
                    if (checkIfEmailExistsRes.data.emailExists) {
                      // console.log("checkIfPolicyExistsRes.data.policyExists", checkIfPolicyExistsRes.data.policyExists);
                      setEmailExistsErrorModal(true)

                      regLogJsonReq.issue = "Duplicate Email";
                      regLogJsonReq.description = "Duplicate Email: " + emailAddress;
                      await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)
                      return
                    } else {

                      console.log('VALIDATED!!')
                      return true
                    }
                  })
                }
              })
            }

          } else {
            regLogJsonReq.issue = "API 'validatePolicy' Error - R.2";
            regLogJsonReq.description = "API 'validatePolicy' Error: " + res?.data?.Response?.Message;
            await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

            setapiName('validatePolicy')
            setapiResponse(res.data)
            setAPIErrorModalOpen(true)
            console.log('issue', 'validatePolicy res', res.data.Response)
            return
          }
        }).catch(async (err) => {
          regLogJsonReq.issue = "API 'validatePolicy' Error - R.1";
          regLogJsonReq.description = "API 'validatePolicy' Error: " + JSON.stringify(err);
          await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

          if (err.response) {
            console.err("Error.response:", err.response);
            setapiName('validatePolicy')
            setapiResponse({
              Code: err.response.status,
              Message: err.response.statusText
            })
            setAPIErrorModalOpen(true)
            console.log('issue', 'validatePolicy', err)
            return
          } else {
            setapiName('validatePolicy')
            setapiResponse({
              Code: '408',
              Message: "API request timeout."
            })
            setAPIErrorModalOpen(true)
            console.log('issue', 'validatePolicy', err)
            return
          }

        })
      })
    } catch (error) {
      console.log("axios error", error);
    }
  }

  const [dateThing, setDateThing] = React.useState("");
  const [dateThingValidated, setdateThingValidated] = React.useState(false);

  const RegiSchema = Yup.object().shape({
    policyNumber: Yup.string()
      .min(7, 'Too Short!')
      .max(8, 'Too Long!')
      .required('Required'),
    firstName: Yup.string()
      .min(1, 'Too Short!')
      .max(100, 'Too Long!')
      .required('Required'),
    middleName: Yup.string()
      .min(1, 'Too Short!')
      .max(100, 'Too Long!'),
    // .required('Required'),
    lastName: Yup.string()
      .min(1, 'Too Short!')
      .max(100, 'Too Long!')
      .required('Required'),
    suffix: Yup.string()
      .min(1, 'Too Short!'),
    mobileNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    emailAddress: Yup.string()
      .email('Invalid email')
      .required('Required'),
    // .max(100, 'Too Long!')
    // email: Yup.string().email('Invalid email').required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      policyNumber: '',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      mobileNumber: '',
      emailAddress: '',
    },
    enableReinitialize: true,
    validationSchema: RegiSchema,
    onSubmit: async values => {
      // console.log({ values, dateThing.toLocaleString() })

      console.log("dateThing", dateThing)
      console.log("moment(dateThing).format('MM/DD/YYYY')", moment(dateThing).format('MM/DD/YYYY'))
      const inputs = { ...values, birthday: dateThing }
      //FIrebase auth TODO better add inside Then if success

      // validate
      const correct = await backendValidate(inputs).then((res) => {
        console.log("res", res)
        if (res) {
          if (PROVIDER_STATE === 'emailpassword') {
            console.log("inputs", inputs)
            saveToStore(inputs)
            navigate("/registration-step2")
          } else {
            setFormValues({ ...inputs })
            setAgreedToPrivacyPolicy(false)
            setAgreedToAcknowledgementSlip(false)
            setModalPrivacyPolicy(true)
          }
        }
      })

      // save record
      // saveToBackend({ ...values, birthday: dateThing })
    }
  })

  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: "background.default" }}>
      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={policyExistsErrorModal}
        onClose={() => {
          setPolicyExistsErrorModal(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={policyExistsErrorModal}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6"
              component="h2" color="text.primary">
              Policy already registered!
            </Typography>

            <Divider sx={{ mt: 3, mb: 1, width: '100%' }} />

            <Box sx={{ mx: 1 }}>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                The Policy you have entered has already been registered.
              </Typography>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                If you think this is a mistake, please contact our customer support at 02- 88107888 for assistance.
              </Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
              <Button variant="contained"
                sx={{ mt: 4 }}
                onClick={() => {
                  setPolicyExistsErrorModal(false)
                }}>
                Ok
              </Button>
            </Box>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={policyErrorModal}
        onClose={() => {
          setPolicyErrorModal(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={policyErrorModal}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6"
              component="h2" color="text.primary">
              Invalid Policy Info!
            </Typography>

            <Divider sx={{ mt: 3, mb: 1, width: '100%' }} />

            <Box sx={{ mx: 1 }}>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                Please enter the correct Policy Details.
              </Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
              <Button variant="contained"
                sx={{ mt: 4 }}
                onClick={() => {
                  setPolicyErrorModal(false)
                }}>
                Ok
              </Button>
            </Box>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={mobileNumberExistsErrorModalOpen}
        onClose={() => {
          setMobileNumberExistsErrorModal(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={mobileNumberExistsErrorModalOpen}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6"
              component="h2" color="text.primary">
              Mobile Number already registered!
            </Typography>

            <Divider sx={{ mt: 3, mb: 1, width: '100%' }} />

            <Box sx={{ mx: 1 }}>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                The Mobile Number you have entered has already been used.
              </Typography>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                If you think this is a mistake, please contact our customer support at 02- 88107888 for assistance.
              </Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
              <Button variant="contained"
                sx={{ mt: 4 }}
                onClick={() => {
                  setMobileNumberExistsErrorModal(false)
                }}>
                Ok
              </Button>
            </Box>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={emailExistsErrorModalOpen}
        onClose={() => {
          setEmailExistsErrorModal(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={emailExistsErrorModalOpen}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6"
              component="h2" color="text.primary">
              Email already taken!
            </Typography>

            <Divider sx={{ mt: 3, mb: 1, width: '100%' }} />

            <Box sx={{ mx: 1 }}>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                The Email you have entered has already been taken.
              </Typography>
              <Typography
                id="transition-modal-description"
                align="left"
                sx={{ mt: 2, fontWeight: "bold" }}
                color="text.secondary"
              >
                If you think this is a mistake, please contact our customer support at 02- 88107888 for assistance.
              </Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
              <Button variant="contained"
                sx={{ mt: 4 }}
                onClick={() => {
                  setEmailExistsErrorModal(false)
                }}>
                Ok
              </Button>
            </Box>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalPrivacyPolicy}
        onClose={() => {
          setModalPrivacyPolicy(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalPrivacyPolicy}>
          <Box sx={{ ...style, maxWidth: 400 }}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Privacy Policy Statement
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Your privacy is important to us. By using this site, you agree to
              the use of cookies and your personal information in accordance
              with our{" "}
              <Link
                href="https://www.cocolife.com/about/privacy-policy/"
                target="_blank"
                sx={{ textDecoration: "none" }}
                color="text.linkActive"
              >
                Privacy Policy
              </Link>
            </Typography>

            <Stack direction="row">
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <Typography
                    color="text.secondary">
                    I AGREE to the Privacy Policy{' '}
                  </Typography>}
                checked={agreedToPrivacyPolicy}
                sx={{ mt: 4 }}
                onClick={() => {
                  setAgreedToPrivacyPolicy(!agreedToPrivacyPolicy)
                }} />
            </Stack>
            <Stack direction="row"
              sx={{ maxWidth: '70%' }}>
              <Typography
                sx={{ textAlign: 'left', marginLeft: 4, fontSize: '14px' }}
                color="text.secondary">
                {/* Please {' '} */}
                <Link
                  href="/privacy-policy"
                  target="_blank"
                  color="text.linkActive"
                  sx={{ textDecoration: "none" }}
                >
                  {/* click here  */}
                  Please view our Data Privacy Policy here &raquo;
                </Link>
                {/* to learn more about our Data Privacy. */}
              </Typography>
            </Stack>

            {agreedToPrivacyPolicy ?
              <Button variant="contained" onClick={async () => {
                setModalPrivacyPolicy(false)
                setModalAcknowledgementSlip(true)
                // setAgreedToPrivacyPolicy(!agreedToPrivacyPolicy)
              }} sx={{ mt: 4 }}>
                Proceed
              </Button>
              :
              <Button variant="contained" sx={{ mt: 4 }} disabled>
                Proceed
              </Button>}
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalAcknowledgementSlip}
        onClose={() => {
          setModalAcknowledgementSlip(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalAcknowledgementSlip}>
          <Box sx={{ ...style, maxWidth: 400 }}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Acknowledgement Slip
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '100%' }} />

            <Stack direction="row">
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <Typography
                    color="text.secondary">
                    I acknowledge the reciept of my Cocolife Insurance Policy.{' '}
                  </Typography>}
                checked={agreedToAcknowledgementSlip}
                sx={{ mt: 4 }}
                onClick={() => {
                  setAgreedToAcknowledgementSlip(!agreedToAcknowledgementSlip)
                }} />
            </Stack>

            {agreedToAcknowledgementSlip ?
              <Button variant="contained" onClick={async () => {
                setModalAcknowledgementSlip(false)
                let formValuesUpdated = formValues;
                formValuesUpdated.policyAgreementDate = moment().tz("Asia/Manila").format('MM-DD-YYYY hh:mm:ss a')
                saveToStore(formValues)
                navigate("/registration-step-otp")
                // setAgreedToAcknowledgementSlip(!agreedToAcknowledgementSlip)
              }} sx={{ mt: 4 }}>
                Proceed
              </Button>
              :
              <Button variant="contained" sx={{ mt: 4 }} disabled>
                Proceed
              </Button>}
          </Box>
        </Fade>
      </Modal>

      <Container maxWidth="sm" sx={{ p: 2 }}>
        <Paper variant="outlined" sx={{ py: 5, px: 4 }}>
          <Stack spacing={4}>
            <Box>
              <img src={cocolife} style={{ height: "25px" }} />
            </Box>
            <Box>
              <Typography variant="h4" color="text.primary" sx={{ mb: 2 }}>
                User Registration
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                Only <strong>Cocolife Policy payors</strong> are allowed to
                register.
              </Typography>

              <Divider sx={{ mt: 1 }} />
            </Box>


            <FormControl sx={{ my: 1 }} fullWidth>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>

                  <Grid item xs={12}>
                    <Stack>
                      <Typography variant="overline">Policy Number</Typography>

                      <TextField
                        name="policyNumber"
                        size="small"
                        variant="outlined"
                        hiddenLabel
                        value={formik.values.policyNumber}
                        onChange={(e) => {
                          e.target.value = ("" + e.target.value).toUpperCase();
                          formik.handleChange(e)
                          console.log("formik.values", formik.values)
                        }}
                      />
                      {formik.errors.policyNumber ? <Typography color="red">{formik.errors.policyNumber}</Typography> : null}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Stack>
                      <Typography variant="overline">First Name</Typography>

                      <TextField
                        name="firstName"
                        size="small"
                        variant="outlined"
                        hiddenLabel
                        value={formik.values.firstName}
                        onChange={(e) => {
                          e.target.value = ("" + e.target.value).toUpperCase();
                          formik.handleChange(e)
                          console.log("formik.values", formik.values)
                        }}
                      />
                      {formik.errors.firstName ? <Typography color="red">{formik.errors.firstName}</Typography> : null}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Stack>
                      <Typography variant="overline">Middle Name</Typography>

                      <TextField
                        name="middleName"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        value={formik.values.middleName}
                        onChange={(e) => {
                          e.target.value = ("" + e.target.value).toUpperCase();
                          formik.handleChange(e)
                          console.log("formik.values", formik.values)
                        }}
                      />
                      {formik.errors.middleName ? <Typography color="red">{formik.errors.middleName}</Typography> : null}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Stack>
                      <Typography variant="overline">Last Name</Typography>

                      <TextField
                        name="lastName"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        value={formik.values.lastName}
                        onChange={(e) => {
                          e.target.value = ("" + e.target.value).toUpperCase();
                          formik.handleChange(e)
                          console.log("formik.values", formik.values)
                        }}
                      />
                      {formik.errors.lastName ? <Typography color="red">{formik.errors.lastName}</Typography> : null}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Stack>
                      <Typography variant="overline">Suffix</Typography>

                      <TextField
                        name="suffix"
                        size="small"
                        variant="outlined"
                        hiddenLabel
                        value={formik.values.suffix}
                        onChange={(e) => {
                          e.target.value = ("" + e.target.value).toUpperCase();
                          formik.handleChange(e)
                          console.log("formik.values", formik.values)
                        }}
                      />
                      {formik.errors.suffix ? <Typography color="red">{formik.errors.suffix}</Typography> : null}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack>
                      <Typography variant="overline">Date of Birth</Typography>

                      {/* <TextField 
                        name="dateOfBirth"
                        size="small"
                        variant="outlined"
                        hiddenLabel
                        value={formik.values.dateOfBirth}
                        onChange={formik.handleChange}
                      /> */}

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="dateOfBirth"
                          inputFormat="MM/dd/yyyy"
                          hiddenLabel
                          value={moment(dateThing).format('MM/DD/YYYY')}
                          onChange={(value) => {
                            if (moment(value).isValid()) {
                              console.log("moment(value) 2", moment(value).format('MM/DD/YYYY'))

                              setdateThingValidated(true)
                              setDateThing(moment(value).format('MM/DD/YYYY'))
                            } else {
                              setdateThingValidated(false)
                            }
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      {!dateThingValidated && hasSubmitted ? <Typography color="red">Required</Typography> : null}

                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack>
                      <Typography variant="overline">Mobile Number</Typography>

                      <TextField
                        name="mobileNumber"
                        size="small"
                        variant="outlined"
                        hiddenLabel
                        value={formik.values.mobileNumber}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.mobileNumber ? <Typography color="red">{formik.errors.mobileNumber}</Typography> : null}

                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack>
                      <Typography variant="overline">Email Address</Typography>

                      <TextField
                        name="emailAddress"
                        size="small"
                        variant="outlined"
                        hiddenLabel
                        value={formik.values.emailAddress}
                        onChange={(event) => {
                          formik.setFieldValue("emailAddress", event.target.value, true)
                          formik.setFieldValue("username", event.target.value)
                          formik.setFieldValue("username", event.target.value, true)
                        }}
                      />
                      {formik.errors.emailAddress ? <Typography color="red">{formik.errors.emailAddress}</Typography> : null}

                    </Stack>
                  </Grid>
                </Grid>

                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={3}
                  sx={{ mt: 5 }}
                >

                  <Button variant="text" size="large">
                    <Link
                      href="/"
                      sx={{ textDecoration: "none" }}
                      color="text.linkActive"
                    >
                      Cancel{" "}
                    </Link>
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    // href="/registration-step2"
                    type="submit"
                    onClick={(() => {
                      setHasSubmitted(true)
                    })}
                  >
                    Next
                  </Button>
                </Stack>
              </form>
            </FormControl>

          </Stack>
        </Paper>
      </Container>
    </Box>
  );
}

export default UserRegistration;
